import { useState } from "react";
import { Card } from "antd";
import "../styles/propinfo.css";
import "antd/dist/antd.css";
import { Steps } from "antd";
import pic from "../assets/unnamed.png";
import locpic from "../assets/ec-location@2x.png";
import photoheadpic from "../assets/ec-photos@2x.png";
// import { Upload } from 'antd';
import { Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { NavLink } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload, message } from "antd";
import axios from "axios";
import { event } from "jquery";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useContext } from "react";
import { PropertyContext } from "../../HB Components/propertyContext/PropertyContext";
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export default function Photos() {
  const { propImages, addToPropImages,propImagespath, addToPropImagespath } = useContext(PropertyContext);
  const description = "This is a description.";
  const location = useLocation();
  const navigate = useNavigate();
  const options = [
    {
      value: "Burns Bay Road",
    },
    {
      value: "Downing Street",
    },
    {
      value: "Wall Street",
    },
  ];
  const [propphoto, setpropphoto] = useState([]);
  const [errorphoto, seterrorphoto] = useState("");
  const [errorphotofilesize, seterrorphotofilesize] = useState("");
  const handleChange = (value) => {
    console.log(`Selected: ${value}`);
  };

  const beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isLt2M;
  };
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([

      // {
      //   uid: '1',
      //   name: 'image.png',
      //   status: 'done',
      //   url: 'https://endpoint887127.azureedge.net/clogo/Tata_Capital.jpg',
      // },
      
        // {
        //   uid: '-1',
        //   name: 'image.png',
        //   status: 'done',
        //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        // },
        // {
        //   uid: '-2',
        //   name: 'image.png',
        //   status: 'done',
        //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        // },
        // {
        //   uid: '-3',
        //   name: 'image.png',
        //   status: 'done',
        //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        // },
        // {
        //   uid: '-4',
        //   name: 'image.png',
        //   status: 'done',
        //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        // },
        // {
        //   uid: '-xxx',
        //   percent: 50,
        //   name: 'image.png',
        //   status: 'uploading',
        //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        // },
        // {
        //   uid: '-5',
        //   name: 'image.png',
        //   status: 'error',
        // },
      

  ]);
  const [open, setOpen] = useState(false);
  const [file, setfile] = useState("");
  const [file1,setfile1]=useState("");
  const [file2,setfile2]=useState("");
  const [filename, setfilename] = useState();
 // const [Imagepath, setImagepath] = useState([]);
  const [imagegreaterthan, setimagegreaterthan] = useState(false);
  const [openerror, setopenerror] = useState(false);
  const handleCancel = () => setPreviewOpen(false);
  // const [propphoto,setpropphoto]=useState("")
let Imagepath1 = [];
  const handleCancelbtn = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  const handleCancelbtnerror = () => {
    console.log("Clicked cancel button");
    setopenerror(false);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
      // console.log(file)
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
         Upload <span className="m-red"> *</span>
      </div>
    </div>
  );

  //   useEffect(()=>{
  //     console.log(location.state)
  // },[])

  const fileinsert = () => {
    let filedata = document.querySelector(".ant-upload");

    let remove_error = document.querySelectorAll(".border_red");

    remove_error.forEach((item) => item.classList.remove("border_red"));

    if (fileList.length === 0) {
      setopenerror(true);
      seterrorphotofilesize("");
      seterrorphoto("* Please attach the Property Photos");
      let fileuploadeddata = document.querySelector(
        ".ant-upload-list-picture-card-container"
      );
      if (!fileuploadeddata) {
        filedata?.classList.add("border_red");
      }
    } else if (imagegreaterthan === true) {
      setopenerror(true);
      seterrorphoto("");

      seterrorphotofilesize("* Image must be less than 2MB");
    } else {
      addToPropImages(fileList,Imagepath1);
      //localStorage.setItem("Propertyimages", JSON .stringify(fileList));
      console.log(file1)
      console.log("propertyimages",propImages[0])

      
      var form = document.getElementById("formimg");
      const formData = new FormData(form);
      const formData2 = new FormData(form);
      const formData3 = new FormData(form);
      formData.append("file", file || propImages[0]?.originFileObj);

      if(fileList.length > 0)
      {
      let response = axios
        .post(
          "https://hotelonboardingapi.staysimplyfied.com/API/imageupload",
          formData
        )
        .then((res) => {
          console.log(res.data.path)
          //setImagepath((pre) => [...pre,res.data]);
          Imagepath1.push(res.data.path)
           navigate("/Profile");
          if(res.data.message==="Success" && file1!=="")
          {
            formData2.append("file", file1 || propImages[1]?.originFileObj);
            
        let response = axios
        .post(
          "hhttps://hotelonboardingapi.staysimplyfied.com/API/imageupload",
          formData2
        )
        .then((res) => {
         console.log(res.data)
       // setImagepath((pre) => [...pre,res.data]);
       Imagepath1.push(res.data.path)
          if(res.data.message==="Success" && file2!=="")
          {
            formData3.append("file", file2 || propImages[2]?.originFileObj);

      
            let response = axios
              .post(
                "https://hotelonboardingapi.staysimplyfied.com/API/imageupload",
                formData3
              )
              .then((res) => {
                console.log(res.data)
               // setImagepath((pre) => [...pre,res.data]);
               Imagepath1.push(res.data.path)
                 navigate("/Profile");
              })
              .catch((error) => {
                console.log(error);
              });

          }
      
        })
        .catch((error) => {
          console.log(error);
        });
      }



          
      
        })
        .catch((error) => {
          console.log(error);
        });
    }
    
      // console.log(res);
      // console.log(imgres)
      
    }
    addToPropImagespath(Imagepath1);

  };
// console.log(formData);
     
  const handleChangephoto = ({ fileList: newFileList }) => {
    console.log(newFileList[0])
    setFileList(newFileList);
    setpropphoto(newFileList);
    setfile(newFileList[0]?.originFileObj);
    setfile1(newFileList[1]?.originFileObj);
    setfile2(newFileList[2]?.originFileObj);
    // setfilename(newFileList[0].name)
    let filedata = document.querySelector(".ant-upload");
    let fileuploadeddata = document.querySelector(
      ".ant-upload-list-picture-card-container"
    );
    if (!fileuploadeddata) {
      filedata?.classList.remove("border_red");
    }
  };
  useEffect(() => {
    if (propImages) {
      setFileList(propImages);
    }
  }, []);
  console.log(propImagespath)
  return (
    <div>
      <nav className="row">
        <a className="navbar-brand col-md-2 text-center mobile-show" href="#">
          <img
            className=""
            src={pic}
            alt=""
            width={"120px"}
            height={"85px"}
          />
        </a>
        {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon">fsfds</span>
      </button> */}
        <span className="text-center col-md-12 d-flex justify-content-center align-items-center">
          <h3>
            <b>Property RFP</b>
          </h3>
        </span>
      </nav>
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-md-2 no-border-l h100"
            style={{ background: "#fff", borderLeft: "none" }}
          >
            <Card className=" col-md-12 h-100 side-menu">
              <Steps
                size="small"
                className="mt-md-5 cursor-not-allowed mobile-hide"
                direction="vertical"
                current={4}
              >
                <Steps.Step title={<NavLink to="/">Basics</NavLink>} />
                <Steps.Step
                  title={<NavLink to="/location">Location</NavLink>}
                />
                <Steps.Step
                  title={<NavLink to="/facilities">Facilities</NavLink>}
                />
                <Steps.Step
                  title={<NavLink to="/roomdet">Room details</NavLink>}
                />
                <Steps.Step title={<NavLink to="/photos">Photos</NavLink>} />
                <Steps.Step title={<NavLink to="/Profile">Contact</NavLink>} />
                <Steps.Step title={<NavLink to="/Publish">Publish</NavLink>} />
              </Steps>
              <a className="navbar-brand col-md-2 text-center logo-display" href="#">
            <img
              className=""
              src={pic}
              alt=""
             width="100%"
            />
          </a>
            </Card>
          </div>
          <div
            className="col-md-10 layout-ml"
            style={{ background: "rgb(227 245 255)" }}
          >
            <div className="row ">
              <div className="col-sm-6 p-3">
                <h3>Show them what they’re missing</h3>
                <p>
                  Pictures matter to travelers. Upload as many high-quality
                  images as you have. You can add more later. Want some tips on
                  how to upload quality photos that generate more bookings?
                </p>
              </div>
              <div className="col-sm-6">
                <form id="formimg" action="#">
                  <img src={photoheadpic} alt="" height={"120px"} />
                </form>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-9 photos">
                <h5>Property Photos</h5>
                <Card
                  style={{
                    background: "#f4f4f4",
                    boxShadow: "rgb(207 202 202) 0px 0px 10px",
                  }}
                >
                  {/* <h3>Property Photos</h3> */}

                  <div className="row">
                    <Upload
                      // action="https://www.mocky.io/v2/5cc8019d300000980a0f55e76"
                      listType="picture-card"
                      fileList={fileList}
                      accept={".jpg, .jpeg, .png"}
                      action={"http://localhost:8000/"}
                      
                      onPreview={handlePreview}
                      onChange={handleChangephoto}
                      multiple={true}
                      beforeUpload={(file) => {
                        const isLt2M = file.size / 1024 / 1024 < 2;
                        if (!isLt2M) {
                          // message.error("Image must smaller than 2MB!");
                          setimagegreaterthan(true);
                          return true;
                        } else {
                          setimagegreaterthan(false);
                          return false;
                        }
                      }}
                    >
                      {fileList.length >= 3 ? null : uploadButton}
                    </Upload>
                    <Modal
                      open={previewOpen}
                      title={previewTitle}
                      footer={null}
                      onCancel={handleCancel}
                    >
                      <img
                        alt="example"
                        style={{
                          width: "100%",
                        }}
                        src={previewImage}
                      />
                    </Modal>
                  </div>
                </Card>
                <div className="float-end mt-3 mt-sm-3 mb-4">
                  <Button
                    type="primary"
                    style={{ minWidth: "100px", marginRight: "5px" }}
                  >
                    <NavLink to="/roomdet">Previous</NavLink>
                  </Button>

                  <Button
                    type="primary"
                    className="ms-sm-3"
                    style={{ minWidth: "100px" }}
                    onClick={fileinsert}
                  >
                    Save & Continue
                    {/* <NavLink to="/Profile"> </NavLink> */}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal title="Title" open={open} onCancel={handleCancelbtn}></Modal>
      <Modal
        className="error_msg"
        title="Message"
        open={openerror}
        onCancel={handleCancelbtnerror}
        footer={[
          <Button key="back" type="primary" onClick={handleCancelbtnerror}>
            Ok
          </Button>,
        ]}
      >
        <p>{errorphoto}</p>
        <p>{errorphotofilesize}</p>
      </Modal>
    </div>
  );
}
