import React,{createContext,useReducer} from 'react'
import PropertyReducer from './PropertyReducer';


const initialState = {
  locationValue:[],
   facilities:[],
   roomDetails:[],
   propImages:[],
   profileDetails:[],
  basic:[],
  paymentVerified:true,
  upiverified:true,
  basicverify:false,
  profileacverified:false,
  profileupiverified:false,
  upi_name:{},
  bankac_name:{},
 gstvarified_details:[],
 propImagespath:[],
 profileImagePath:[],
  }

//basic,locationValue,facilities,roomDetails,propImages,profileDetails

export const PropertyContext = createContext(initialState);

export const PropertyProvider = (props) => {

    const [state,dispatch] = useReducer(PropertyReducer,initialState);


  const addToBasic = (values) => {
    dispatch({ type: "ADD_TO_Basic", payload: values });

  };

   const addToLocations = (values) => {
    dispatch({ type: "ADD_TO_LOCATIONS", payload: values });
  };

  const addToFacilities = (values) => {
    dispatch({ type: "ADD_TO_FACILITIES", payload: values });
  };
  const addToRoomDetails = (values) => {
    dispatch({ type: "ADD_TO_ROOMDETAILS", payload: values });
  };
  const addToPropImages = (values) => {
    dispatch({ type: "ADD_TO_PROPIMAGES", payload: values });
  };
  const addToPropImagespath = (values) => {
    dispatch({ type: "ADD_TO_PROPIMAGES_PATH", payload: values });
  };
  const addToProfile = (values) => {
    dispatch({ type: "ADD_TO_Profile", payload: values });
  };
  const payment_verified = (values) => {
    dispatch({ type: "ADD_TO_PAYMENT_VERIFIED", payload: values });
    console.log(values)
  };
  const upi_verified = (values) => {
    dispatch({ type: "ADD_TO_UPI_VERIFIED", payload: values });
    console.log(values)
  };
  //verifybut
  const basic_Verifiedbut = (values) => {
    dispatch({ type: "BASIC_VERIFIED", payload: values });
  };
  const profile_acVerifiedbut = (values) => {
    dispatch({ type: "PROFILE_AC_VERIFIED", payload: values });
  };
  const profile_upi_Verifiedbut = (values) => {
    dispatch({ type: "PROFILE_UPI_VERIFIED", payload: values });
  };
  const upiNamefunc = (values) => {
    dispatch({ type: "VERIFIED_UPI_DETAILS", payload: values });
  };
  const bankNamefunc = (values) => {
    dispatch({ type: "VERIFIED_Bank_DETAILS", payload: values });
  };
  const basic_GST_verified = (values) => {
    dispatch({ type: "VERIFIED_GST_DETAILS", payload: values });
  };
  const profileImagePathfunc = (values) => {
    dispatch({ type: "PROFILE_IMAGE_DETAILS", payload: values });
  };

  //Enter number only
  const textNum = (e) => {
    return e.target.value.replace(/\D/g,'')
    }
    //remove space
  const propInput = (e) => {
    if(/^\s/g.test(e.target.value)){
    e.target.value = e.target.value.replace(/^\s/g, "");
    
     }
     else {
      e.target.value = e.target.value.replace(/^[ \t]+|[ \t]+$/gm, " ");
     }
    }
//multi space trim
const multispacetrim = (value) => {
  return value?.replace(/  /g,' ').trim()
  }

    return (
        <PropertyContext.Provider 
        value = {{
            basic:state.basic,
            addToBasic,
            locationValue:state.locationValue,
            addToLocations,
            facilities:state.facilities,
            addToFacilities,
            roomDetails:state.roomDetails,
            addToRoomDetails,
            propImages:state.propImages,
            addToPropImages,
            profileDetails:state.profileDetails,
            addToProfile,
            paymentVerified:state.paymentVerified,
            payment_verified,
            upiverified:state.upiverified, 
            upi_verified,
            basicverify:state.basicverify,
            basic_Verifiedbut,
            profileacverified:state.profileacverified,
            profile_acVerifiedbut,
            profileupiverified:state.profileupiverified,
            profile_upi_Verifiedbut,
            upi_name:state.upi_name,
            upiNamefunc,
            bankac_name:state.bankac_name,
            bankNamefunc,
            gstvarified_details:state.gstvarified_details,
            basic_GST_verified,
            propImagespath:state.propImagespath,
            addToPropImagespath,
            profileImagePath:state.profileImagePath,
            profileImagePathfunc,
            textNum,
            propInput,
            multispacetrim
        }}>{props.children}</PropertyContext.Provider>
    )
}

