import { useMemo, useState, useRef, useContext } from "react";
import { Card } from "antd";
import "../styles/propinfo.css";
import "antd/dist/antd.css";
import { Steps } from "antd";
import pic from "../assets/unnamed.png";
import locpic from "../assets/ec-location@2x.png";
import { AutoComplete } from "antd";
import { Select } from "antd";
import { Input } from "antd";
import { Button } from "antd";
import { NavLink } from "react-router-dom";
// import { Input } from 'antd';
import Place from "./Place";
import Places from "./Places";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Modal, message } from "antd";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import { PropertyContext } from "../../HB Components/propertyContext/PropertyContext";

import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { event } from "jquery";

export default function Location({ propertyname }) {
  const { locationValue, addToLocations,textNum,multispacetrim } = useContext(PropertyContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyA64hWNaMkhU56Bz0nix2TOzJJf2DoqT0A",
    libraries: ["places"],
  });
  const {basic,propInput} = useContext(PropertyContext);
  // console.log(location.state.propertydescription)
  const [Addrsline1, setAddrsline1] = useState(locationValue[0]?.Addrsline1);
  const [Addrsline2, setAddrsline2] = useState(locationValue[0]?.Addrsline2);
  const [StateId, setStateId] = useState("");
  const [CityId, setCityId] = useState("");
  const [localityId, setLocalityId] = useState("");
  const [Pincode, setPincode] = useState(locationValue[0]?.Pincode);
  const [Directions, setDirections] = useState(locationValue[0]?.Directions);
  const [open, setOpen] = useState(false);
  const [erroraddressline1, seterroraddressline1] = useState("");
  const [erroraddresline2, seterroraddressline2] = useState("");
  const [errorStatename, seterrorStatename] = useState("");
  const [errorCityname, seterrorCityname] = useState("");
  const [errorlocalityname, seterrorlocalityname] = useState("");
  const [errorpincode, seterrorpincode] = useState("");
  const [errordirections, seterrordirections] = useState("");
  const [Statename, setStatename] = useState(locationValue[0]?.Statename);
  const [Cityname, setCityname] = useState(locationValue[0]?.Cityname);
  const [localityname, setlocalityname] = useState(locationValue[0]?.localityname);
  const [latlng, setlatlng] = useState("");
  const [errormessagearr, setmessagearr] = useState([]);
  const [stategstnum, setstategstnum] = useState("");
  const [opnemodalerror,setopenmodalerror]=useState(false)
  const Addresline1ref = useRef();
  const Addresline2ref = useRef();
  const Pincoderef = useRef();
  const directionref = useRef();

  const handleChangestate = (value) => {
    console.log(`selected ${value}`);
    setStateId(value);
    if (State !== "") {
      const stdata = {
        StateId: value,
      };
      const cityresponse = axios
        .post(
          "https://hotelonboardingapi.staysimplyfied.com/API/selectcity",
          stdata
        )
        .then((res) => {
          console.log("city", res.data.data);
          setCity(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  const handleCancelerror = () => {
    console.log("Clicked cancel button");
    setopenmodalerror(false);
  };
  const handlechangecity = (value) => {
    console.log(`selected ${value}`);
    setCityId(value);
    const ctdata = {
      cityid: value,
    };
    const localityesponse = axios
      .post(
        "https://hotelonboardingapi.staysimplyfied.com/API/selectlocality",
        ctdata
      )
      .then((res) => {
        console.log("locality", res.data.data.Id);

        setLocality(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    const statenameresponse = axios
      .post(
        "https://hotelonboardingapi.staysimplyfied.com/API/SelectCityname",
        ctdata
      )
      .then((res) => {
        console.log("Cityname", res.data.data[0]);
        setCityname(res.data.data[0].CityName);
        // setCity(res.data.data)
      })
      .catch((err) => {
        console.log(err);
      });

    //  navigate("/location", { state: {propertyname:propertyname,propertytype:PropertyId,checkintime:checkintime,
    //   checkintype:checkintype,gstnumber:gstnumber,
    //   propertydescription:propdescription,cancellationpolicy:cancellationpolicy,websiteAddress:websiteAddress } });
  };

  const handlechangelocality = (value) => {
    console.log(`selected ${value}`);
    setLocalityId(value);

   

    // http://hotelonboardingapi.staysimplyfied.com/
  };
  const [State, setState] = useState([]);
  const [City, setCity] = useState([]);
  const [Locality, setLocality] = useState([]);

  useEffect(() => {
    // console.log(location.state)
    // console.log(window.history.state.usr)
  }, []);

  const showModal = () => {
    //   event.preventDefault()
    console.log(Addrsline1);
    const statenameselect = {
      StateId: StateId || locationValue[0]?.StateId, 
    };
    axios
        .post(
          "https://hotelonboardingapi.staysimplyfied.com/API/SelectStatename",
          statenameselect
        )
        .then((res) => {
          console.log(res);
          setTimeout(() => {
          setstategstnum(res.data.data[0]?.GSTNumber);
          },1000);
          // setCity(res.data.data)
          // setStatename(res.data.data[0].StateName);
        })
        .catch((err) => {
          console.log(err);
        });

    setmessagearr((pre) => []);
    let add1 = document.querySelector("#Addrsline1");
    let add2 = document.querySelector("#Addrsline2");
    let loc = document.querySelector("#state");
    let city = document.querySelector("#city");
    let Locality = document.querySelector("#Locality");
    let Pincode = document.querySelector("#Pincode");
    let Directions = document.querySelector("#Directions");

    let remove_error = document.querySelectorAll(".border_red");

    remove_error.forEach((item) => item.classList.remove("border_red"));

    if (add1?.value === "") {
      setmessagearr((pre) => [...pre, "* Please enter the Address line1"]);
      add1.classList.add("border_red");
    }

    if (add2?.value === "") {
      setmessagearr((pre) => [...pre, "* Please enter the  Address line2"]);
      add2.classList.add("border_red");
    }
    if (loc?.value === "") {
      setmessagearr((pre) => [...pre, "* Please enter the  State name"]);
      loc?.parent?.parent?.classList.add("border_red");
      loc?.closest(".ant-select-selector").classList.add("border_red");
    }
    if (city?.value === "") {
      setmessagearr((pre) => [...pre, "* Please enter the City name"]);
      city?.closest(".ant-select-selector").classList.add("border_red");
    }
    if (Locality?.value === "") {
      setmessagearr((pre) => [...pre, "* Please enter the Locality name"]);
      Locality?.closest(".ant-select-selector").classList.add("border_red");
    }
    if (Pincode?.value === "") {
      setmessagearr((pre) => [...pre, "* Please enter the Pincode"]);
      Pincode.classList.add("border_red");
    }
    else {
      if(Pincode?.value.length < 6) {
        setmessagearr((pre) => [...pre, "* Pincode must be 6 digits"]);
      Pincode.classList.add("border_red");
      }
    }
    if (Directions?.value === "") {
      setmessagearr((pre) => [...pre, "* Please enter the Directions"]);
      Directions.classList.add("border_red");
    }

    setTimeout(() => {
      console.log(errormessagearr);
    }, 1000);

    if (
      add1?.value === "" ||
      add2?.value === "" ||
      loc?.value === "" ||
      city?.value === "" ||
      Locality?.value === "" ||
      Pincode?.value === "" ||
      Pincode?.value.length < 6 ||
      Directions?.value === ""
    ) {
      setOpen(true);
    } else {
      console.log(errormessagearr);
      

  

      let locrr = [
        {
          Addrsline1: multispacetrim(Addrsline1) || locationValue[0]?.Addrsline1,
          Addrsline2: multispacetrim(Addrsline2) || locationValue[0]?.Addrsline2,
          Statename: Statename || locationValue[0]?.Statename,
          Cityname: Cityname || locationValue[0]?.Cityname,
          localityname: localityname || locationValue[0]?.localityname,
          StateId: StateId || locationValue[0]?.StateId,
          CityId: CityId || locationValue[0]?.CityId,
          localityId: localityId || locationValue[0]?.localityId,
          Pincode: Pincode.value || locationValue[0]?.Pincode,
          Directions: multispacetrim(Directions.value) || locationValue[0]?.Directions,
          latlng: latlng || locationValue[0]?.latlng,
          // Pincode: Pincode || locationValue[0]?.,
          //Directions: Directions || locationValue[0]?.Directions,
          stategstnum: stategstnum || locationValue[0]?.stategstnum,
        },
      ];
      addToLocations(locrr);
      //localStorage.setItem("locationarr", JSON.stringify(locrr));
    
      const datalocality={
        cityid:CityId,
        localityname:localityname
        
      }
      const responselocality = axios
      .post("https://hotelonboardingapi.staysimplyfied.com/API/insertlocality",datalocality)
      .then((res) => {
        setLocality(localityname)
        console.log(res.data[0])
      })
      .catch((err) => {
        console.log(err);
      });
      // basic[0]?.propertyname
      
      console.log("adadd",locrr);
      
    
      navigate("/facilities");
    }
      
  };
  console.log(errormessagearr);
  const getltlng = (data) => {
    console.log(data);
  };
console.log(stategstnum)
  const description = "This is a description.";

  const options = State.map((element, i) => {
    return {
      key: i,
      value: element.StateName,
      label: element.StateName,
      StateId: element.Id,
    };
  });

  const optionscity = City.map((element, i) => {
    return {
      key: i,
      value: element.CityName,
      label: element.CityName,
      CityId: element.Id,
    };
  });

  const optionslocality = Locality.map((element, i) => {
    return {
      key: i,
      value: element.Locality,
      label: element.Locality,
      localityId: element.Id,
    };
  });

  const onSelect = (value, option) => {
    console.log("onSelect", value);
    console.log("onSelect option", option);
    console.log(option.StateId);
    setStateId(option.StateId);
    setStatename(option.value);
  };
  const onSelectcity = (value, option) => {
    console.log("onSelect", value);
    console.log("onSelect option", option);
    console.log(option.CityName);
    setCityname(option.value);
    setCityId(option.CityId);
  };

  const onSelectlocality = (value, option) => {
    console.log("onSelect", value);
    console.log("onSelect option", option);
    console.log(option.value);
    setlocalityname(option.value);
    
    setLocalityId(option.localityId);
  };

  const handleChange = (value) => {
    console.log(`Selected: ${value}`);
  };

  const { TextArea } = Input;

  const handleClick = (e) => {
    e.preventDefault();
    // console.log(element)
    console.log("The link was clicked.");
  };
  const onChangeautocomplete = (data, event) => {
    console.log(data);
    console.log(options);
    //const stateresponse = axios
    axios.post("https://hotelonboardingapi.staysimplyfied.com/API/selectstate")
      .then((res) => {
        console.log("state", res.data.data);
        setState(res.data.data);
        
       // const statenameresponse = axios
        
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onChangeautocompletecity = (data, event) => {
    console.log(data);
    console.log(optionscity);
   
  };

  const onChangeautocompletelocality = (data, event) => {
    console.log(data);
    setlocalityname(data);
    console.log(optionscity);
    const stdata = {
      cityid: CityId || locationValue[0].CityId,
    };
    
    const localityresponse = axios
      .post(
        "https://hotelonboardingapi.staysimplyfied.com/API/selectlocality",
        stdata
      )
      .then((res) => {
        console.log("locality", res.data.data);
        setLocality(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Getlocation = () => {
    const mapresponse = axios
      .post(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${localityname},${Cityname},+India,&key=AIzaSyA64hWNaMkhU56Bz0nix2TOzJJf2DoqT0A`
      )
      .then((res) => {
        console.log(res.data.results[0].geometry);
        console.log(res.data.results[0].geometry.location);
        setlatlng(res.data.results[0].geometry.location);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  useEffect(() => {
    if(Statename !== '') {
      axios.post("https://hotelonboardingapi.staysimplyfied.com/API/selectstate")
      .then((res) => {
        console.log("state", res.data.data);
        setState(res.data.data);
    }).catch(error => console.log(error.message))


    if(Cityname!=="")
    {
      const stdata = {
        StateId: StateId,
      };
  
      const cityresponse = axios
        .post(
          "https://hotelonboardingapi.staysimplyfied.com/API/selectcity",
          stdata
        )
        .then((res) => {
          console.log("city", res.data.data);
          setCity(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if(localityname!=="")
    {
      const stdata = {
        cityid: CityId || locationValue[0]?.CityId,
      };
      
      const localityresponse = axios
        .post(
          "https://hotelonboardingapi.staysimplyfied.com/API/selectlocality",
          stdata
        )
        .then((res) => {
          console.log("locality", res.data.data);
          setLocality(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    

    }
    }
  },[Statename,Cityname,localityname])


  return (
    <div>
      <nav className="row">
        <a className="navbar-brand col-md-2 text-center mobile-show" href="#">
          <img
            className=""
            src={pic}
            alt=""
            width={"120px"}
            height={"85px"}
          />
        </a>

        <span className="text-center col-md-12 d-flex justify-content-center align-items-center">
          <h3>
            <b>Property RFP</b>
          </h3>
        </span>
      </nav>
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-md-2 no-border-l h100"
            style={{ background: "#fff", borderLeft: "none" }}
          >
            <Card className=" col-md-12 cursor-not-allowed h-100 side-menu">
              <Steps
                size="small"
                className="mt-md-5 mobile-hide"
                direction="vertical"
                current={1}
              >
                <Steps.Step title={<NavLink to="/">Basics</NavLink>} />
                <Steps.Step
                  title={<NavLink to="/location">Location</NavLink>}
                />
                <Steps.Step
                  title={<NavLink to="/facilities">Facilities</NavLink>}
                />
                <Steps.Step
                  title={<NavLink to="/roomdet">Room details</NavLink>}
                />
                <Steps.Step title={<NavLink to="/photos">Photos</NavLink>} />
                <Steps.Step title={<NavLink to="/Profile">Contact</NavLink>} />
                <Steps.Step title={<NavLink to="/Publish">Publish</NavLink>} />
              </Steps>
              <a className="navbar-brand col-md-2 text-center logo-display" href="#">
            <img
              className=""
              src={pic}
              alt=""
             width="100%"
            />
          </a>
            </Card>
          </div>
          <div
            className="col-md-10 location layout-ml"
            style={{ background: "rgb(227 245 255)" }}
          >
            <div className="row">
              <div className="col-sm-6 p-3">
                <h3>Put yourself on the map</h3>
                <p>Where will your guests be staying?</p>
              </div>
              <div className="col-sm-6">
                <img src={locpic} alt="" height={"120px"} />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-sm-12 col-lg-9">
                <h5>Location</h5>
                <p>
                  Guests will only receive your exact address once they’ve
                  confirmed a booking.
                </p>
                <Card
                  className="cardbgcolor"
                  style={{
                    background: "#f4f4f4",
                    boxShadow: "rgb(207 202 202) 0px 0px 10px",
                  }}
                >
                  <div className="row mt-2">
                    <div className="col-sm-2">
                      <label>
                        <b>Address Line&nbsp;1&nbsp;<span className="m-red">*</span></b>
                      </label>
                      <br />
                    </div>
                    <div className="col-sm-4">
                      <TextArea
                        rows={2}
                        value={Addrsline1}
                        onChange={(event) => {
                          setAddrsline1(event.target.value.replaceAll('  ',' '));
                        }}
                        ref={Addresline1ref}
                        style={{ width: "100%",resize: "none" }}
                        id="Addrsline1"
                        onInput={propInput}
                      />
                    </div>
                    <div className="col-sm-2">
                      <label>
                        <b>Address Line&nbsp;2&nbsp;<span className="m-red">*</span></b>
                      </label>
                    </div>
                    <div className="col-sm-4">
                      <TextArea
                        value={Addrsline2}
                        rows={2}
                        onChange={(event) => {
                          setAddrsline2(event.target.value.replaceAll('  ',' '));
                        }}
                        ref={Addresline2ref}
                        style={{ width: "100%",resize: "none"}}
                        id="Addrsline2"
                        onInput={propInput}
                      />
                    </div>
                  </div>
                  <div className="row mt-sm-2">
                    <div className="col-sm-2">
                      <label>
                        <b>State&nbsp;<span className="m-red">*</span></b>
                      </label>
                      <br />
                    </div>
                    <div className="col-sm-4">
                      <AutoComplete
                        options={options}
                        style={{ width: "100%" }}
                       // onChange={onChangeautocomplete}
                       onChange={e => 
                        {setStatename(e);setCityname(""); setDirections("");setlocalityname("");setPincode("");}}
                        onSelect={onSelect}
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        placeholder="State"
                        value={Statename}
                        id="state"
                      />
                    </div>
                    <div className="col-sm-2">
                      <label>
                        <b>City&nbsp;<span className="m-red">*</span></b>
                      </label>
                      <br />
                    </div>
                    <div className="col-sm-4">
                      <AutoComplete
                        options={optionscity}
                        style={{ width: "100%" }}
                        onChange={e=>{setCityname(e);setDirections("");setlocalityname("");setPincode("")}}
                        onSelect={onSelectcity}
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        placeholder="City"
                        value={Cityname}
                        id="city"
                      />
                    </div>
                  </div>

                  <div className="row mt-0 mt-md-2">
                    <div className="col-sm-2">
                      <label>
                        <b>Locality&nbsp;<span className="m-red">*</span></b>
                      </label>
                    </div>
                    <div className="col-sm-4">
                      <AutoComplete
                        options={optionslocality}
                        style={{ width: "100%" }}
                        onChange={e=>{setlocalityname(e);setPincode("");setDirections("")}}
                        onSelect={onSelectlocality}
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        onBlur={Getlocation}
                        placeholder="Locality"
                        value={localityname}
                        id="Locality"
                      />
                    </div>
                    <div className="col-sm-2">
                      <label>
                        <b>Pincode&nbsp;<span className="m-red">*</span></b>
                      </label>
                    </div>
                    <div className="col-sm-4">
                      <Input
                        placeholder="Pincode"
                        value={Pincode} 
                        
                        //pattern="[0-9]*"
                        pattern="[0-9]"
                        style={{ width: "100%" }}
                        onChange={e => {setPincode(textNum(e));}}
                        ref={Pincoderef}
                        id="Pincode"
                        type="text"
                        maxLength={6}
                        min={0}
                      >
                      </Input>
                    </div>
                  </div>

                  <div className="row mt-sm-2">
                    <div className="col-sm-2">
                      <label>
                        <b>Directions&nbsp;<span className="m-red">*</span></b>
                      </label>
                    </div>
                    <div className="col-sm-4">
                      <TextArea
                        placeholder="Directions"
                        onChange={(event) => {
                          setDirections(event.target.value.replaceAll('  ',' '));
                        }}
                        ref={directionref}
                        value={Directions}
                        id="Directions"
                        style={{resize: "none"}}
                        onInput={propInput}
                      ></TextArea>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <h5 className="mt-sm-3">Map location</h5>
                      <p>
                        Guests will only receive your exact address once they’ve
                        confirmed a booking.
                      </p>
                      {/* <Map latlng={latlng}/> */}
                      <Places latlng={latlng} localityname={localityname} />
                    </div>
                  </div>
                </Card>
                <div className="float-end mt-3 mt-sm-3 mb-4">
                  <Button
                    type="primary"
                    style={{ minWidth: "100px", marginRight: "5px" }}
                  >
                    <NavLink to="/">Previous</NavLink>
                  </Button>

                  <Button
                    type="primary"
                    className="ms-sm-3"
                    style={{ minWidth: "100px" }}
                    onClick={showModal}
                  >
                    Save & Continue
                    {/* <NavLink to="/facilities" ></NavLink> */}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="error_msg"
        title="Message"
        open={open}
        onCancel={handleCancel}
        footer={[
          <Button key="back" type="primary" onClick={handleCancel}>
            Ok
          </Button>,
        ]}
      >
        {errormessagearr &&
          errormessagearr.map((item, index) => <p key={index}>{item}</p>)}
      </Modal>
      

      <Modal
        className="error_msg"
        title="Message"
        open={opnemodalerror}
        onCancel={handleCancelerror}
        footer={[
          <Button key="back" type="primary" onClick={handleCancelerror}>
            Ok
          </Button>,
        ]}
      >
       This Prperty has already  created
      </Modal>
      
    </div>
  );
}
function Map({ latlng }) {
  const center = useMemo(() => ({ lat: 28, lng: 77 }), []);
  const [selected, setSelected] = useState(null);
  useEffect(() => {
    console.log(latlng);
  }, []);
  return (
    <>
      <GoogleMap
        zoom={10}
        center={center}
        mapContainerClassName="map-container"
        style={{ height: "450px", width: "850px" }}
      >
        {latlng && <Marker position={latlng} />}
      </GoogleMap>
    </>
  );
}
