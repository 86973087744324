import React from "react";
import { useState, useRef, useContext } from "react";
import { Card } from "antd";
import "../styles/propinfo.css";
import "antd/dist/antd.css";
import { Steps } from "antd";
import pic from "../assets/unnamed.png";
import locpic from "../assets/ec-location@2x.png";
import photoheadpic from "../assets/ec-photos@2x.png";

import { CloseOutlined, UploadOutlined,DeleteOutlined,CheckOutlined } from "@ant-design/icons";

import { Button, Tabs,Popconfirm } from "antd";
import { NavLink } from "react-router-dom";
import { Input, Modal, message } from "antd";
import { AutoComplete, Upload } from "antd";
import { Space, Table, Tag,Select } from "antd";
import { event } from "jquery";
// import {Column} from 'antd';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { PlusOutlined } from "@ant-design/icons";
import sha512 from "js-sha512";
import { useNavigate } from "react-router-dom";
import { useStatStyles } from "@chakra-ui/react";
import { PropertyContext } from "../../HB Components/propertyContext/PropertyContext";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export default function Profile({ setData1 }) {
  const { profileDetails, addToProfile,paymentVerified, payment_verified, upiverified, upi_verified,profileacverified,
    profile_acVerifiedbut,profileupiverified,profile_upi_Verifiedbut,upi_name,upiNamefunc,bankac_name,
    bankNamefunc,profileImagePath, profileImagePathfunc,textNum,propInput,multispacetrim } = useContext(PropertyContext);
  const description = "This is a description.";
  const location = useLocation();
  const { Column } = Table; 
  const navigate = useNavigate();

  // useEffect(() => {
  //   console.log(location.state.Propertyname)
  //  });

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [file, setfile] = useState();
  const [showverifysymb, setshowverifysymb] = useState(false);
  const [showverifybtn, setshowverifybtn] = useState(true);
  const [showverifypayee, setshowverifypayee] = useState(false);
  const [showverifypaueebtn, setshowverifypayeebtn] = useState(true);
  const options = [
    {
      value: "Front Office",
    },
    {
      value: "Sales",
    },
    {
      value: "GM",
    },
    {
      value: "Reservation",
    },
    {
      value: "Finance Manager",
    },
  ];
  const inputRef = useRef(null);

  const onChangeautocomplete = (data) => {
    console.log(data);
    setcontacttypeval(data.replaceAll('  ',' '));
  };
  const [errorcontacttype, seterrorcontacttype] = useState("");
  const [errorcontactName, seterrorcontactName] = useState("");
  const [errorEmailId, seterrorEmailid] = useState("");
  const [errormobilenumber, seterrormobilenumber] = useState("");
  const [contacttypeval, setcontacttypeval] = useState("");
  const [contactName, setcontactName] = useState("");
  const [Email, setEmail] = useState("");
  const [Mobileno, setMobileno] = useState("");
  const [landlineno, setlandlineno] = useState("");

  const [open, setOpen] = useState(false);
  const [Payeename, setPayeename] = useState(profileDetails[0]?.Propertyowners && profileDetails[0]?.Propertyowners[0]?.Payeename);
  const [Bankname, setBankname] = useState(profileDetails[0]?.Propertyowners && profileDetails[0]?.Propertyowners[0]?.Bankname);
  const [Accountnumber, setAccountnumber] = useState(profileDetails[0]?.Propertyowners && profileDetails[0]?.Propertyowners[0]?.Accountnumber);
  const [IFSCcode, setIFSCcode] = useState(profileDetails[0]?.Propertyowners && profileDetails[0]?.Propertyowners[0]?.IFSCcode);
  const [AccountType, setAccounttype] = useState(profileDetails[0]?.Propertyowners && profileDetails[0]?.Propertyowners[0]?.AccountType);
  const [branchAddress, setbranchaddress] = useState(profileDetails[0]?.Propertyowners && profileDetails[0]?.Propertyowners[0]?.branchAddress);
  const [chkleafpath, setchkleafpath] = useState("");
  const [UpiId, setUpiid] = useState(profileDetails[0]?.Propertyowners && profileDetails[0]?.Propertyowners[0]?.UpiId);
  const [Upiname, setUpiname] = useState("");
  const [Upimobilenumber, setUpimobilenumber] = useState( profileDetails[0]?.Propertyowners && profileDetails[0]?.Propertyowners[0]?.Upimobilenumber);
  const [Authorization, setAuthorization] = useState("");
  const [Authorizationupi, setAuthorizationupi] = useState("");
  const [errorPayeename, seterrorPayeename] = useState("");
  const [errorbankname, seterrorbankname] = useState("");
  const [erroraccountno, seterroraccountno] = useState("");
  const [errorifsccode, seterrorifsccode] = useState("");
  const [erroraccounttype, seterroraccounttype] = useState("");
  const [errorbranchaddress, seterrorbranchaddress] = useState("");
  const [errorupiId, seterrorupiid] = useState("");
  const [errorupiname, seterrorupiname] = useState("");
  const [errorupimobilenumber, seterrorupimobilenumber] = useState("");
  const [errormessagearr, setmessagearr] = useState([]);
  const [openerror, setopenerror] = useState(false);
  const [imageerror, setimageerror] = useState(false);
  const [email_validation, setEmail_validation] = useState(true);
  const [payee_Verified, setPayee_Verified] = useState(false);
  const [upi_Verified, setUpi_Verified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [upierrorfunc, setupierrorfunc] = useState(false);
  const [upierrormsg, setupierrormsg] = useState("");
  const [acerrorfunc, setacerrorfunc] = useState(false);
  const [validLandLine, setvalidLandLine] = useState(false);
  const [acerrormsg, setacerrormsg] = useState("");
  let getimagepath=[];
  console.log(getimagepath,profileImagePath);
  const [DataSource, SetDataSource] = useState([]);
  let element;
console.log(paymentVerified,upiverified)
  const handleClick = () => {
    // SetDataSource({})"
    setmessagearr([]);

    let contact_type = document.querySelector(
      ".contact_type > div > span > input"
    );
    let contact_Name = document.querySelector(".contact_Name");
    let Email_Id = document.querySelector(".Email_Id");
    let Mobile_No = document.querySelector(".Mobile_No");
    let landline = document.querySelector(".landline");

    let remove_error = document.querySelectorAll(".border_red");

    remove_error.forEach((item) => item.classList.remove("border_red"));

    if (contact_type?.value === "") {
      setopenerror(true);
      setmessagearr((pre) => [...pre, "* Please enter the Contact Type"]);
      contact_type?.closest(".ant-select-selector").classList.add("border_red");
    }
    if (contact_Name?.value === "") {
      setopenerror(true);
      setmessagearr((pre) => [...pre, "* Please enter the Contact Name"]);
      contact_Name?.classList.add("border_red");
    }
    if (Email_Id?.value === "") {
      setopenerror(true);
      setmessagearr((pre) => [...pre, "* Please enter the Email Id"]);
      Email_Id?.classList.add("border_red");
      setEmail_validation(false);
    }

    if (Mobile_No?.value === "") {
      // debugger
      setopenerror(true);
      // seterrorEmailid("")
      setmessagearr((pre) => [...pre, "* Please enter the Mobile Number"]);
      Mobile_No?.classList.add("border_red");
    }
    else {
      if (Mobile_No?.value.length < 10) {
        setmessagearr((pre) => [...pre, "* Mobile Number should be 10 digits"]);
        Mobile_No?.classList.add("border_red"); 
      }
    }
    // if (landline?.value.length < 10) {
    //   setmessagearr((pre) => [...pre, "* Landline Number should be 10 digits"]);
    //   landline?.classList.add("border_red"); 
    // }
   

    if (
      contact_type?.value === "" ||
      contact_Name?.value === "" ||
      Email_Id?.value === "" ||
      Mobile_No?.value === "" || Mobile_No?.value.length < 10  
    ) {
      setopenerror(true);
    } else {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(Email_Id?.value)) {
        setopenerror(true);
        setmessagearr((pre) => [...pre, "* Please enter the Valid Email Id"]);
        Email_Id?.classList.add("border_red");
      } else {
      
        const newcontactDetail = {
          key: Math.floor(Math.random() * 100),
          contactType: multispacetrim(contacttypeval),
          contactName: multispacetrim(contactName),
          Email: multispacetrim(Email),
          mobileno: Mobileno,
          landlineno: multispacetrim(landlineno),
        };

        // newcontactDetail.key = newcontactDetail.key + 1 || 1;

        console.log(newcontactDetail.key);
        console.log(DataSource);
        //  if(newcontactDetail.)
        SetDataSource((pre) => {
          return [...pre, newcontactDetail];
        });
        console.log("inside the function");

        console.log(contacttypeval);
        console.log(contactName);
        console.log(Email);
        console.log(Mobileno);
        console.log(landlineno);

        setcontacttypeval("");
        setcontactName("");
        setEmail("");
        setMobileno("");
        setlandlineno("");
        // console.log(dataentered)
      }
    }
  };

  const handleCancel = () => setPreviewOpen(false);
  const handleChange = (value) => {
    console.log(`Selected: ${value}`);
  };
  const [fileList, setFileList] = useState([]);
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  // const handleChangephoto =  (info) =>
  // {
  //   console.log(info.file.originFileObj)
  //   setFileList(info.file.originFileObj)

  // }

  const handleChangephoto = ({ fileList: newFileList }) => {
    // console.log(newFileList[0].originFileObj)
    setFileList(newFileList);

    setfile(newFileList[0].originFileObj);
    // setfilename(newFileList[0].name)
  };

  const [enteredData, setEnteredData] = useState({
    // contactType: '',
    contactName: "",
    Email: "",
    // mobileno:'',
    // landlineno:''
  });
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
      // console.log(file)
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleCancelbtn = () => {
    setOpen(false);
  };

  const handlebtncancel = () => {
    setopenerror(false);
  };
  const [dataentered, setdataentered] = useState([]);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Cheque Leaf Upload <span className="m-red">*</span>
      </div>
    </div>
  );

  // const changeHandler = (e) => {
  //   setEnteredData({
  //     ...enteredData,
  //     [e.target.name]: e.target.value
  //   });
  //   console.log(enteredData)
  // };

  const onSelect = (data) => {
    console.log("onSelect", data);
  };

  // function isValidEmail(Email) {
  //   return /\S+@\S+\.\S+/.test(Email);
  // }
  // const showModal = () => {
  //   if (enteredData.contactName === "") {
  //     console.log("inside the if");
  //     setOpen(true);
  //     seterrorcontactName("Please provide the contact name");
  //     // setpropdesc("")
  //     // console.log(con)
  //   } else {
  //     window.location.href = "/Publish";
  //   }

  //   var mailformat =
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   if (enteredData.Email.match(mailformat)) {
  //     errorEmailId("* You have entered a valid email address!");
  //     // document.form1.text1.focus();
  //     return true;
  //   } else {
  //     alert("* You have entered an invalid email address!");
  //     // document.form1.text1.focus();
  //   }

  //   if (enteredData.mobileno === 10) {
  //     alert("* You have entered a valid mobileno");
  //     // document.form1.text1.focus();
  //     return true;
  //   } else {
  //     errormobilenumber("* You have entered an invalid email address!");
  //     // document.form1.text1.focus();

  //     return false;
  //   }
  // };

  const emailcall = () => {
    const response = axios
      .post("http://localhost:3000/api/propmail")
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const props = {
  //   action: '//jsonplaceholder.typicode.com/posts/',
  //   listType: 'picture',

  // };
  let chklpath;
  const verifybankaccounthash = async () => {
    setmessagearr([]);
    let p_name = document.querySelector(".p_name");
    let b_name = document.querySelector(".b_name");
    let a_no = document.querySelector(".a_no");
    let ifsc = document.querySelector(".ifsc");
    let a_type = document.querySelector(".a_type .ant-select-selection-item").innerHTML;
    let a_type_text = document.querySelector(".a_type .ant-select-selection-item");
    let b_address = document.querySelector(".b_address");
    let c_leaf = document.querySelector(".ant-upload");

    let remove_error = document.querySelectorAll(".border_red");
    remove_error.forEach((item) => item.classList.remove("border_red"));

    if (p_name?.value === "") {
      seterroraccountno();
      setmessagearr((pre) => [...pre, "* Please enter the Payee Name"]);
      p_name?.classList.add("border_red");
    }
    if (b_name?.value === "") {
      seterroraccountno();
      setmessagearr((pre) => [...pre, "* Please enter the Bank Name"]);
      b_name?.classList.add("border_red");
    }
    if (a_no?.value === "") {
      seterroraccountno();
      setmessagearr((pre) => [...pre, "* Please enter the Account Number"]);
      a_no?.classList.add("border_red");
    }
    if (ifsc?.value === "") {
      seterrorPayeename("");
      seterroraccountno("");
      //seterrorifsccode("Please enter the IFSC Code");
      setmessagearr((pre) => [...pre, "* Please enter the IFSC Code"]);
      ifsc?.classList.add("border_red");
    }
    console.log(a_type);
    if (a_type === "") {
      setmessagearr((pre) => [...pre, "* Please enter the Account Type"]);
      a_type_text?.closest(".ant-select-selector").classList.add("border_red");
      //ant-select-selector
    }
    // if (b_address?.value === "") {
    //   setmessagearr((pre) => [...pre, "* Please enter the Branch Address"]);
    //   b_address?.classList.add("border_red");
    // }
    let filedata = document.querySelector(".ant-upload");
    if (fileList.length === 0) {
      setmessagearr((pre) => [...pre, "* Please upload Cheque leaf"]);
      let fileuploadeddata = document.querySelector(
        ".ant-upload-list-picture-card-container"
      );
      if (!fileuploadeddata) {
        filedata?.classList.add("border_red");
      }
    }
    if (c_leaf?.value === "") {
      setmessagearr((pre) => [...pre, "* Please upload Cheque leaf"]);
      c_leaf?.classList.add("border_red");
    }

    if (
      p_name?.value === "" ||
      b_name?.value === "" ||
      a_no?.value === "" ||
      ifsc?.value === "" ||
      a_type?.value === "" ||
     // b_address?.value === "" ||
      fileList.length === 0
    ) {
      setOpen(true);
    } else {
      setIsLoading(true);
      const datanew = {
        Authorization: `780070794E|${Accountnumber}|${IFSCcode}|8EA5D0A2F2`,
      };
      const res = axios
        .post("https://hotelonboardingapi.staysimplyfied.com/API/hash", datanew)
        .then((res) => {
          console.log(res.data.data);

          setAuthorization(res.data.data);
          const data = {
            key: "780070794E",
            account_no: Accountnumber,
            ifsc: IFSCcode,
          };
         // setIsLoading(false);
          // setPayee_Verified(true);
          // payment_verified(true)
          // // setshowverifypayeebtn(false);
          // // setshowverifypayee(true);
          // profile_acVerifiedbut(true);

            const response = axios
              .post(
                "https://wire.easebuzz.in/api/v1/beneficiaries/bank_account/verify/",
                data,
                {
                  headers: {
                    // 'Content-Type': 'application/json',
                    Authorization: res.data.data,
                  },
                }
              )
              .then((res) => {
                console.log(res.data.data.is_valid);
                if (res.data.data.is_valid == true) {
                  bankNamefunc(res.data.data);
                  setIsLoading(false);
                  setPayeename(res.data.data.account_name)
                  setacerrorfunc(false);
                  setPayee_Verified(true);
                  payment_verified(true)
                  profile_acVerifiedbut(true)
                }else {
                  setIsLoading(false);
                   setacerrorfunc(true);
                   profile_acVerifiedbut(false)
                   setmessagearr((pre) => [...pre, "* Check your A/c number / IFSC code and try again"]);
                   setopenerror(true);
                   a_no?.classList.add("border_red");
                   ifsc?.classList.add("border_red");
                }

                console.log(res);
              })
              .catch((err) => {
                console.log(err);
                bankNamefunc({})
                setIsLoading(false);
                // setPayee_Verified(false);
                // payment_verified(false)
                // profile_acVerifiedbut(true)
               
              });
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          
        });
    }
    // console.log(response)
  };

  const verifybankaccount = async () => {
    const data = {
      key: "780070794E",
      account_no: "1872155000022946",
      ifsc: "KVBL0001872",
    };
    console.log(Authorization);

    const response = await axios
      .post(
        "https://wire.easebuzz.in/api/v1/beneficiaries/bank_account/verify/",
        data,
        {
          headers: {
            // 'Content-Type': 'application/json',
            Authorization: Authorization,
          },
        }
      )
      .then((res) => {
        console.log(res.response);
         setPayee_Verified(true);
          payment_verified(true)
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
         setPayee_Verified(true);
          payment_verified(true)
        setIsLoading(false);
      });
  };

  const verifyupiaccounthash = () => {
    setmessagearr([]);
    let upi_Id = document.querySelector(".upi_id");
    let upi_name = document.querySelector(".upi_name");
    let upi_mobile_no = document.querySelector(".upi_mobile_no");

    let remove_error = document.querySelectorAll(".border_red");
    remove_error.forEach((item) => item.classList.remove("border_red"));

    if (upi_Id?.value == "") {
      seterrorupiid("Please enter the Upi Id");
      setmessagearr((pre) => [...pre, "* Please enter the Upi Id"]);
      upi_Id?.classList.add("border_red");
    }
    if (upi_name?.value == "") {
      setmessagearr((pre) => [...pre, "* Please enter the Upi Name"]);
      upi_name?.classList.add("border_red");
    }
    // if (upi_mobile_no?.value == "") {
    //   setmessagearr((pre) => [...pre, "* Please enter the Upi Mobile No"]);
    //   upi_mobile_no?.classList.add("border_red");
    // }
    if (
      upi_Id?.value == "" ||
      upi_name?.value == "" ||
     upi_mobile_no?.value == "" && profileacverified === true && profileupiverified === true
    ) {
      setOpen(true);
    } else {
      setIsLoading(true);
      const datanew = {
        Authorization: `780070794E|${UpiId}|8EA5D0A2F2`,
      };
      const res = axios
        .post("https://hotelonboardingapi.staysimplyfied.com/API/hashupi", datanew)
        .then((res) => {
          console.log(res.data.data);

          //  authorization =res.data.data
          setAuthorizationupi(res.data.data);

          const data = {
            key: "780070794E",
            vpa: UpiId,
          };
console.log(data)
          const response = axios
            .post(
              "https://wire.easebuzz.in/api/v1/beneficiaries/vpa/verify/",
              data,
              {
                headers: {
                  Authorization: res.data.data,
                },
              }
            )
            .then((res) => {
              console.log(res.data);
              console.log(res.data.data.vpa_status);
              if (res.data.data.vpa_status === "valid") {
               
                // setshowverifysymb(true);
                // setshowverifybtn(false);
                profile_upi_Verifiedbut(true);
                
                upiNamefunc(res.data.data)
              }
              else {
                setupierrormsg("Check your UPI Id and try again")
                profile_upi_Verifiedbut(false)
                setupierrormsg("Check your UPI Id and try again")
                setupierrorfunc(true)
                
              }
              upi_verified(true);
              setUpi_Verified(true);
              setIsLoading(false);
              setupierrorfunc(false)
            })
            .catch((err) => {
              console.log(err);
              upi_verified(true);
              setUpi_Verified(true);
              profile_upi_Verifiedbut(false);
              setIsLoading(false);
              setupierrormsg("Check your UPI Id and try again")
              setupierrorfunc(true)
            });
          
          upi_verified(true);
          setUpi_Verified(true);
          
        })
        .catch((err) => {
          console.log(err);
         
          upi_verified(true);
          setUpi_Verified(true);
          // setshowverifysymb(true);
          // setshowverifybtn(false);
          
        });
    }
  };

  const verifyupiaccount = async () => {
    const data = {
      key: "780070794E",
      vpa: "7010474614@ybl",
    };
    console.log(Authorizationupi);

    const response = await axios
      .post("https://wire.easebuzz.in/api/v1/beneficiaries/vpa/verify/", data, {
        headers: {
          Authorization: Authorizationupi,
        },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendcontactdetails = () => {
   

    setmessagearr([]);
    let contact_details = document.querySelectorAll(
      ".ant-table-row.ant-table-row-level-0"
    );
    if (contact_details?.length === 0) {
      setmessagearr((pre) => [...pre, "* Please add contact details"]);
    }
    if (paymentVerified === false || payee_Verified === false) {
      setmessagearr((pre) => [...pre, "* Please verify Payment information"]);
    }
    if (upiverified === false || upi_Verified === false) {
      setmessagearr((pre) => [...pre, "* Please verify UPI information"]);
    }

    let upi_Id = document.querySelector(".upi_id");

   

    if (contact_details?.length === 0 || paymentVerified === false || upi_Id !== '' ? upiverified === false : null) {
      setOpen(true);
    } else {
      // debugger
      

      let Propertyowners = [
        {
          Payeename:multispacetrim(Payeename) || bankac_name?.account_name,
          //document.querySelector(".p_name")?.value,
          Bankname: multispacetrim(Bankname) || document.querySelector(".b_name")?.value,
          Accountnumber: Accountnumber || document.querySelector(".a_no")?.value,
          IFSCcode: multispacetrim(IFSCcode) || document.querySelector(".ifsc")?.value,
          AccountType: AccountType || document.querySelector(".a_type .ant-select-selection-item").innerHTML,
          branchAddress: multispacetrim(branchAddress) || document.querySelector(".b_address")?.value,
          checkleafpath: fileList,
          UpiId: UpiId || document.querySelector(".upi_id")?.value,
          Upiname: Upiname || upi_name?.vpa_name,
          //document.querySelector(".upi_name")?.value,
          Upimobilenumber: Upimobilenumber || document.querySelector(".upi_mobile_no")?.value,
          chkleafimagepath:chkleafpath 
        },
      ];


      setData1(DataSource);
      let contactarr = DataSource;
      addToProfile([{ Propertyowners: Propertyowners, contactarr: contactarr }]);
      var form = document.getElementById("chkuploadform");
      const formData = new FormData(form);
      formData.append("file", file || profileDetails[0]?.Propertyowners[0]?.checkleafpath[0].originFileObj);
  // formData.append("fileName", filename);
  let response = axios
    .post(
      "https://hotelonboardingapi.staysimplyfied.com/API/imageupload",
      formData
    )
    .then((res) => {
      console.log(res.data.path);
      setchkleafpath(res.data.path)
     getimagepath.push(res.data.path);
    })
    .catch((error) => {
      console.log(error);
    });
      navigate("/Publish");
      profileImagePathfunc(getimagepath) 
    }
  };
  console.log(chkleafpath);
  // const fileinsert = () => {

  // };

  useEffect(() => {
    if (profileDetails[0]?.contactarr) {
      SetDataSource(profileDetails[0]?.contactarr);
      setFileList(profileDetails[0]?.Propertyowners[0]?.checkleafpath);
    }
  }, []);

  const handleDelete = (key) => {
    const newData = DataSource.filter((item) => item.key !== key);
    SetDataSource(newData);

  };

  

console.log(bankac_name && bankac_name?.account_name)
  return (
    <div>
      {isLoading && (
        <div className="loaderloading">
          <span>Loading</span>
        </div>
      )}
      <div>
        <nav className="row">
          <a className="navbar-brand col-md-2 text-center mobile-show" href="#">
            <img
              className=""
              src={pic}
              alt=""
              width={"120px"}
              height={"85px"}
            />
          </a>
          <span className="text-center col-md-12 d-flex justify-content-center align-items-center">
            <h3>
              <b>Property RFP</b>
            </h3>
          </span>
        </nav>
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-md-2 no-border-l h100"
              style={{ background: "#fff", borderLeft: "none" }}
            >
              <Card className="col-md-12 h-100 side-menu">
                <Steps
                  size="small"
                  className="mt-md-5 cursor-not-allowed mobile-hide"
                  direction="vertical"
                  current={5}
                >
                  <Steps.Step title={<NavLink to="/">Basics</NavLink>} />
                  <Steps.Step
                    title={<NavLink to="/location">Location</NavLink>}
                  />
                  <Steps.Step
                    title={<NavLink to="/facilities">Facilities</NavLink>}
                  />
                  <Steps.Step
                    title={<NavLink to="/roomdet">Room&nbsp;details</NavLink>}
                  />
                  <Steps.Step title={<NavLink to="/photos">Photos</NavLink>} />
                  <Steps.Step
                    title={<NavLink to="/Profile">Contact</NavLink>}
                  />
                  <Steps.Step
                    title={<NavLink to="/Publish">Publish</NavLink>}
                  />
                </Steps>
                <a className="navbar-brand col-md-2 text-center logo-display" href="#">
            <img
              className=""
              src={pic}
              alt=""
             width="100%"
            />
          </a>
              </Card>
              
            </div>
            <div
              className="col-md-10 layout-ml"
              style={{ background: "rgb(227 245 255)" }}
            >
              <div className="row ">
                <div className="col-12 col-lg-9 p-3">
                  <h3>Account details</h3>
                  <p>
                    Please provide your full legal name here for your contract
                    with HB. If additional information is needed to list your
                    property, we will contact you.
                  </p>
                </div>
                {/* <div className="col-sm-6">
            <img src={photoheadpic} alt="" height={"120px"}  />
          </div> */}
              </div>

              <div className="row">
                <div className="col-sm-12 col-lg-9 profile">
                  <h5>Contact details</h5>

                  <Card
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                    }}
                  >
                    <div className="row">
                      <div className="col-sm-6">
                        <label>
                          <b>Contact Type&nbsp;<span className="m-red">*</span></b>
                        </label>
                        <br />
                        <AutoComplete
                          options={options}
                          style={{
                            width: "100%",
                          }}
                          id="message"
                          name="contactType"
                          value={contacttypeval}
                          onChange={onChangeautocomplete}
                          filterOption={(inputValue, option) =>
                            option.value
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                          className="mt-0 mb-2 contact_type"
                          placeholder="Contact type"
                          onInput={propInput}
                        />
                      </div>

                      <div className="col-sm-6">
                        <label>
                          <b>Contact Name&nbsp;<span className="m-red">*</span></b>
                        </label>
                        <br />
                        <Input
                          placeholder="Contact Name"
                          className="mt-sm-2 contact_Name"
                          name="contactName"
                          style={{
                            width: "100%",
                          }}
                          value={contactName}
                          onChange={(event) => {
                            setcontactName(event.target.value.replaceAll('  ',' '));
                          }}
                          onInput={propInput}
                        ></Input>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <label>
                          <b>Email Id&nbsp;<span className="m-red">*</span></b>
                        </label>
                        <Input
                          placeholder="Email Id"
                          className="mt-sm-2 Email_Id"
                          name="Email"
                          type="text"
                          value={Email}
                          onChange={(event) => { event.target.value.includes(",") === false &&  setEmail(event.target.value.replaceAll(' ',''))
                          }}
                          onInput={propInput}
                        ></Input>
                      </div>
                      <div className="col-sm-6">
                        <label>
                          <b>Mobile No&nbsp;<span className="m-red">*</span></b>
                        </label>
                        <br />
                        <Input
                          placeholder="Mobileno"
                          className="mt-sm-2 Mobile_No"
                          name="mobileno"
                          value={Mobileno}
                          pattern="[0-9]*"
                          onChange={(event) => {
                            setMobileno((v) =>
                              event.target.validity.valid
                                ? event.target.value
                                : v
                            );
                          }}
                          maxLength={10}
                        ></Input>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <label>
                          <b>Landline No</b>
                        </label>
                        <br />
                        <Input
                          placeholder="Landline No"
                          className="mt-sm-2 landline"
                          name="landlineno"
                          value={landlineno}
                          maxLength={10}
                          onChange={(event) => {
                            setlandlineno(event.target.value.replace(/\D/g,''));
                          }}
                          onInput={propInput}
                        ></Input>
                      </div>

                      <div className="col-sm-6 mt-2  mt-md-0">
                        <label className="d-m-none">
                          <b></b>
                        </label>
                        <br className="d-m-none" />
                        <Button
                          type="primary"
                          className="float-end float-sm-start"
                          onClick={handleClick}
                          style={{
                            width: "",
                            marginLeft: "0px !important",
                          }}
                        >
                          Add
                        </Button>
                      </div>
                    </div>
                  </Card>

                  <div
                    className="col-sm-12 my-3"
                    style={{
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                      overflow: "auto",
                    }}
                  >
                    <Table
                      dataSource={DataSource}
                      style={{ background: "#fff", minWidth: "600px" }}
                      pagination={false} 
                    >
                      <Column
                        title="Contact Type"
                        key="contactType"
                        render={(_, record) => (
                          <Space size="middle">
                            <p>{record?.contactType}</p>
                          </Space>
                        )}
                      />
                      <Column
                        title="Contact Name"
                        key="contactName"
                        render={(_, record) => (
                          <Space size="middle">
                            <p>{record?.contactName}</p>
                          </Space>
                        )}
                      />
                      <Column
                        title="Email Id"
                        key="Email"
                        render={(_, record) => (
                          <Space size="middle">
                            <p>{record?.Email}</p>
                          </Space>
                        )}
                      />
                      <Column
                        title="Mobile Number"
                        key="mobileno"
                        render={(_, record) => (
                          <Space size="middle">
                            <p>{record?.mobileno}</p>
                          </Space>
                        )}
                      />
                      <Column
                        title="Landline Number"
                        key="landlineno"
                        render={(_, record) => (
                          <Space size="middle">
                            <p>{record?.landlineno}</p>
                          </Space>
                        )}
                      />
                      <Column
                    title="Action"
                    key="delete"
                    // render={(_, record) => (
                    //   <Space size="middle">

                    //   </Space>
                    // )}
                    render={(_, record) =>
                      DataSource.length >= 1 ? (
                        <Popconfirm
                          title="Sure to delete?"
                          onConfirm={() => handleDelete(record?.key)}
                        >
                          <a className="btn btn-danger btn-sm text-white d_v_c" style={{width:'30px'}}><DeleteOutlined /></a>
                        </Popconfirm>
                      ) : null
                    }
                  />
                    </Table>
                  </div>
                  <h5>Payment Information</h5>

                  <Card
                    className="mb-sm-3"
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                    }}
                  >
                    <div className="row">
                    <div className="col-sm-4">
                        <label>
                          <b>Account Number&nbsp;<span className="m-red">*</span></b>
                        </label>
                       
                        <Input
                          placeholder="Account Number"
                          pattern="[0-9]*"
                          
                            onChange={e => {setAccountnumber(textNum(e));
                            profile_acVerifiedbut(false);
                            setPayee_Verified(false);
                          }}
                          className="mt-sm-2 a_no"
                          value={Accountnumber}
                          type="text"
                          min={0}
                        ></Input>

                      </div>
                      <div className="col-sm-4">
                        <label>
                          <b>IFSC Code&nbsp;<span className="m-red">*</span></b>
                        </label>
                        <Input
                          placeholder="IFSC Code"
                          onChange={(event) => {event.target.value.includes(" ") === false &&                           
                            setIFSCcode(event.target.value);
                            profile_acVerifiedbut(false);
                            setPayee_Verified(false);
                          }}
                          className="mt-sm-2 ifsc"
                          value={IFSCcode}
                          maxLength={12}
                          onInput={propInput}
                        ></Input>
                      </div>
                      <div className="col-sm-4">
                        <label>
                          <b>Payee name&nbsp;<span className="m-red">*</span></b>
                        </label>
                        <Input
                          placeholder="Payee Name"
                          onChange={(event) => {
                            setPayeename(event.target.value.replaceAll('  ',' '));
                          }}
                          className="mt-sm-2 p_name"
                          value={Payeename
                          }
                          onInput={propInput}
                        ></Input>
                      </div>

                      

                      
                    </div>
                    <div className="row">
                     <div className="col-sm-4">
                        <label>
                          <b>Bank name&nbsp;<span className="m-red">*</span></b>
                        </label>
                        <Input
                          placeholder="Bank Name"
                          onChange={(event) => {
                            setBankname(event.target.value.replaceAll('  ',' '));
                          }}
                          className="mt-sm-2 b_name"
                          value={Bankname}
                          onInput={propInput}
                        ></Input>
                      </div>

                      <div className="col-sm-4">
                        <label>
                          <b>Account Type</b>
                        </label>
                        <Select
                           onChange={(event) => {
                            setAccounttype(event);
                          }}
                        
                          value={AccountType || "Savings"}
                          style={{ width: "100%" }}
                          // placeholder='Select Channel Manager'
                          options={[
                            {
                              value: "Current",
                              label: "Current",
                            },
                            {
                              value: "Savings",
                              label: "Savings",
                            }
                          ]}
                          className="a_type"
                        />
                      </div>

                      <div className="col-sm-4">
                        <label>
                          <b>Branch Address</b>
                        </label>
                        <Input
                          placeholder="Branch Address"
                          onChange={(event) => {
                            setbranchaddress(event.target.value.replaceAll('  ',' '));
                          }}
                          className="mt-sm-2 b_address"
                          value={branchAddress}
                          onInput={propInput}
                        ></Input>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <form id="chkuploadform">
                          <Upload
                            // action="https://www.mocky.io/v2/5cc8019d300000980a0f55e76"
                            className="mt-sm-2 c_leaf"
                            listType="picture-card"
                            fileList={fileList}
                            action={"http://localhost:8000/"}
                            onPreview={handlePreview}
                            onChange={handleChangephoto}
                            multiple={true}
                            beforeUpload={(file) => {
                              const isLt2M = file.size / 1024 / 1024 < 2;
                              if (!isLt2M) {
                                message.error("Image must smaller than 2MB!");
                                setimageerror(true);
                                return true;
                              }
                              return false;
                            }}
                          >
                            {fileList.length >= 1 ? null : uploadButton}
                          </Upload>
                          <Modal
                            open={previewOpen}
                            title={previewTitle}
                            footer={[
                              <Button key="back" onClick={handleCancel}>
                                Ok
                              </Button>,
                            ]}
                            onCancel={handleCancel}
                          >
                            <img
                              alt="example"
                              style={{
                                width: "100%",
                              }}
                              src={previewImage}
                            />
                          </Modal>
                        </form>
                      </div>

                      <div className="col-sm-8 mt-2 mt-sm-5">
                        {profileacverified === false ? <Button type="primary" onClick={verifybankaccounthash}>
                            Verify
                          </Button> : <><span className="verified_but"><CheckOutlined style={{ color: "#02cc02" }} />{" "}{console.log(bankac_name?.account_name)}
                            <span style={{ color: "#02cc02",fontWeight:"bold" }}> &nbsp;Verified&nbsp;</span></span><b style={{opacity : profileacverified === true ? 1 : 0}}> Name : </b><span style={{ color: "black" }}> {bankac_name && bankac_name?.account_name}</span> 
                         </>
                          }
                          {<p>{acerrorfunc === true ?  acerrormsg : ''}</p>}
                        <br />
                        {/* <CloseOutlined style={{color:"red"}}/><span style={{color:"red"}}>Invalid Bank Account</span> */}
                      </div>
                    </div>
                  </Card>
                  <h5 className="mt-3 mt-md-0">UPI Information</h5>
                  <Card
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                    }}
                  >
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                        <label>
                          <b>UPI ID&nbsp;<span className="m-red">*</span></b>
                        </label>
                        <div className="row">
                          <div className="col-9 col-md-9">
                            <Input
                              placeholder="UPI ID"
                              onChange={(event) => {
                                setUpiid(event.target.value.replaceAll(' ',''));
                                profile_upi_Verifiedbut(false);
                                setUpi_Verified(false);
                              }}
                              className="mt-sm-2 col-9 upi_id"
                              value={UpiId}
                              maxLength={70}
                            ></Input>
                            <p>{upierrorfunc === true ?  upierrormsg : ''}<b style={{opacity : profileupiverified === true ? 1 : 0}}>Name : </b>{profileupiverified === true && <span style={{ color: "black" }}> {upi_name?.vpa_name}</span> }</p>
                            
                          </div>
                          <div className="col-3 col-md-3 ps-0">
                        {profileupiverified === false ? <Button type="primary" onClick={verifyupiaccounthash}>
                            Verify
                          </Button> : <><span className="verified_but"><CheckOutlined style={{ color: "#02cc02" }} />{" "}
                            <span style={{ color: "#02cc02",fontWeight:"bold" }}> &nbsp;Verified &nbsp;</span> 
                         </span></> }
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-sm-6 col-md-4 mt-2 mt-sm-0">
                        <label>
                          <b>UPI Name&nbsp;<span className="m-red">*</span></b>
                        </label>
                        <Input
                          placeholder="UPI Name"
                          onChange={(event) => {
                            setUpiname(event.target.value);
                          }}
                          className="mt-sm-2 upi_name"
                          defaultValue={profileDetails?.Propertyowners && profileDetails?.Propertyowners[0]?.Upiname || Upiname
                          }
                        ></Input>
                      </div> */}

                      <div className="col-sm-6 col-md-4 mt-2 mt-sm-0">
                        <label>
                          <b>UPI&nbsp;Mobile&nbsp;Number</b>
                        </label>
                        <Input
                          placeholder="UPI Mobile Number"
                          value={Upimobilenumber}
                          pattern="[0-9]"
                        style={{ width: "100%" }}
                          
                          onChange={e => {setUpimobilenumber(textNum(e));
                            profile_upi_Verifiedbut(false);
                               setUpi_Verified(false);}}
                          className="mt-sm-2 upi_mobile_no"
                          type="text"
                          maxLength={10}
                          min={0}
                        ></Input>
                        
                      </div>
                    </div>
                  </Card>

                  <div className="float-end mt-3 mt-sm-3 mb-4">
                    <Button
                      type="primary"
                      style={{ minWidth: "100px", marginRight: "5px" }}
                    >
                      <NavLink to="/Photos">Previous</NavLink>
                    </Button>
                    {/* onClick={verifyupiaccount} */}
                    <Button
                      type="primary"
                      className="ms-sm-3"
                      style={{ minWidth: "100px" }}
                      onClick={sendcontactdetails}
                    >
                      Save & Continue
                      {/* <NavLink to="/Publish"></NavLink> */}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="error_msg"
        title="Message"
        open={open}
        onCancel={handleCancelbtn}
        footer={[
          <Button key="back" type="primary" onClick={handleCancelbtn}>
            Ok
          </Button>,
        ]}
      >
        {/* <p>{errorcontacttype}</p> */}
        {/* {errormessagearr?.map((errarr)=>
        
        (<p>{errarr}</p>)
       
      )}   */}
        {errormessagearr &&
          errormessagearr.map((item, index) => <p key={index}>{item}</p>)}
      </Modal>

      <Modal
        className="error_msg"
        title="Message"
        open={openerror}
        onCancel={handlebtncancel}
        footer={[
          <Button key="back" type="primary" onClick={handlebtncancel}>
            Ok
          </Button>,
        ]}
      >
        {/* <p>{errorcontacttype}</p> */}
        {/* {errormessagearr?.map((errarr)=>
        
        (<p>{errarr}</p>)
       
      )}   */}
        {/* {errormessagearr && <ul ><li style={{listStyle:"none"}}><b>Please enter the</b>{errormessagearr}</li><br/></ul>} */}
        {errormessagearr &&
          errormessagearr.map((item, index) => <p key={index}>{item}</p>)}

        {/* <p>{errormessagearr[0]}</p>
      <p>{errormessagearr[0]}</p>
      <p>{errormessagearr[0]}</p> */}
      </Modal>
    </div>
  );
}
