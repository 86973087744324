// import React from 'react';
// import { useState } from 'react';
// import PlacesAutocomplete, {
//   geocodeByAddress,
//   getLatLng,
// } from 'react-places-autocomplete';

// export default function Places(){
//   const [address,setaddress]=useState("");

//   const [Coordinates,setCoordinates]=useState({lat:null,lng:null})

// const  handleSelect = async value => {
//  const results=await  geocodeByAddress(value)
// const li=await getLatLng(results[0])
// console.log(li)
// setaddress(value)
// setCoordinates(li)
//   };

//     return (
//       <>
//         <PlacesAutocomplete
//         value={address}
//         onChange={setaddress}
//         onSelect={handleSelect}
//       >
//         {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
//           <div>
//             <input
//               {...getInputProps({
//                 placeholder: 'Search Places ...',
//                 className: 'location-search-input',
//               })}
//             />
//             <div className="autocomplete-dropdown-container">
//               {/* {loading && <div>Loading...</div>} */}
//               {suggestions.map(suggestion => {
//                 const className = suggestion.active
//                   ? 'suggestion-item--active'
//                   : 'suggestion-item';
//                 // inline style for demonstration purpose
//                 const style = suggestion.active
//                   ? { backgroundColor: '#fafafa', cursor: 'pointer',width:"150px" }
//                   : { backgroundColor: '#ffffff', cursor: 'pointer',width:"150px" };
//                 return (
//                   <div
//                     {...getSuggestionItemProps(suggestion, {
//                       className,
//                       style,
//                     })}
//                   >
//                     <span>{suggestion.description}</span>
//                   </div>
//                 );
//               })}
//             </div>
//           </div>
//         )}
//       </PlacesAutocomplete>
//       <span>
// {Coordinates.lat}
// {Coordinates.lng}
//       </span>
//       </>
//     );
//   }
import { useState, useMemo } from "react";
import { GoogleMap, useLoadScript, Marker} from "@react-google-maps/api";
// import { GoogleMapProps } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { result } from "lodash";


export default function Places({ latlng }) {
  
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyA64hWNaMkhU56Bz0nix2TOzJJf2DoqT0A",
    libraries: ["places"],
    
    // region:"in"
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <Map latlng={latlng} />;
}

function Map({ latlng,localityname }) {
  // const center = useMemo((latlng) => ({ lat: 28, lng: 77 }), []);
  const [selected, setSelected] = useState(null);
  const [ lat, setLat ] = useState(55.64233320580574)
  const [ lng, setLng ] = useState(12.089922851562505)
  console.log(latlng);
 


  const onMarkerDragEnd = (coord) => {
    console.log('coord', coord)
    if(coord && coord.latLng) {
      const { latLng } = coord;
      // console.log('latLng')
      // const lat = latLng.lat('-34.397');
      // const lng = latLng.lng('150.644');
      setLat(latLng.lat())
      setLng(latLng.lng())
    }
  }
  console.log(localityname)
  console.log(document.getElementById("googlemapid"))

  // const map = new google.maps.Map(document.getElementById("googlemapid"), {
  //   zoom: 4,
  //   center: { lat: 28, lng: 77 },
  // });

  
  return (
    <>
      {/* <div className="places-container">
        <PlacesAutocomplete setSelected={setSelected} getlatlng={getlatlng}/>
      </div> */}
      <GoogleMap
        // options={{latLng:google.maps.ControlPosition}}
        zoom={latlng ? 15 : 7}
        clickableIcons={true}
        center={latlng || { lat: 28, lng: 77 }}
        // mapContainerClassName="map-container"
        mapContainerStyle={{ height: "400px", width: "100%" }}
        id="googlemapid"
      >
        {latlng && <Marker position={latlng}  draggable={true} onDragEnd={(e)=>onMarkerDragEnd(e)} />}
      </GoogleMap>
    </>
  );
}

// const PlacesAutocomplete = ({ setSelected,getlatlng }) => {
//   const {
//     ready,
//     value,
//     setValue,
//     suggestions: { status, data },
//     clearSuggestions,
//   } = usePlacesAutocomplete();

//   const handleSelect = async (address) => {
//     setValue(address, false);
//     clearSuggestions();

//     const results = await getGeocode({ address });
//     const { lat, lng } = await getLatLng(results[0]);
//     // getlatlng({lat,lng})
//     setSelected({ lat, lng });
//   };

//   return (
//     <Combobox onSelect={handleSelect}>
//       <ComboboxInput
//         value={value}
//         onChange={(e) => setValue(e.target.value)}
//         disabled={!ready}
//         className="combobox-input"
//         placeholder="Search an address"
//       />
//       <ComboboxPopover>
//         <ComboboxList>
//           {status === "OK" &&
//             data.map(({ place_id, description }) => (
//               <ComboboxOption key={place_id} value={description} />
//             ))}
//         </ComboboxList>
//       </ComboboxPopover>
//     </Combobox>
//   );
// };
