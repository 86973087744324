import { useState, useEffect, useContext, useCallback } from "react";
import roomheadpic from "../assets/ec-pricing@2x.png";
import { Card } from "antd";
import "../styles/propinfo.css";
import "antd/dist/antd.css";
import { Steps } from "antd";
import pic from "../assets/unnamed.png";
import { Input } from "antd";
import { Button } from "antd";
import { NavLink } from "react-router-dom";
import { AutoComplete, Tabs } from "antd";
import {
  ConsoleSqlOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Modal, Upload, message, Popconfirm } from "antd";
import { event } from "jquery";
import { Select, DatePicker } from "antd";
import { Space, Table, Tag } from "antd";
import Column from "antd/lib/table/Column";
import { Checkbox, Col, Row } from "antd";
import moment from "moment";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Radio } from "antd";
import { Img } from "@chakra-ui/react";
import { DeleteOutline } from "@mui/icons-material";
import { PropertyContext } from "../../HB Components/propertyContext/PropertyContext";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export default function Roomdet() {
  const { roomDetails, addToRoomDetails,textNum } = useContext(PropertyContext);
  const description = "This is a description.";

  const [RoomType, setRoomType] = useState("");
  const [ValidityFrom, setValidityFrom] = useState("");
  const [ValidityTo, setValidityTo] = useState("");
  const [SingleRackRate, setSingleRackRate] = useState("");
  const [SingleDiscountRate, setSingleDiscountRate] = useState("");
  const [DoubleDiscountRate, setDoubleDiscountRate] = useState("");
  const [DoubleRackRate, setDoubleRackRate] = useState("");
  const [Tacper, setTacper] = useState("");
  
  const [Startvalue, setStartValue] = useState("");
  const [checkoutdt, setcheckoutdt] = useState("");
  const [endValue, setEndValue] = useState("");
  const [checkindt, setcheckindt] = useState("");
  const [Inclusions, setInclusions] = useState(["WIFI"]);
  const [Roomimagepath, setroomimagepath] = useState("");
  const [showbtcperiod, setshowbtcperiod] = useState(false);
  const [errorroomtype, seterrorroomtype] = useState("");
  const [errorvalidityfrom, seterrorvalidityfrom] = useState("");
  const [errorsinglerackrate, seterrorsinglerackrate] = useState("");
  const [errorsingdiscountrate, seterrorsingdiscountrate] = useState("");
  const [errordoublerackrate, seterrordoublerackrate] = useState("");
  const [errordoublediscountrate, seterrordoublediscountrate] = useState("");
  const [Openeror, setOpenerror] = useState(false);
  const [errormealplan, seterrormealplan] = useState("");
  const [errortacper, seterrortacper] = useState("");
  const [btccreditperiod, setbtccreditperiod] = useState(roomDetails?.btccreditperiod);
  const [disabledvalidityto, setdisablevalidityto] = useState(false);
  const [btccreditperiodoption, setbtccreditperiodoption] = useState(roomDetails?.btccreditperiodoption || "No");
  const [errormessagearr, setmessagearr] = useState([]);
  const [errorphotofilesize, seterrorphotofilesize] = useState(false);
  const { CheckableTag } = Tag;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(""); 
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [open, setOpen] = useState(false);
  const [file, setfile] = useState("");
  const [name, setname] = useState();
  const [cart, setCart] = useState([
    { roomtype: null, singletariff: null, doubletariff: null, mealplan: null },
  ]);
  const [cart1, setCart1] = useState([cart]);
  const [roomtype, setroomtypoe] = useState([]);
  const [singletariff, setsingletariff] = useState([]);
  const [doubletariff, setdoubletariff] = useState([]);
  const [mealplan, setmealplan] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const handleClick = () => {
    // prop_roomtype
    // prop_indate
    // prop_outdate
    // singlerackrate
    // singlediscount
    // doublerate
    // doublediscountrate
    // mealplan
    // tac
    // inclusions
    // prop_upload
    let btc = document.querySelector(".btc");
    let room_type = document.querySelector("#message");
    let date_in = document.querySelector("#date_in");
    let date_out = document.querySelector("#date_out");
    let singlerackrate = document.querySelector(".singlerackrate");
    let singlediscount = document.querySelector(".singlediscount");
    let doublerate = document.querySelector(".doublerate");
    let doublediscountrate = document.querySelector(".doublediscountrate");
    let meal_plan = document.querySelector(".mealplan >  div > span > input");
    let mealplan_input = document.querySelector(
      ".mealplan >  div > span.ant-select-selection-item"
    );
    let tac = document.querySelector(".tac");
    let prop_upload = document.querySelector(".prop_upload");

    let remove_error = document.querySelectorAll(".border_red");

    remove_error.forEach((item) => item.classList.remove("border_red"));

    console.log(moment(endValue._d).format("D-M-Y").toString());
    setValidityTo(moment(endValue._d).format("DD-MM-YYYY").toString());
    console.log(typeof ValidityTo);
    setmessagearr([]);
    // console.log(errormessagearr)
    console.log(RoomType);
    if (btc?.value === "") {
      setmessagearr((pre) => [...pre, "* Please enter the  BTC value"]);
      btc?.classList.add("border_red");
      //RoomType.classList.add("border_red");
    }
    if (room_type?.value === "") {
      setmessagearr((pre) => [...pre, "* Please enter the  RoomType"]);
      room_type?.closest(".ant-select-selector").classList.add("border_red");
      //RoomType.classList.add("border_red");
    }
    if (date_in?.value === "") {
      console.log(Startvalue);
      setmessagearr((pre) => [...pre, "* Please enter the Validity from date"]);
      date_in?.closest(".ant-picker").classList.add("border_red");
    }
    if (singlerackrate?.value === "") {
      setmessagearr((pre) => [...pre, "* Please enter the Single Rate"]);
      singlerackrate?.classList.add("border_red");
    }else {
      if (singlerackrate?.value.length < 3 ) {
        setmessagearr((pre) => [...pre, "* Single Rack Rate should be minimum 3 digits"]);
        singlerackrate?.classList.add("border_red");
      }
    }
    if (singlediscount?.value === "") {
      setmessagearr((pre) => [...pre, "* Please enter the Single Discount Rate"]);
      singlediscount?.classList.add("border_red");
    }else {
      if (singlediscount?.value.length < 3 ) {
        setmessagearr((pre) => [...pre, "* Single Discount Rate should be minimum 3 digits"]);
        singlediscount?.classList.add("border_red");
      }
    }
    if (parseInt(SingleDiscountRate) > parseInt(SingleRackRate)) {
      setmessagearr((pre) => [
        ...pre,
        "* Single discount rate should be less than single rack rate",
      ]);
      singlediscount?.classList.add("border_red");
    }

    if (doublerate?.value === "") {
      setmessagearr((pre) => [...pre, "* Please enter the Double Rate"]);
      doublerate?.classList.add("border_red");
    }
    else {
      if (doublerate?.value.length < 3 ) {
        setmessagearr((pre) => [...pre, "* Double Rack Rate should be minimum 3 digits"]);
        doublerate?.classList.add("border_red");
      }
    }
    if (doublediscountrate?.value === "") {
      setmessagearr((pre) => [...pre, "* Please enter the Double discount Rate"]);
      doublediscountrate?.classList.add("border_red");
    }else {
      if (doublediscountrate?.value.length < 3) {
        setmessagearr((pre) => [...pre, "* Double discount Rate should be 3 digits"]);
        doublediscountrate?.classList.add("border_red");
      }

    }
    if (parseInt(DoubleDiscountRate) > parseInt(DoubleRackRate)) {
      setmessagearr((pre) => [
        ...pre,
        "* Double discount rate should be less than Double rack rate",
      ]);
      doublediscountrate?.classList.add("border_red");
    }

    if (mealplan === "") {
      setmessagearr((pre) => [...pre, "* Please enter the Mealplan"]);
      meal_plan?.closest(".ant-select-selector").classList.add("border_red");
    }
    // if (mealplan_input?.innerHTML !=='Meal Plan') {
    //   mealplan?.closest(".ant-select-selector").classList.remove("border_red");
    // }
    // if (tac?.value === "") {
    //   setmessagearr((pre) => [...pre, "* Please enter the TAC Percentage"]);
    //   tac?.classList.add("border_red");
    // }else {
    //   if (tac?.value.length < 2 || tac?.value.length > 2 ) {
    //     setmessagearr((pre) => [...pre, "* TAC Percentage should be 2 digits"]);
    //     tac?.classList.add("border_red");
    //   }

   // }
    if (fileList.length === 0) {
      setOpenerror(true);
      setmessagearr((pre) => [...pre, "* Room Image is Missing"]);
      prop_upload?.classList.add("border_red");
    } else if (errorphotofilesize === true) {
      setOpenerror(true);
      setmessagearr((pre) => [...pre, "* Room Image should be less than 2 MB"]);
      prop_upload?.classList.add("border_red");
    }

    if (
      room_type?.value === "" ||
      date_in?.value === "" ||
      singlerackrate?.value === "" ||
      singlediscount?.value === "" ||
      singlerackrate?.value.length < 3 ||
      singlediscount?.value.length < 3 ||
      parseInt(SingleDiscountRate) > parseInt(SingleRackRate) ||
      doublerate?.value === "" ||
      doublediscountrate?.value === "" ||
      doublerate?.value.length < 3  ||
      doublediscountrate?.value.length < 3 ||
      parseInt(DoubleDiscountRate) > parseInt(DoubleRackRate) || 
      mealplan === "" ||
     // tac?.value === "" ||
      fileList.length === 0
    ) {
      setOpenerror(true);
    } else {
      console.log(errormessagearr[0]);
      console.log(errormessagearr[0]);
      let newcontactDetail;
      let incrementkey = 1;
      // let roomimages=[];
   var form = document.getElementById('formimg');
     const formData = new FormData(form);
      formData.append("file", file);
      console.log(file)
   
            
      let response = axios
      .post(
        "https://hotelonboardingapi.staysimplyfied.com/API/imageupload",
        formData
      )
      .then((res) => {
       console.log(res.data)
       newcontactDetail = {
        key: Math.floor(Math.random() * 100),
        RoomType: RoomType,
        ValidityFrom: ValidityFrom,
        //ValidityTo: ValidityTo,
        ValidityTo: document.querySelector("#date_out")?.value,
        SingleRackRate: SingleRackRate + ".00",
        SingleDiscountRate: SingleDiscountRate + ".00",
        DoubleRackRate: DoubleRackRate + ".00",
        DoubleDiscountRate: DoubleDiscountRate + ".00",
        MealPlan: mealplan,
        Tacper: Tacper,
        Inclusions: Inclusions,
        RoomImagepreview: fileList[0]?.thumbUrl,
        RoomImagename:fileList[0]?.name,
        Roomfile:fileList[0].originFileObj,
        Roomimagepath:res.data.path 
      };
      console.log(fileList[0].name);
      // console.log(Math.floor(Math.random() * 100))

      incrementkey++;
      // newcontactDetail.key = newcontactDetail.key + 1 || 1;

      //  if(newcontactDetail.)
      SetDataSource((pre) => {
        return [...pre, newcontactDetail];
      });

      setRoomType("");
      setStartValue("");
      setEndValue("");
      setSingleRackRate("");
      setSingleDiscountRate("");
      setDoubleRackRate("");
      setDoubleDiscountRate("");
      setmealplan("");
      setTacper("");
      setInclusions(["WIFI"]);
      setFileList([]);
      setSelectedTags(["WIFI"])
      }).catch((error) => {
        console.log(error);
      });
      
    }

    // console.log(dataentered)
  };
  console.log(roomDetails);
  useEffect(() => {
    console.log(endValue);
    // console.log(location.state);
    // console.log(Startvalue)
    // console.log(window.history.state.usr)
  }, []);
  const [DataSource, SetDataSource] = useState([
    // {
    //   key:"1",
    //   contactType:"",
    //   contactName:"",
    //   Email:"",
    //   mobileno:"",
    //   landlineno:"",
    // },
  ]);

  const fileinsert = () => {
    // var form = document.getElementById('formimg');
    //  const formData = new FormData(form);
    //   formData.append("file", file);
    //   console.log(file)
    // navigate("/photos",{state:{
    //   Propertyname:location.state.propertyname,checkintime:location.state.checkintime,
    // checkintype:location.state.checkintype,gstnumber:location.state.gstnumber,
    // propertydescription:location.state.propdescription,cancellationpolicy:location.state.cancellationpolicy,
    // websiteAddress:location.state.websiteAddress,legalname:location.state.legalname,Addrsline1:location.state.Addrsline1,Addrsline2:location.state.Addrsline2,
    // State:location.state.State,City:location.state.City,Locality:location.state.Locality,StateId:location.state.StateId,
    // CityId:location.state.CityId,localityId:location.state.localityId,Pincode:location.state.Pincode,
    // Directions:location.state.Directions,Amenities:location.state.Amenities,Roomdet:item
    // }})
    //  let response= axios.post("http://localhost:3000/api/imageupload",formData)
    // .then((res) => {
    //     console.log(res.data.path)
    // }).catch((error) => {
    //     console.log(error)
    // });
    // console.log(formData)
  };

  const options = [
    {
      value: "Standard",
    },
    {
      value: "Deluxe",
    },
    {
      value: "Executive",
    },
    {
      value: "Suite",
    },
    {
      value: "Premium",
    },
  ];

  const Nextpagerouting = () => {
    setmessagearr([]);
    let room_type_room_details = document.querySelectorAll(
      ".ant-table-row.ant-table-row-level-0"
    );
    console.log(room_type_room_details?.length)
    //   if(errormessagearr[0]?.length!==0 )
    //   {
    //     //debugger
    //     console.log(errormessagearr)
    //   if (DataSource.length==0 && RoomType === "" ) {

    //     setmessagearr((pre) => [...pre," RoomType"]);
    //     setOpenerror(true);
    //   }
    //   if (DataSource.length==0 && Startvalue == "") {
    //     console.log(Startvalue)
    //     setmessagearr((pre) => [...pre," Validity from date"]);
    //     setOpenerror(true);

    //   }
    //    if (DataSource.length==0 && SingleRackRate === "") {
    //     setOpenerror(true);
    //     setmessagearr((pre) => [...pre," Single Rate"]);

    //   }  if (DataSource.length==0 && SingleDiscountRate === "") {
    //     setOpenerror(true);
    //     setmessagearr((pre) => [...pre," Single Discount Rate"]);

    //   } if (DataSource.length==0 && DoubleRackRate === "") {
    //     setOpenerror(true);
    //     setmessagearr((pre) => [...pre," Double Rate"]);
    //   }  if (DataSource.length==0 && DoubleDiscountRate === "") {
    //     setOpenerror(true);

    //     setmessagearr((pre) => [...pre," Double discount Rate"]);
    //   }  if (DataSource.length==0 && mealplan === "") {
    //     setOpenerror(true);
    //     setmessagearr((pre) => [...pre," Mealplan"]);
    //   } if (DataSource.length==0 && Tacper === "") {
    //     setOpenerror(true);

    //     setmessagearr((pre) => [...pre," TAC Percentage"]);
    //   }
    //   if (DataSource.length==0 && fileList.length===0) {
    //     setOpenerror(true);

    //     setmessagearr((pre) => [...pre," RoomPhoto is Missing"]);
    //   }
    //   if(btccreditperiodoption==="" || btccreditperiod)
    //   {
    //     setOpenerror(true);

    //     setmessagearr((pre) => [...pre," Select BTC credit period"]);
    //   }

    // }

    let btc_val = document.querySelector(".btc")
    if (room_type_room_details?.length === 0 || room_type_room_details?.length === undefined  ) {
      setmessagearr((pre) => [...pre, "Please Add the Room details"]);
      setOpenerror(true);
    } else {
      if(btc_val?.value === '') {
        setmessagearr((pre) => [...pre, "Please Add BTC / Credit Period"]);
      setOpenerror(true);
      }
      else {
      console.log(errormessagearr);
      let roomdetarr = DataSource;
      addToRoomDetails({
        btccreditperiod: btccreditperiod || roomDetails.btccreditperiod,
        btccreditperiodoption:
          btccreditperiodoption || roomDetails.btccreditperiodoption,
        roomdetarr: roomdetarr || roomDetails.roomdetarr,
      });
      console.log(DataSource)

      // localStorage.setItem("btccreditperiod", JSON.stringify(btccreditperiod));
      // localStorage.setItem("btccreditperiodoption", JSON.stringify(btccreditperiodoption));
      // localStorage.setItem("RoomDetails", JSON.stringify(roomdetarr));

      // localStorage.setItem("roomdetailstartdate",JSON.stringify(Startvalue))
      // localStorage.setItem("roomdetailsenddate",JSON.stringify(endValue))
      navigate("/photos");
    }
  }
  }
  const onChangeautocomplete = (data, event) => {
    console.log(data);
    setRoomType(data);
    // setRoomType(event.target?.value);
  };

  const handleKeyPress = (ev) => {
    console.log("handleKeyPress", ev.target.value);
    setRoomType(ev.target.value);
  };
  const chkoutdtchge = (date, dateString) => {
    console.log(date, dateString);
    setcheckoutdt(date._d);

    setEndValue(date);
  };
  const chkindtchge = (date, dateString) => {
    console.log(date, dateString);
    setStartValue(date);
    setcheckindt(date._d);
    setValidityFrom(dateString);

    let setdt = moment(date).year();
    let settmt = moment(date).month() + 12;
    let setday = moment(date).date();
    console.log(setday);
    console.log(moment(setdt)._d);
    console.log(moment(setdt).set({ year: setdt, month: settmt, date: setday }));
    setEndValue(
      moment(setdt).set({ year: setdt, month: settmt, date: setday })
    );
    setValidityTo(moment(endValue).format("D-M-Y"));

    // moment(setdt).set({ year: setdt, month: settmt, date: setday })
    // console.log(ValidityTo)

    // setValidityTo(dateString)
    // console.log(moment(setdt).set({'year': setdt}))
  };
  const [selectedTags, setSelectedTags] = useState(["WIFI"]);
  const [selectedTagsmeal, setSelectedTagsmeal] = useState(["MAP"]);

  const handleChangetag = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    console.log("You are interested in: ", nextSelectedTags);
    setInclusions(nextSelectedTags);
    setSelectedTags(nextSelectedTags);
  };
  const handleChangetagmeal = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTagsmeal, tag]
      : selectedTagsmeal.filter((t) => t !== tag);
    console.log("You are interested in: ", nextSelectedTags);
    setSelectedTagsmeal(nextSelectedTags);
  };

  const tagsData = ["CP", "EP-Room only", "MAP", "AP"];
  const tagsDatainc = [
    "WIFI",
    "Airport transport",
    "Office Transfer",
    "F & B Discount ",
  ];
  const { Column } = Table;

  const handleChange = (value) => {
    console.log(`Selected: ${value}`);
    setmealplan(value);
  };
 

  const handleCancel = () => setPreviewOpen(false);
  const handleCancelmodal = () => setOpenerror(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
      // console.log(file)
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  // const showModal = () => {
  //   if(fileList.length===0)
  //   {
  //     setOpen(true)
  //     // seterrorphoto("Please provide the Property Photos")
  //     // setpropdesc("")
  //     console.log(errorphoto)

  // }
  // else{
  //   window.location.href='/Profile'
  // }

  // }

  // ({ fileList: newFileList }) => setFileList(newFileList)
  const handleChangephoto = ({ fileList: newFileList }) => {
    console.log(newFileList);
    setFileList(newFileList);
    setfile(newFileList[0]?.originFileObj);
    // cons
  };

  const uploadButton = (
    <div className="p-4" style={{ width: "500px", height: "100px" }}>
      <PlusOutlined />
      <div><b>Room Image <span className="m-red">*</span></b></div>
    </div>
  );

  const InsertRoomdetail = () => {
    navigate("/photos");
    // DataSource.map(item=>{
    // let data={
    //   Propertyname:location.state.propertyname,checkintime:location.state.checkintime,
    //   checkintype:location.state.checkintype,gstnumber:location.state.gstnumber,
    //   propertydescription:location.state.propdescription,cancellationpolicy:location.state.cancellationpolicy,
    //   websiteAddress:location.state.websiteAddress,legalname:location.state.legalname,Addrsline1:location.state.Addrsline1,Addrsline2:location.state.Addrsline2,
    //   State:location.state.State,City:location.state.City,Locality:location.state.Locality,StateId:location.state.StateId,
    //   CityId:location.state.CityId,localityId:location.state.localityId,Pincode:location.state.Pincode,
    //   Directions:location.state.Directions,Amenities:location.state.Amenities,Roomdet:item}

    // let response=axios.post("http://localhost:3000/api/propins",data).then((res)=>{
    //   console.log(res.data)
    // }).catch((err)=>{
    //   console.log(err)
    // })})
  };
  const handleDelete = (key) => {
    const newData = DataSource.filter((item) => item.key !== key);
    SetDataSource(newData);
    addToRoomDetails(newData);
  };

  const sendmail = () => {
    let response = axios
      .post("http://localhost:3000/api/propmail")
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChangeradiobtc = (e) => {
    console.log(e.target.value);
    setbtccreditperiodoption(e.target.value);
    if (e.target.value == "Yes") {
      console.log(true);
      setshowbtcperiod(true);
    }
    if (e.target.value == "No") {
      console.log(false);
      setshowbtcperiod(false);
    }
  };

  // const zohocreate=()=>{

  // }
  useEffect(() => {
    if (roomDetails?.roomdetarr) {
      SetDataSource(roomDetails.roomdetarr);
    }
    if(roomDetails?.btccreditperiodoption === "Yes")
    {setshowbtcperiod(true)}
  }, []);
  console.log(Inclusions);
  return (
    <div>
      <nav className="row">
        <a className="navbar-brand col-md-2 text-center mobile-show" href="#">
          <img
            className=""
            src={pic}
            alt=""
            width={"120px"}
            height={"85px"}
          />
        </a>
        {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon">fsfds</span>
      </button> */}
        <span className="text-center col-md-12 d-flex justify-content-center align-items-center">
          <h3>
            <b>Property RFP</b>
          </h3>
        </span>
      </nav>
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-md-2 no-border-l h100"
            style={{ background: "#fff", borderLeft: "none" }}
          >
            <Card className=" col-md-12 cursor-not-allowed h-100 side-menu">
              <Steps
                size="small"
                className="mt-md-5 mobile-hide"
                direction="vertical"
                current={3}
              >
                <Steps.Step title={<NavLink to="/">Basics</NavLink>} />
                <Steps.Step
                  title={<NavLink to="/location">Location</NavLink>}
                />
                <Steps.Step
                  title={<NavLink to="/facilities">Facilities</NavLink>}
                />
                <Steps.Step
                  title={<NavLink to="/roomdet">Room details</NavLink>}
                />
                <Steps.Step title={<NavLink to="/photos">Photos</NavLink>} />
                <Steps.Step title={<NavLink to="/Profile">Contact</NavLink>} />
                <Steps.Step title={<NavLink to="/Publish">Publish</NavLink>} />
              </Steps>
              <a className="navbar-brand col-md-2 text-center logo-display" href="#">
            <img
              className=""
              src={pic}
              alt=""
             width="100%"
            />
          </a>
            </Card>
            {/* <div className='d-flex justify-content-center'> */}
          </div>
          <div
            className="col-md-10 roomdet layout-ml"
            style={{ background: "rgb(227 245 255)" }}
          >
            <div className="row ">
              <div className="col-sm-6 p-3">
                <h3>Setup your rooms</h3>
                <p>
                  Choose your nightly room rates based on guests and other
                  conditions.
                </p>
              </div>
              <div className="col-sm-6">
                <img src={roomheadpic} alt="" height={"120px"} />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 p-3">
                <h5>Rooms Details</h5>
                <p>
                  You only need to create one room type at this stage.
                  Additional room types can be added through our extranet.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-9">
                <h5>BTC / Credit Period <span className="m-red">*</span></h5>
                <Card
                  style={{
                    background: "#f4f4f4",
                    boxShadow: "rgb(207 202 202) 0px 0px 10px",
                    minHeight: "57px",
                    display: "flex",
                  }}
                  className="asn-center"
                >
                  <div className="row">
                    <div className="col-lg-12 pe-0 d_f_a_c">
                      <Radio.Group
                        onChange={onChangeradiobtc}
                        value={btccreditperiodoption}
                        className="float-start"
                      >
                        <Radio value={"Yes"}>
                          <b>Yes</b>
                        </Radio>

                        <Radio value={"No"}>
                          <b>No</b>
                        </Radio>
                      </Radio.Group>
                      {((btccreditperiodoption === "Yes" && showbtcperiod === true) || roomDetails?.btccreditperiodoption === "Yes" && showbtcperiod === true) && (
                        <div className="col-lg-9 ps-0 float-start">
                          <div className="row">
                            <div className="col-sm-3 col-lg-3">
                              <Input
                                type="text"
                                maxLength={2}
                                min={0}
                                className="mt-sm-0 mt-1 w-100 btc"
                                style={{ width: "150px" }}
                              
                                onChange={e => setbtccreditperiod(textNum(e))}
                                value={btccreditperiod}
                              ></Input>
                            </div>

                            <span className="ps-2 ps-sm-0 col-sm-8">
                              days from invoice submission
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-12 col-lg-9">
                <Card
                  style={{
                    background: "#f4f4f4",
                    boxShadow: "rgb(207 202 202) 0px 0px 10px",
                  }}
                >
                  <div className="row">
                    <div className="col-sm-4">
                      <label>
                        <b>Room Type&nbsp;<span className="m-red">*</span></b>
                      </label>
                      <AutoComplete
                        options={options}
                        style={{
                          width: "100%",
                        }}
                        id="message"
                        name="Roomtype"
                        value={RoomType}
                        onChange={onChangeautocomplete}
                        onKeyUp={handleKeyPress}
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        placeholder="Room Type"
                        className="prop_roomtype"
                      />
                    </div>

                    <div className="col-sm-4">
                      <label>
                        <b>Validity From&nbsp;<span className="m-red">*</span></b>
                      </label>
                      <br />
                      <DatePicker
                        value={Startvalue}
                        format="DD-MM-YYYY"
                        onChange={chkindtchge}
                        style={{ width: "100%" }}
                        className="prop_indate"
                        placeholder="Validity From"
                        id="date_in"
                      />
                    </div>
                    <div className="col-sm-4">
                      <label>
                        <b>Validity To</b>
                      </label>
                      <br />
                      <DatePicker
                        value={endValue}
                        format="DD-MM-YYYY"
                        id="date_out"
                        onChange={chkoutdtchge}
                        style={{ width: "100%" }}
                        className="prop_outdate"
                        placeholder="Validity To"
                        disabledDate={(current) => {
                          let customDate = moment(Startvalue).format("YYYY-MM-DD");
                         // let customDate1 = moment(endValue).format("YYYY-MM-DD");
                          return (current && current < moment(customDate, "YYYY-MM-DD"));
                        }} 
                        
                      />
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            {/* <div className="col-sm-3 p-4"> */}
            <div className="row mt-2">
              <div className="col-sm-4 col-lg-3">
                <Card
                  // title="Single Tariff"
                  bordered={true}
                  style={{
                    background: "#f4f4f4",
                    boxShadow: "rgb(207 202 202) 0px 0px 10px",
                  }}
                >
                  <label>
                    <b>Single Rack Rate&nbsp;<span className="m-red">*</span></b>
                  </label>
                  <Input
                    placeholder="Rack Rate"
                    type="text"
                    min={3}
                    maxLength={5}
                    value={SingleRackRate}
                    onChange={e => setSingleRackRate(textNum(e))}
                    className="singlerackrate"
                  ></Input>
                  <br />
                  <label className="mt-md-1">
                    <b>Single Discount Rate&nbsp;<span className="m-red">*</span></b>
                  </label>
                  <Input
                    className="mt-1 mt-sm-0 singlediscount"
                    minLength={3}
                    min={3}
                    maxLength={5}
                    type="text"
                    onChange={e => setSingleDiscountRate(textNum(e))}
                    value={SingleDiscountRate}
                    placeholder="Discount Rate"
                  />
                </Card>
              </div>
              <div className="col-sm-4 col-lg-3 ps-sm-3 mt-2 mt-sm-0">
                <Card
                  // title="Double Tariff"
                  style={{
                    background: "#f4f4f4",
                    boxShadow: "rgb(207 202 202) 0px 0px 10px",
                  }}
                >
                  <label>
                    <b>Double Rack Rate&nbsp;<span className="m-red">*</span></b>
                  </label>
                  <Input
                    placeholder="Rack Rate"
                    minLength={3}
                    min={3}
                    maxLength={5}
                    value={DoubleRackRate}
                    type="text"
                    onChange={e => setDoubleRackRate(textNum(e))}
                    className="doublerate"
                  ></Input>
                  <br />
                  <label className="mt-md-1">
                    <b>Double Discount Rate&nbsp;<span className="m-red">*</span></b>
                  </label>
                  <Input
                    className="mt-1 mt-sm-0 doublediscountrate"
                    minLength={3}
                    min={3}
                    maxLength={5}
                    value={DoubleDiscountRate}
                    type="text"
                    onChange={e => setDoubleDiscountRate(textNum(e))}
                    placeholder="Discount Rate"
                  />
                </Card>
              </div>
              <div className="col-sm-4 col-lg-3 ps-sm-3 mt-2 mt-sm-0">
                <Card
                  style={{
                    background: "#f4f4f4",
                    boxShadow: "rgb(207 202 202) 0px 0px 10px",
                  }}
                >
                  <label>
                    <b>Meal Plan&nbsp;<span className="m-red">*</span></b>
                  </label>
                  <br />
                  <Select
                    className="mealplan"
                    placeholder="Meal Plan"
                    defaultValue="EP-Room only"
                    style={{ width: "100%" }}
                    onChange={handleChange}
                    value={mealplan}
                    options={[
                      {
                        value: "CP-breakfast",
                        label: "CP-breakfast",
                      },
                      {
                        value: "EP-Room only",
                        label: "EP-Room only",
                      },
                      {
                        value: "MAP-breakfast,lunch",
                        label: "MAP-breakfast,lunch",
                      },
                      {
                        value: "AP",
                        label: "AP-breakfast,lunch,Dinner",
                      },
                    ]}
                  />

                  <br />
                  <label className="mt-md-1">
                    <b>TAC %</b>
                  </label>
                  <Input
                    placeholder="TAC %"
                    type="text"
                    onChange={e => setTacper(textNum(e))}
                    value={Tacper}
                    max={50}
                    min={1}
                    maxLength={2}
                    className="mb-sm-1 tac"
                  />
                </Card>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-sm-12 col-lg-9 inclusions">
                <div className="row">
                  <div className="col-6 col-sm-4">
                    <Card
                      //title="Inclusions *"
                      style={{
                        background: "#f4f4f4",
                        boxShadow: "rgb(207 202 202) 0px 0px 10px",
                      }}
                    >
                      <div className="col-sm-12">
                        <label className="w-100">
                          <b>Inclusions</b>
                        </label>
                        {tagsDatainc.map((tag) => (
                          <CheckableTag
                            key={tag}
                            checked={selectedTags.indexOf(tag) > -1}
                            onChange={(checked) => {
                              handleChangetag(tag, checked);
                            }}
                            // style={{width:"50px"}}
                            className="w-100"
                            value={Inclusions}
                          >
                            <li style={{ display: "inline" }} className="w-100">
                              {"> " + tag}
                            </li>
                          </CheckableTag>
                        ))}
                      </div>
                    </Card>
                  </div>
                  <div className="col-6 col-sm-8">
                    <div className="row h-100">
                      <div className="col-12 col-sm-6 f-height-o">
                      <form id="formimg" action="#" className="f-height-o">
                        <Upload
                          // action="https://www.mocky.io/v2/5cc8019d300000980a0f55e76"
                          listType="picture-card"
                          fileList={fileList}
                          // action={"http://localhost:8000/"}
                          onPreview={handlePreview}
                          onChange={handleChangephoto}
                          multiple={true}
                          accept={".jpg, .jpeg, .png"}
                          beforeUpload={(file) => {
                            const isLt2M = file.size / 1024 / 1024 < 2;
                            if (!isLt2M) {
                              seterrorphotofilesize(true);
                              return true;
                            }
                            return false;
                          }}
                          className="f-height prop_upload"
                        >
                          {fileList.length >= 1 ? null : uploadButton}
                        </Upload>
                        </form>
                      </div>
                      <Modal
                        open={previewOpen}
                        title={previewTitle}
                        footer={null}
                        onCancel={handleCancel}
                      >
                        <img
                          alt="example"
                          style={{
                            width: "100%",
                          }}
                          src={previewImage}
                        />
                      </Modal>
                      <div className="col-sm-4 mt-2 mt-sm-0 text-center roomdet-add ">
                        <Button
                          type="primary"
                          className="mt-sm-5"
                          style={{ minWidth: "100px" }}
                          onClick={handleClick}
                        >
                          Add to list 
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <NavLink to="/facilities"></NavLink> */}

              {/* <NavLink to="/photos"></NavLink> */}
            </div>
            <div className="row mt-2">
              <div className="col-sm-12 col-lg-9">
                <Table
                  dataSource={DataSource}
                  scroll={{
                    x: 1300,
                  }}
                  pagination={false} 
                >
                  <Column
                    title={`Room Type`}
                    key="RoomType"
                    render={(_, record) => (
                      <Space size="middle">
                        <p>{record?.RoomType}</p>
                      </Space>
                    )}
                    id="room_type_room_details"
                  />
                  <Column
                    title="Validity From"
                    key="ValidityFrom"
                    render={(_, record) => (
                      <Space size="middle">
                        <p>{record?.ValidityFrom}</p>
                      </Space>
                    )}
                  />
                  <Column
                    title="Validity To"
                    key="ValidityTo"
                    render={(_, record) => (
                      <Space size="middle">
                        <p>{record?.ValidityTo}</p>
                      </Space>
                    )}
                  />

                  <Column
                    title="Single Discount Rate"
                    key="SingleDiscountRate"
                    render={(_, record) => (
                      <Space size="middle">
                        <p>{record?.SingleDiscountRate}</p>
                      </Space>
                    )}
                  />

                  <Column
                    title="Double Discount Rate"
                    key="DoubleDiscountRate"
                    render={(_, record) => (
                      <Space size="middle">
                        <p>{record?.DoubleDiscountRate}</p>
                      </Space>
                    )}
                  />

                  <Column
                    title="Meal Plan"
                    key="Meal Plan"
                    render={(_, record) => (
                      <Space size="middle">
                        <p>{record?.MealPlan}</p>
                      </Space>
                    )}
                  />
                  <Column
                    title="TAC %"
                    key="Tacper"
                    render={(_, record) => (
                      <Space size="middle">
                        <p>{record?.Tacper}</p>
                      </Space>
                    )}
                  />
                  <Column
                    title="Inclusions "
                    key="Inclusions"
                    render={(_, record) => (
                      <Space size="middle">
                       <p> {record.Inclusions.map((item,index) => item+", ")}</p>
                        
                      </Space>
                    )}
                  />
                  <Column
                    title="Room Images"
                    key="RoomImagepreview"
                    render={(_, record) => (
                      <Space size="middle">
                        {/* <p>{record?.RoomImagepreview}</p> */}
                        {/* <img src={record?.RoomImagepreview} /> */}
                        <p>{record?.RoomImagename}</p>
                      </Space>
                    )}
                  />
                  <Column
                    title="Action"
                    key="delete"
                    
                    // render={(_, record) => (
                    //   <Space size="middle">

                    //   </Space>
                    // )}
                    render={(_, record) =>
                      DataSource.length >= 1 ? (
                        <Popconfirm
                          title="Sure to delete?"
                          onConfirm={() => handleDelete(record?.key)}
                        >
                          <a className="btn btn-danger btn-sm text-white d_v_c"style={{width:'30px'}}><DeleteOutlined /></a>
                        </Popconfirm>
                      ) : null
                    }
                  />
                </Table>
                <div className="col-sm-12 pb-sm-5">
                  <div className="float-end mt-3 mt-sm-3 mb-4">
                    <Button
                      type="primary"
                      style={{ minWidth: "100px", marginRight: "5px" }}
                    >
                      <NavLink to="/facilities">Previous</NavLink>
                    </Button>
                    <Button
                      type="primary"
                      className="ms-sm-3"
                      style={{ minWidth: "100px" }}
                      onClick={Nextpagerouting}
                    >
                      Save & Continue
                      {/* <NavLink to="/photos" ></NavLink> */}
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <Modal
              className="error_msg"
              title="Message"
              open={Openeror}
              onCancel={handleCancelmodal}
              footer={[
                <Button key="back" type="primary" onClick={handleCancelmodal}>
                  Ok
                </Button>,
              ]}
            >
              {errormessagearr &&
                errormessagearr.map((item, index) => <p key={index}>{item}</p>)}
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

{
  /* <div className='row ' >
             <div className='col-sm-12 p-4 '>
             <Table  dataSource={cart}>
             <Column
      title="Roomtype"
      key="roomtype"
      render={(_, record) => (
        <Space size="middle">
          <p>{record?.roomtype}</p>
          
        </Space>
      )}
    />
       <Column
      title="Single Tariff"
      key="singletariff"
    
      render={(_, record) => (
        <Space size="middle">
          <p>{record?.singletariff}</p>
          
        </Space>
      )}
    />
          <Column
      title="Doubletariff"
      key="doubletariff"
      render={(_, record) => (
        <Space size="middle">
          <p>{record?.doubletariff}</p>
          
        </Space>
      )}
    />
       <Column
      title="Mealplan"
      key="mealplan"
      render={(_, record) => (
        <Space size="middle">
          <p>{record?.mealplan}</p>
          
        </Space>
        
      )}
    />
       <Column
      title="Room Photo"
      key="roomphoto"
      render={(_, record) => (
        <Space size="middle">
          <p>{record?.roomphoto}</p>
          
        </Space>
        
      )}
    />
      
    

    </Table>
            
          </div>
        </div> */
}
