import { useState, useEffect, useRef, useContext } from "react";
// import Card from '@mui/material/Card';
import pic from "../assets/unnamed.png";
// import { useHistory } from "react-router-dom";
import { CardActionArea } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/system";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { Button } from "antd";
import { Card } from "antd";
import "../styles/propinfo.css";
import "antd/dist/antd.css";
import { Input } from "antd";
import { Radio } from "antd";
import { TimePicker } from "antd";
import { Steps, items } from "antd";
import { Rate } from "antd";
import moment from "moment";
import dayjs from "dayjs";

// import { Link } from "react-router-dom";
import {
  NavLink,
  redirect,
  Link,
  Navigate,
  useHistory,
} from "react-router-dom";
import { Modal, message } from "antd";
import { useNavigate } from "react-router-dom";
import bagpic from "../assets/ec-basics@2x.png";
import React from "react";
import axios from "axios";
import { Select, Upload, Checkbox } from "antd";
import { useLocation } from "react-router-dom";
import { Alert, Space } from "antd";
import "../styles/propinfo.css";
import { CheckOutlined } from "@ant-design/icons";
import { event } from "jquery";
import { PropertyContext } from "../../HB Components/propertyContext/PropertyContext";

export default function Propcreation({ setpropname, setgstnum }) {
  const format = 'HH A';
  let hrs="08"
  let Am="AM"
  const { basic, addToBasic,basicverify, basic_Verifiedbut,basic_GST_verified,gstvarified_details,propInput,multispacetrim } = useContext(PropertyContext);
  console.log(basic);
  const description = "This is a description.";
  const location = useLocation();
 
  const { TextArea } = Input;
  const [errorproperty, seterrorproperty] = useState("");
  const [errorpropertytype, seterrorpropertytype] = useState("");
  const [errorpropdesc, seterrorpropdesc] = useState("");
  const [checkintime, setcheckintime] = useState("12");
  const [chktimeupdt, setchktimeupdt] = useState("");
  const [chkouttimeupdt, setchkouttimeupdt] = useState("");
  const [checkintype, setcheckintype] = useState("AM");
  const [errorchkintime, seterrorchkintime] = useState("");
  const [checkouttime, setcheckoutime] = useState("12");
  const [errorchkouttime, seterrorchkouttime] = useState("");
  const [checkouttype, setcheckouttype] = useState("AM");
  const [gstnumber, setgstnumber] = useState(basic[0]?.gstnumber);
  const [propdescription, setpropdescription] = useState(basic[0]?.propdescription);
  const [errorgstnumber, seterrorgstnumber] = useState("");
  const [errorcancellationpolicy, seterrorcancellationpolicy] = useState("");
  const [cancellationpolicy, setcancellationpolicy] = useState("");
  const [starrating, setstarrating] = useState("");
  const [propertyname, setpropertyname] = useState(basic[0]?.propertyname);
  const [propertylocalname, setpropertylocalname] = useState("");
  const [propertytype, setpropertytype] = useState("15");
  const [Show, setShow] = useState(false);
  const [showserapart, setshowserapart] = useState(false);
  const [websiteAddress, setwebsiteAddress] = useState(basic[0]?.websiteAddress);
  const [legalname, setlegalname] = useState(basic[0]?.legalname);
  const [messageApi, contextHolder] = message.useMessage();
  const [open, setOpen] = useState(false);
  const [showchnlmanger, setshowchnlmanger] = useState(false);
  const [ctime, setctime] = useState();
  const [Channelmanager, setChannelmanager] = useState("");
  const [ChannelManagerload,setChannelManagerload]=useState([])
  const [shwchkinouttime, setshwchkinouttime] = useState(true);
  const [Policies1, setPolicies1] = useState(basic[0]?.Policies1);
  const [Policies2, setPolicies2] = useState(basic[0]?.Policies2);
  const [cancelpolicy1,setcancelpolicy1]=useState("Day of arrival (6 pm)" || basic[0]?.cancelpolicy1)
  const [cancelpolicy2,setcancelpolicy2]=useState("Room night" || basic[0]?.cancelpolicy2)
  const [showverifysymb, setshowverifysymb] = useState(false);
  const [showverifybtn, setshowverifybtn] = useState(true);
  const [errorwebsiteAddress, seterrorwebsiteaddress] = useState("");
  const [gststatus, setgststatus] = useState("");
  const [disablechktime, setdisablechktime] = useState(false);
  const [propertytypeId, setpropertytypeId] = useState("");
  const [proptertytypeload, setpropertytypeload] = useState([]);
  const [propertytypeoption, setpropertytypeoption] = useState(basic[0]?.propertytypeoption);
  const [channelmanageroption, setchannnelmanageroption] = useState("");
  const [otherstext, setotherstext] = useState(basic[0]?.otherstext);
  const [serviceApp, setServiceApp] = useState("");
  const [oneday, setOneday] = useState("");
  const [errormessagearr, setmessagearr] = useState([]);
  const propnameRef = useRef(null);
  const propdescref = useRef(null);
  const websiteaddref = useRef(null);
  const [opengsterror, setopengsterror] = useState(false);
  const [gsterrofmsg, setgsterrofmsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validwebsite, setvalidwebsite] = useState(true);
  const [chnlmanagerid,setchnlmanagerïd]=useState("")
  const [channelmanagerothersload,setchannelmanagerothersload]=useState([])
  // let channelmanagerothersload=[];
  
  const [textBox, setTextBox] = useState(false);

console.log(gstvarified_details)
  console.log(propertytypeoption);
  let numincrement=1;
  // useEffect(() => {
  //   if (basic[0]?.oneday === "24 hrs") {
  //     setdisablechktime(true);
  //     setcheckintime("24");
  //     setcheckintype("hrs");
  //     setcheckouttype("24");
  //     setcheckouttype("hrs");
  //     // new value asigned
  //     setOneday("24 hrs");
  //   } else {
  //     setdisablechktime(false);
  //     setOneday("0 hrs");
  //   }

  //   // setchktimeupdt(moment([basic[0]?.checkintype,basic[0]?.checkintime]))
  // }, [basic]);
  console.log([basic[0]?.checkintype, basic[0]?.checkintime]);
  const onChangecheckin = (time, timeString) => {
    console.log(timeString)
    // alert(time,timeString)
    // setchkouttimeupdt(time._d)
    // time._d="12";
    // le
   
    
    setchktimeupdt(time);
    // setchkouttimeupdt(time);
    // const aftercolonSlash = timeString.split(":").pop();
    // const beforeslash = timeString.split(":");
    // console.log(beforeslash);
    // setcheckintime(aftercolonSlash);
    // setcheckintype(beforeslash);
    setctime(time);
  };
  const othersoption={value: "Others",label: "Others"}

  const onChangecheckout = (time, timeString) => {
    console.log(timeString);
    setchkouttimeupdt(time);
    const aftercolonSlash = timeString.split(":").pop();
    const beforeslash = timeString.split(":");

    setcheckoutime(aftercolonSlash);
    setcheckouttype(beforeslash);
  };
  const [value, setValue] = useState(1);
  const onChangeradio = (e) => {
    // setValue(e.target.value);
    setpropertytypeoption(e.target.value);
    setShow(false);
    if (e.target.value == "Hotel") {
      setShow(true);
      setshowserapart(false);
    }
    if (e.target.value === "Serviced Apartment") {
      setShow(false);
      setshowserapart(true);
    }
    if (e.target.value === "PG/hostel" || basic[0].propertytypeoption==="PG/hostel") {
      setShow(false);
      setshowserapart(false);
      setpropertytype("15")
    }
    if (e.target.value === "Resort" || basic[0].propertytypeoption==="Resort") {
      setShow(false);
      setshowserapart(false);
      setpropertytype("16")
    }
  };

  const onChangeradiochnlmanager = (e) => {
    setchannnelmanageroption(e.target.value);
    if (e.target.value == "Yes") {
      setshowchnlmanger(true);
    }
    if (e.target.value == "No") {
      setshowchnlmanger(false);
      setTextBox(false);
      setotherstext("");

    }
  };

  const [starvalue, setstarvalue] = useState();

  const navigate = useNavigate();

  // let history = useHistory();

  const showModal = () => {
    setmessagearr([]);
   console.log(ChannelManagerload)
    let prop_Name = document.querySelector("#prop_Name");
    let prop_type = document.querySelector("#prop_type");
    let check_time = document.querySelector("#check_time");
    let checkout_time = document.querySelector("#checkout_time");
    let cheoneday = document.querySelector("#cheoneday");
    let pro_descrip = document.querySelector("#pro_descrip");
    let gst_number = document.querySelector("#gst_number");
    let c_manager_group = document.querySelector("#c_manager_group");
    let channel_manager_text = document.querySelector(".channel_manager_text");
    let web_site = document.querySelector("#web_site");

    let remove_error = document.querySelectorAll(".border_red");

    remove_error.forEach((item) => item.classList.remove("border_red"));

    // function isValidURL(websiteAddress) {
    //   var res = websiteAddress.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    //   return (res !== null)
    // };
    // isValidURL(websiteAddress)
    // if(propertyname==="" || basic[0]?.propertyname)
    if (prop_Name?.value === "") {
      // setOpen(true)
      setmessagearr((pre) => [...pre, "* Please enter the Property Name"]);
      prop_Name.classList.add("border_red");
    }

    if (prop_type?.value === "") {
      setmessagearr((pre) => [...pre, "* Please enter the Property Type"]);
      prop_type.classList.add("border_red");
    }
   if(cheoneday.checked === false) {
    if (check_time?.value === "" || checkout_time?.value === "") {
      let date_in = document.querySelector(".date_in > div");

      setmessagearr((pre) => [...pre, "* Please enter the Check-In & Check-Out Time"]);
      if (check_time?.value === "" ){
      date_in.classList.add("border_red");
    }
    if (checkout_time?.value === "") {
      let date_out = document.querySelector(".date_out > div");

      date_out.classList.add("border_red");
    }
 
  }
}
    //  if(chktimeupdt!=="" || oneday==="")
    // if(cheoneday?.value ==="")
    // {
    //  // setOpen(true)

    //   setmessagearr((pre) => [...pre,"* Please enter the Check-In Time"]);
    // }
    // if(chkouttimeupdt!=="" || oneday==="")

    if (propertytypeoption === "Serviced Apartment") {
      let select_ser_app = document.querySelector(
        ".service-apt .ant-card .ant-card-body .ant-select .ant-select-selector"
      );
      console.log(propertytypeoption);
      if (serviceApp === "" || basic[0]?.serviceApp === "") {
        setmessagearr((pre) => [...pre, "* Please select Service Apartments"]);
        select_ser_app?.classList.add("border_red");
      }
    }
    if (pro_descrip?.value === "") {
      setmessagearr((pre) => [
        ...pre,
        "* Please enter the Property Description",
      ]);
      pro_descrip.classList.add("border_red");
    }
    if (gst_number?.value === "") {
      setmessagearr((pre) => [...pre, `* Please enter the GST Number`]);
      gst_number.classList.add("border_red");
    }
    else { 
      if(basicverify === false) {
      setmessagearr((pre) => [
        ...pre,
        "* Please Verify the GST Number",
      ]);
    }
  }

   if (channelmanageroption === "Yes") {
      console.log(Channelmanager);
      if (Channelmanager === "") {
        let channel_manager_select = document.querySelector(
          ".channel_manager_select > div > div"
        );
        if (channel_manager_select) {
          setmessagearr((pre) => [
            ...pre,
            "* Please select the Channel Manager",
          ]);
          channel_manager_select.classList.add("border_red");
        }
      } else if (Channelmanager === "Others") {
        let channel_manager_text = document.querySelector(
          ".channel_manager_text"
        );
        if (channel_manager_text.value === "") {
          setmessagearr((pre) => [
            ...pre,
            "* Please Enter the Channel Manager",
          ]);
          channel_manager_text.classList.add("border_red");
        }
      }
    } else {
    }
    let channelmanaer = document.querySelector(".ant-select-selection-placeholder");
    console.log(channelmanaer)
    if (channel_manager_text?.value === "") {
      // setOpen(true)
      // setmessagearr((pre) => [...pre, "* Please add the Channel manager"]);
      channel_manager_text.classList.add("border_red");
    }

    if (web_site?.value !== "") {


      var res = web_site?.value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if(res == null) {
    setmessagearr((pre) => [
      ...pre,
      "* Please Enter valid Website Address",
    ]);
    web_site.classList.add("border_red");
        setvalidwebsite(false);
  }
    else {
    setvalidwebsite(true);
}
     
      }
      
   
    
    

    if (cheoneday.checked === false ? prop_Name?.value === "" ||
    prop_type?.value === "" ||
    check_time?.value === "" ||
    checkout_time?.value === "" ||
    pro_descrip?.value === "" ||
    gst_number?.value === "" ||
    c_manager_group?.value === "" ||
    channel_manager_text?.value === "" ||
    channelmanaer?.innerHTML ==='Select Channel Manager' ||
    basicverify === false ||
    validwebsite === false
    :
     prop_Name?.value === "" ||
    prop_type?.value === "" ||
    pro_descrip?.value === "" ||
    gst_number?.value === "" ||
    c_manager_group?.value === "" ||
    channel_manager_text?.value === "" ||
    channelmanaer?.innerHTML ==='Select Channel Manager'||
    basicverify === false ||
    validwebsite === false) {
      setOpen(true);
    } else {
      console.log(checkouttype)
      let proparr = [
        {
          propertyname: multispacetrim(propertyname) || basic[0]?.propertyname,
          propertytype: propertytype || basic[0]?.propertytype,
          propertytypeoption:
            propertytypeoption || basic[0]?.propertytypeoption,
          checkintime:checkintime || basic[0]?.checkintime,
          //checkintype: checkintype || basic[0]?.checkintype,
          checkouttime: checkouttime || basic[0]?.checkouttime,
          // checkintype: chktimeupdt || basic[0]?.checkintype,
          checkouttype: chkouttimeupdt || basic[0]?.checkouttype,
          gstnumber: gstnumber || basic[0]?.gstnumber,
          propdescription: multispacetrim(propdescription) || basic[0]?.propdescription,
          cancellationpolicy:
            cancellationpolicy || basic[0]?.cancellationpolicy,
          websiteAddress: multispacetrim(websiteAddress) || basic[0]?.websiteAddress,
          Channelmanager: Channelmanager || basic[0]?.Channelmanager,
          gststatus: gststatus || basic[0]?.gststatus,
          legalname: legalname || basic[0]?.legalname,
          channelmanageroption:
            channelmanageroption || basic[0]?.channelmanageroption,
          otherstext: multispacetrim(otherstext) || basic[0]?.otherstext,
          Policies1: Policies1 || basic[0]?.Policies1,
          Policies2: Policies2 || basic[0]?.Policies2,
          cancelpolicy1:cancelpolicy1 || basic[0]?.cancelpolicy1,
          cancalpolicy2: cancelpolicy2 || basic[0]?.cancelpolicy2,
          oneday: oneday || basic[0]?.oneday,
          serviceApp: serviceApp || basic[0]?.serviceApp,
          checkouttypeampm:checkouttype || basic[0]?.checkouttype,
          checkintypeampm:checkintype || basic[0]?.checkintype,
          chnlmanagerid:chnlmanagerid || basic[0]?.chnlmanagerid,
          cancelpolicy1:cancelpolicy1 || basic[0]?.cancelpolicy1,
          cancelpolicy2:cancelpolicy2 || basic[0]?.cancelpolicy2,
          basic_Verifiedbut:basic_Verifiedbut
        },
      ];
      //localStorage.setItem("Propbasics", JSON.stringify(proparr));
      addToBasic(proparr);

     if(Channelmanager==="Others" || basic[0]?.Channelmanager==="Others")
     {
      let data = {
        Channelmanager:otherstext,
      };
      const responsepropertytype = axios
        .post("https://hotelonboardingapi.staysimplyfied.com/API/Insertchannelmanager", data)
        .then((res) => {
          
        //  setmessagearr([]);
          if(res.data[0].msg==="ALREADY EXISTS")
          {
            console.log("inside if")
            setOpen(true)
            setmessagearr((pre) => [
              ...pre,
              "Channel Manager already exists",
            ])
          }
          else{
            console.log("Inside else")
            return navigate("/location");
          }

         
          
          // setpropertytypeId(res.data[0].Id);
        });
      console.log(chktimeupdt);
     }

      return navigate("/location");

    }
  };
  console.log(basic[0]?.checkintype);
  let options = [];
  useEffect(() => {
    console.log(Policies1);
    setcancellationpolicy(
      `The guest must cancel by ${
        Policies1 !== "Day of arrival (6 pm)" ? Policies1 : "6:00 PM"
      } on the day of arrival or pay 100% of the price  ${
        Policies2 !== "" ? Policies2 : "Room night"
      }`
    );
    const items = JSON.parse(localStorage.getItem("Propbasics"));
    if (items) {
      setpropertyname(items[0].propertyname);
    }

    const responsepropertytype = axios
      .post("https://hotelonboardingapi.staysimplyfied.com/API/Propertytype")
      .then((res) => {
        setpropertytypeload(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
      const responsechannelmanger = axios
                            .post("https://hotelonboardingapi.staysimplyfied.com/API/Selectchannelmanager")
                            .then((res) => {
                              
                              setChannelManagerload(res.data)
                              // console.log("channelMangerload",res.data)
                              // console.log(res.data[80])
                              // setchannelmanagerothersload(ChannelManagerload.splice(res.data[80],1))  
                              // channelmanagerothersload.push({Channelmanager:"Others"})     
                              // ChannelManagerload.push({Channelmanager:"Others"})      
                             
                             
                             
                              console.log(channelmanagerothersload.length)
                                console.log(ChannelManagerload.length)
                                console.log(ChannelManagerload)
                            
                            })
                            .catch((err) => {
                              console.log(err);
                            });
  }, []);

  const onChangechecktime = (e) => {
    console.log(e);
    if (e.target.checked == true) {
      setdisablechktime(true);
      setcheckintime(" ");
      setcheckintype(" ");
      setcheckouttype(" ");
      setcheckoutime(" ");
      setchktimeupdt("");
      setchkouttimeupdt("");
      // new value asigned
      setOneday("24 hrs");
      console.log(ChannelManagerload)
    } else {
      setdisablechktime(false);
      setOneday("0 hrs");
    }
    if (e.target.checked == false) {
      setdisablechktime(false);
      setcheckintime("12");
      setcheckintype("AM");
      setcheckouttype("AM");
      setcheckoutime("12");
      
    } else {
    }
  };

  function gstnumverification() {
    
    if (gstnumber === "" || gstnumber === undefined) {
      setopengsterror(true);
      setmessagearr(["* Please enter the GST number"]);
        setOpen(true)
    }else {
      if(gstnumber?.length < 15){
        setmessagearr(["* GST number must be 15 digits"]);
        setOpen(true); 
      }
      else {
        setIsLoading(true);
      }
    }
    const body = {
      GSTNo: gstnumber,
    };
    const response = axios
      .post(
        "https://warsoftapi.warsoft.in/API/GSTNoValidatetoken/Validate",
        body
      )
      .then((res) => {
        console.log(res.data);

        if (res.data.MESSAGE.success === true) {
          setgststatus(res.data.MESSAGE.result.sts);
          setlegalname(res.data.MESSAGE.result.lgnm);
          basic_GST_verified(res.data.MESSAGE.result)
          // setshowverifysymb(true);
          // setshowverifybtn(false);
          basic_Verifiedbut(true);
          setgsterrofmsg(false);
          
        } else {
          setopengsterror(true);
          setgsterrofmsg(true);
          if(gstnumber?.length < 15){
            setmessagearr(["* GST number must be 15 digits"]);
          }else {
          setmessagearr(["* It is your responsibility to review local laws and taxes. Be responsibile in paying any applicable taxes (GST) on all bookings."]);
        
            }
              setOpen(true);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        
      });
    //console.log(legalname);
  }

  const handleCancel = () => {
    setOpen(false);
  };
  const handleCancelerror = () => {
    setopengsterror(false);
  };

  const handleChangeselect = (value) => {
    console.log(`selected ${value}`);
    
    setServiceApp(value);
    let data = {
      propertytype: value,
    };
    const responsepropertytype = axios
      .post("https://hotelonboardingapi.staysimplyfied.com/API/PropertytypeId", data)
      .then((res) => {
        console.log(res.data[0].Id);
        setpropertytypeId(res.data[0].Id);
        setpropertytype(res.data[0].Id);
      });
  };



//add the Others text to the last of the list
  let ch_ch = ChannelManagerload.map((element, i) => element.Channelmanager === 'Others' ? ChannelManagerload.splice(i,1) :
    {
  
      value: element.Channelmanager,
      label: element.Channelmanager,
  } 
  )
  ch_ch.push({
  
    value: 'Others',
    label: 'Others',
 
})
//add the Others text to the last of the list

  return (
    <>
     {isLoading && (
        <div className="loaderloading">
          <span>Loading</span>
        </div>
      )}
      <nav className="row">
        <a className="navbar-brand col-md-2 text-center mobile-show" href="#">
          <img
            className="3"
            src={pic}
            alt=""
            width={"120px"}
            height={"85px"}
          />
        </a>
        {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon">fsfds</span>
      </button> */}
        <span className="text-center col-md-12 d-flex justify-content-center align-items-center">
          <h3>
            <b>Property RFP</b>
          </h3>
        </span>
      </nav>
      <form>
        <div className="container-fluid g-padding">
          <div className="row">
            <div
              className="col-md-2 no-border-l h100"
              style={{ background: "#fff", borderLeft: "none" }}
            >
              <Card className="col-md-12 h-100 side-menu">
                <Steps
                  size="small"
                  className="mt-md-5 cursor-not-allowed mobile-hide"
                  direction="vertical"
                  current={0}
                >
                  <Steps.Step title={<NavLink to="/">Basics</NavLink>} />
                  <Steps.Step
                    title={<NavLink to="/location">Location</NavLink>}
                  />
                  <Steps.Step
                    title={<NavLink to="/facilities">Facilities</NavLink>}
                  />
                  <Steps.Step
                    title={<NavLink to="/roomdet">Room details</NavLink>}
                  />
                  <Steps.Step title={<NavLink to="/photos">Photos</NavLink>} />
                  <Steps.Step
                    title={<NavLink to="/Profile">Contact</NavLink>}
                  />
                  <Steps.Step
                    title={<NavLink to="/Publish">Publish</NavLink>}
                  />
                </Steps>
                <a className="navbar-brand col-md-2 text-center logo-display" href="#">
            <img
              className=""
              src={pic}
              alt=""
             width="100%"
            />
          </a>
              </Card>
            </div>
            <div
              className="col-md-10 bgcolor layout-ml"
              style={{ background: "rgb(227 245 255)" }}
            >
              <div className="row">
                <div className="col-sm-6 p-3">
                  <h3>Match your property to the right travelers.</h3>
                  <p>All information is required unless marked optional.</p>
                </div>
                <div className="col-sm-6">
                  <img src={bagpic} alt="" height={"120px"} />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-3 ">
                  <h5>Name Your Property&nbsp;<span className="m-red">*</span></h5>
                  <Card
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                    }}
                  >
                    <Input
                      placeholder="Property Name"
                      value={propertyname}
                      onChange={(event) => {
                       
                        setpropertyname(event.target.value.replaceAll('  ',' '));}
                      }
                      
                      ref={propnameRef}
                      name="property"
                      id="prop_Name"
                      onInput={propInput}
                      // onInputChange={onInputValueChange}
                      maxLength={100}
                    />
                  </Card>
                </div>
                <div className="col-sm-9 col-md-9 col-lg-6 mt-2 mt-sm-0 service-apt">
                  <h5>Select Property Type</h5>

                  <Radio.Group
                    onChange={onChangeradio}
                    defaultValue={
                      propertytypeoption || basic[0]?.propertytypeoption || 'PG/hostel'
                    }
                    className="col-sm-4 float-start"
                    id="prop_type"
                  >
                    <Radio value={"Hotel"} className="col-8">
                      <b>Hotel</b>
                    </Radio>
                    {/* <p className='d-inline'>Multi-unit accommodation building with shared facilities, restaurants, and services</p> */}
                    <Radio value={"Serviced Apartment"}>
                      <b>Serviced Apartment</b>
                    </Radio>
                    <Radio value={"PG/hostel"} className="col-8">
                      <b>PG&nbsp;/&nbsp;hostel</b>
                    </Radio>
                    <Radio value={"Resort"} className="col-8">
                      <b>Resort</b>
                    </Radio>
                    {/* <p className='d-inline'>Apartment unit with basic services like cleaning and front desk</p> */}
                  </Radio.Group>
                  {/* <div className="col-sm-3"> */}
                  {(Show ||
                    (basic[0]?.propertytypeoption === "Hotel" &&
                      propertytypeoption === "Hotel")) && (
                    <Card
                      className="mt-2 mt-sm-0 col-12 col-sm-8 float-start"
                      style={{
                        background: "#f4f4f4",
                        boxShadow: "rgb(207 202 202) 0px 0px 10px",
                      }}
                    >
                      <p>
                        Give your home a rating to help set expectations for
                        travelers stay
                      </p>
                      <Rate
                        value={propertytype || basic[0]?.propertytype}
                        color={'blue'}
                        onChange={setpropertytype}
                      />
                    </Card>
                  )}

                  {(showserapart ||
                    (basic[0]?.propertytypeoption === "Serviced Apartment" &&
                      propertytypeoption === "Serviced Apartment")) && (
                    <Card
                      className="mt-2 mt-sm-0 col-12 col-sm-8 float-start"
                      style={{
                        background: "#f4f4f4",
                        boxShadow: "rgb(207 202 202) 0px 0px 10px",
                      }}
                    >
                      {/* <p>Give your home a rating to help set expectations for travelers stay</p>  */}
                      <Select
                        onChange={handleChangeselect}
                        style={{ width: 200 }}
                        placeholder="Select Service Apartment"
                        defaultValue={serviceApp || basic[0]?.serviceApp}
                        options={proptertytypeload.map((element, i) => {
                          return {
                            value: element.PropertyType,
                            label: element.PropertyType,
                          };
                        })}
                        id="select_ser_app"
                      />
                    </Card>
                  )}
                  {/* </div> */}
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-sm-12 col-md-6 col-lg-5">
                  <h5>Check-In & Check-Out Time</h5>

                  <Card
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                    }}
                  >
                    <div className="row">
                      <div className="col-sm-4 col-lg-4 px-lg-1 px-sm-1 col-4 px-1 ps-3 date_in d-flex">
                      <Select
                      disabled={disablechktime}
                      value={checkintime}
                          onChange={(value) => {
                            console.log(value);
                            setcheckintime(value)
                          }}
                          defaultValue={checkintime}
                          className="d-inline"
                          // defaultValue={
                          //   Policies1 ||
                          //   basic[0]?.Policies1 ||
                          //   "Day of arrival (6 pm)"
                          // }
                          // style={{ width: 200 }}
                          // placeholder='Select Channel Manager'
                          options={[
                            {
                              value: "01",
                              label: "01",
                            },
                            {
                              value: "02",
                              label: "02",
                            },
                            {
                              value: "03",
                              label: "03",
                            },
                            {
                              value: "04",
                              label: "04",
                            },
                            {
                              value: "05",
                              label: "05",
                            },
                            {
                              value: "06",
                              label: "06",
                            },
                            {
                              value: "07",
                              label: "07",
                            },
                            {
                              value: "08",
                              label: "08",
                            },
                            {
                              value: "09",
                              label: "09",
                            },
                            {
                              value: "10",
                              label: "10",
                            },
                            {
                              value: "11",
                              label: "11",
                            },
                            {
                              value: "12",
                              label: "12",
                            },
                          ]}
                        />
                         <Select
                         className="d-inline"
                         value={checkintype}
                          onChange={(value) => {
                            console.log(value);
                            setcheckintype(value)
                          }}
                          defaultValue={checkouttype}
                          disabled={disablechktime}
                          options={[
                            {
                              value: "AM",
                              label: "AM",
                            },
                            {
                              value: "PM",
                              label: "PM",
                            }
                            
                          ]}
                        />
                      

                      </div>
                      <div className="col-sm-4 col-lg-4 col-4 px-lg-1 px-1 pe-1 pe-sm-2 date_out d-flex">
                        <Select
                          onChange={(value) => {
                            console.log(value);
                           setcheckoutime(value)
                          }}
                          value={checkouttime}
                          disabled={disablechktime}
                          className="d-inline"
                          defaultValue={checkouttype}
                          // id="checkout_time"
                          options={[
                            {
                              value: "01",
                              label: "01",
                            },
                            {
                              value: "02",
                              label: "02",
                            },
                            {
                              value: "03",
                              label: "03",
                            },
                            {
                              value: "04",
                              label: "04",
                            },
                            {
                              value: "05",
                              label: "05",
                            },
                            {
                              value: "06",
                              label: "06",
                            },
                            {
                              value: "07",
                              label: "07",
                            },
                            {
                              value: "08",
                              label: "08",
                            },
                            {
                              value: "09",
                              label: "09",
                            },
                            {
                              value: "10",
                              label: "10",
                            },
                            {
                              value: "11",
                              label: "11",
                            },
                            {
                              value: "12",
                              label: "12",
                            },
                          ]}
                        />
                         <Select
                         className="d-inline"
                         value={checkouttype}
                         disabled={disablechktime}
                          onChange={(value) => {
                            console.log(value);
                           setcheckouttype(value)
                          }}
                          
                          options={[
                            {
                              value: "AM",
                              label: "AM",
                            },
                            {
                              value: "PM",
                              label: "PM",
                            }
                            
                          ]}
                        />
                        {/* <TimePicker
                          disabled={disablechktime}
                          use12Hours
                          format="hh: A"
                          style={{ width: "100%" }}
                          onSelect={onChangecheckout}
                          // className={"mt-sm-3"}
                          value={chkouttimeupdt || basic[0]?.checkouttype}
                          placeholder="Check-Out-Time"
                          id="checkout_time"
                          showNow={false}
                          needsConfirmation={false}

                        /> */}
                      </div>

                      <div
                        className="col-sm-3 col-2 px-1 ms-lg-2 d-flex align-items-center ps-1 ps-sm-2"
                        style={{ borderLeft: "2px solid #c4c4c4" }}
                      >
                        <Checkbox
                          onChange={onChangechecktime}
                          //checked={(oneday === '24 hrs' || basic[0]?.oneday === '24 hrs') ? 'sdf' : 'fds'}
                          defaultChecked={
                            basic[0]?.oneday === "24 hrs" ? true : false
                          }
                          id="cheoneday"
                        >
                          <b>24&nbsp;hrs</b>
                        </Checkbox>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4 pr-1">
                  <h5>Describe Your Place&nbsp;<span className="m-red">*</span></h5>

                  <Card
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                    }}
                  >
                    <TextArea
                      rows={1}
                      ref={propdescref}
                      value={propdescription}
                      onChange={(event) => {
                        setpropdescription(event.target.value.replaceAll('  ',' '));
                      }}
                      id="pro_descrip"
                      style={{resize: "none"}}
                      onInput={propInput}
                    />
                    
                  </Card>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-sm-6 col-lg-5 pr-1">
                  <h5>GST Number&nbsp;<span className="m-red">*</span></h5>
                  <Card
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                    }}
                  >
                    <div className="row ">
                      <div className="col-10 col-sm-8">
                        <Input
                          placeholder="GST Number"
                          maxLength={15}
                          value={gstnumber}
                          onChange={(event) => {
                            setgstnumber(event.target.value);
                            // setshowverifybtn(true);
                            // setshowverifysymb(false);
                            basic_Verifiedbut(false)
                            setlegalname("");
                          }}
                          id="gst_number"
                          onInput={propInput}
                          className="uppercasetext"
                        />
                        {contextHolder}
                      </div>
                      <div className="col-sm-4 col-2 px-0 d_v_c">
                        {/* {showverifybtn && (
                          <Button type="primary" onClick={gstnumverification}>
                            Verify
                          </Button>
                        )} 
                        {showverifysymb && (
                          <>
                            <CheckOutlined style={{ color: "green" }} />{" "}
                            <span style={{ color: "green" }}> &nbsp;Verified</span>
                          </>
                        )} */}
                        {basicverify === false ? <Button type="primary" onClick={gstnumverification}>
                            Verify
                          </Button> : <><span className="verified_but"><CheckOutlined style={{ color: "#02cc02" }} />{" "}
                            <span style={{ color: "#02cc02",fontWeight:"bold" }}> &nbsp;Verified</span></span>
                         </> }
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mt-2 mt-sm-0">
                  <h5>Legal Name </h5>
                  <Card
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                      height: "56px"
                    }}
                  >
                    <div className="row ">
                      <div className="col-12">
                       {(legalname || basic[0]?.legalname) &&  <Input
                          placeholder=""
                          maxLength={15}
                          value={legalname}
                          id="gst_legal"
                          readOnly
                        /> }
                        {/* {gsterrofmsg === true && <span style={{color:'red'}}>Please check your GST number and try again</span> } */}
                      </div>
                    </div>
                  </Card>
                  {/* {legalname && (
                            <span className="mt-sm-1">
                              {legalname||"w"}{" "}
                            </span>
                          )} */}
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-sm-12 col-lg-9 pr-1 ">
                  <h5>Cancellation Policy</h5>

                  <Card
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                    }}
                  >
                    {/* <h5>Cancellations</h5> */}
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <p>
                          How many days in advance can guests cancel free of
                          charge?
                        </p>
                        <Select
                          onChange={(value) => {
                            console.log(value);
                            setcancelpolicy1(value);

                            if (value == "Day of arrival (6 pm)") {
                              setPolicies1("6:00 PM");
                            } else {
                              setPolicies1(value);
                            }
                          }}
                          defaultValue={
                            Policies1 ||
                            "Day of arrival (6 pm)"
                          }
                          style={{ width: 200 }}
                          // placeholder='Select Channel Manager'
                          options={[
                            {
                              value: "Day of arrival (6 pm)",
                              label: "Day of arrival (6 pm)",
                            },
                            {
                              value: "1 day",
                              label: "1 day",
                            },
                            {
                              value: "2 days",
                              label: "2 days",
                            },
                            {
                              value: "3 days",
                              label: "3 days",
                            },
                            {
                              value: "7 days",
                              label: "7 days",
                            },
                            {
                              value: "14 days",
                              label: "14 days",
                            },
                          ]}
                        />
                      </div>
                      <div
                        className="col-sm-12 col-md-1 d-flex justify-content-center align-items-center"
                        style={{ fontSize: "18px" }}
                      >
                        <b>Or</b>
                      </div>
                      <div className="col-sm-12 col-md-5 col-lg-4">
                        <p>guests will pay 100%</p>
                        <Select
                          onChange={(value) => {
                            setcancelpolicy2(value);
                            setPolicies2(value);
                            if (value == "Room night") {
                              setPolicies2(" the Room night");
                            }
                            if (value == "of the full stay") {
                              setPolicies2(" the whole stay");
                            }
                          }}
                          style={{ width: 200 }}
                          defaultValue={
                            Policies2 ||
                            "Room night"
                          }
                          options={[
                            {
                              value: "Room night",
                              label: "Room night",
                            },
                            {
                              value: "of the full stay",
                              label: "of the full stay",
                            },
                          ]}
                        />
                      </div>
                    </div>

                    {/* <p>Choose one from the options below:</p> */}

                    <div className="row mt-2">
                      <div className="col-sm-12">
                        <div className="alert alert-primary mt-sm-3">
                          The guest must cancel by{" "}
                          {Policies1 || "6:00 PM"} on the
                          day of arrival or pay 100%{" "} 
                          of  {Policies2 ||
                            "Room night"}
                          <br />
                          <span style={{ fontSize: "12px" }}>
                            You'll be able to make changes to your policies
                            later on – this is just to get you started.
                          </span>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-sm-9 col-lg-6">
                  <h5>Do you work with Channel Manager</h5>

                  <Card
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                      display: "flex",
                    }}
                    className="channel-manager"
                  >
                    <Radio.Group
                      id="c_manager_group"
                      defaultValue={basic[0]?.channelmanageroption || 'No' }
                      onChange={onChangeradiochnlmanager}
                    >
                      <Radio value={"Yes"}>
                        <b>Yes</b>
                      </Radio>

                      <Radio value={"No"}>
                        <b>No</b>
                      </Radio>
                    </Radio.Group>
                    {/* {console.log(showchnlmanger || (basic[0]?.channelmanageroption === "Yes" || channelmanageroption ==="Yes"))} */}
                    {(showchnlmanger ||
                      (basic[0]?.channelmanageroption === "Yes" &&
                        channelmanageroption === "")) && (
                      <div className="w-75 px-0 channel_manager_select">
                        <Select
                          className="col-sm-6 text-50 channel_manager_radio"
                          defaultValue={basic[0]?.Channelmanager}
                          onChange={(value) => {
                            console.log(value);
                            const data={
                             ChannelManager:value
                            }
                            const responsepropertytype = axios
      .post("http://localhost:3000/api/Selectchannelmanagerid",data)
      .then((res) => {
        console.log(res.data[0].Id);
        setchnlmanagerïd(res.data[0].Id)
      })
      .catch((err) => {
        console.log(err);
      });
                           
                            if (value === "Others") {
                              setTextBox(true);
                            } else {
                              setTextBox(false);
                            }
                            setChannelmanager(value);
                          }}
                          key={numincrement+1}
                          style={{ width: 200 }}
                          placeholder="Select Channel Manager"

                          options={ch_ch}

                          // options={ChannelManagerload.map((element, i) => {
                          //   return {
                          //     value: element.Channelmanager,
                          //     label: element.Channelmanager,
                          //   }
                          // })}
                         
                          
                            
                          

                        />
                        {(textBox ||
                          (basic[0]?.Channelmanager === "Others" &&
                            Channelmanager === "")) && (
                          <Input
                            type="text"
                           value={otherstext}
                            onChange={(event) => {
                              setotherstext(event.target.value.replaceAll('  ',' '));
                            }}
                            className="mt-1 ms-0 ms-md-1 col-sm-6 text-50 mx-md-1 channel_manager_text"
                            onInput={propInput}
                          />
                        )}
                      </div>
                    )}
                  </Card>
                </div>
                <div className="col-sm-3 col-lg-3">
                  <h5>Website Address</h5>
                  <Card
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                    }}
                  >
                    <Input
                      placeholder="Website Address"
                      onChange={(event) => {
                        setwebsiteAddress(event.target.value.replaceAll(' ',''));
                      }}
                      value={websiteAddress}
                      id="web_site"
                      onInput={propInput}
                    ></Input>
                  </Card>
                </div>
              </div>

              <div className="row mt-3 mb-4">
                <div className="col-sm-12 col-lg-9">
                  <div className="float-end">
                    <Button
                      type="primary"
                      style={{ minWidth: "100px" }}
                      onClick={showModal}
                    >
                      Save & Continue
                      {/* <NavLink to="/location"></NavLink> */}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <footer className="footer mt-sm-auto " >
  <div className='card'>
    <div className="container ">
      <div className="card-body">
       <span className="text-muted pb-sm-1">Hummingbird • Digital Private Limited</span>
       
       <div className=" float-sm-end">
       <span className="text-muted pb-sm-1">Copywright © 2022 • All Rights Reserved</span>
       </div>
       </div>
     </div>
  </div>
</footer> */}
      </form>
      {/* <Modal
        title="Message"
        open={opengsterror1}
        onCancel={handleCancelerror}
        footer={[
          <Button key="back" type="primary" onClick={handleCancelerror}>
            Ok
          </Button>,
        ]}
      >
        {
          <p>
            It is your responsibility to review local laws and taxes. Be
            responsibile in paying any applicable taxes (GST) on all bookings.
          </p>
        }
      </Modal> */}

      <Modal
        className="error_msg"
        title="Message"
        open={open}
        onCancel={handleCancel}
        footer={[
          <Button key="back" type="primary" onClick={handleCancel}>
            Ok
          </Button>,
        ]}
      >
        {errormessagearr &&
          errormessagearr.map((item, index) => <p key={index}>{item}</p>)}
      </Modal>
    </>
  );
}
