import React,{useCallback, useContext} from "react";
import facilitypic from "../assets/ec-amenities@2x.png";
import { Card } from "antd";
import "../styles/propinfo.css";
import "antd/dist/antd.css";
import { Steps } from "antd";
import pic from "../assets/unnamed.png";
import { Input } from "antd";
import { Checkbox, Col, Row } from "antd";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Modal, message } from "antd";
import { useState, useEffect } from "react";
import { PropertyContext } from "../../HB Components/propertyContext/PropertyContext";
export default function Facilities() {
  const {facilities, addToFacilities} = useContext(PropertyContext);
  const [LaundryServices, setLaundryServices] = useState("");
  const [CyberCafe, setCybercafe] = useState("");
  const [ServicesConferenceHall, setServicesConferanceHall] = useState("");
  const [RestaurtantServices, setRestaurtantServices] = useState("");
  const [SwimmingPoolServices, setSwimmingPoolServices] = useState("");
  const [GymServices, setGymServices] = useState("");
  const [TourAssistance, setTourAssistance] = useState("");
  const [PetFriendly, setPetFriendly] = useState("");
  const [Parking, setParking] = useState("");
  const [WomenSafety, setWomenSafety] = useState("");
  const [Freefacemasks, setFreefacemasks] = useState("");
  const [Contactlesscheckinout, setcontactlesscheckinout] = useState("");
  const [cleaningTeam, setcleaningTeam] = useState("");
  const [handsanitizer, sethandsanitizer] = useState("");
  const [safetyAmenities,setsafetyAmenities] = useState(facilities[0]?.safetyAmenities);
  const [CleaninessAmenities,setcleaninessAmenities] = useState(facilities[0]?.CleaninessAmenities);
  const [otherinformation,setotherinformation]=useState(facilities[0]?.otherinformation);



  const safety = ["Women Safety / Friendly","CCTV surveillance","Contactless check-In / Out","Security guard","Fire extinguisher","Emergency exit"]
  const Cleanliness = ["Hygienic towels and blanket","Free face masks","Free of pests","Cleaned by professional cleaning Team","Hand sanitizer","Water and Electricity"]
  const Other_info = ["Laundry Services","Cyber Cafe / Wifi","Conference Hall","Restaurtant Services","Swimming Pool Services","Gym Services","Tour Assistance","Pet Friendly","Parking"]
  
  const description = "This is a description.";
  const options = [
    {
      value: "Burns Bay Road",
    },
    {
      value: "Downing Street",
    },
    {
      value: "Wall Street",
    },
  ];
  const location = useLocation();
  const navigate = useNavigate();
  // console.log(location.state.Addrsline1)
  // useEffect(() => {
  //   console.log(location.state)
  // },[]);
  const handleChange = (value) => {
    console.log(`Selected: ${value}`);
  };
  const onChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
    setsafetyAmenities(checkedValues);



    // if (Amenities === "Laundry Services") {
    //   console.log("inside the laundry services");
    //   setLaundryServices("1");
    // } else if (Amenities === "CyberCafe/Wifi") {
    //   setCybercafe("1");
    // } else if (checkedValues === "Services ConferenceHall") {
    //   setServicesConferanceHall("1");
    // } else if (checkedValues === "Restaurtant Services") {
    //   setRestaurtantServices("1");
    // } else if (checkedValues === "Swimming Pool Services") {
    //   setSwimmingPoolServices("1");
    // } else if (checkedValues === "Gym Services") {
    //   setGymServices("1");
    // } else if (checkedValues === "Tour Assistance") {
    //   setTourAssistance("1");
    // } else if (checkedValues === "Pet Friendly") {
    //   setPetFriendly("1");
    // } else if (checkedValues === "Pet Friendly") {
    //   setPetFriendly("1");
    // } else if (checkedValues === "Parking") {
    //   setParking("1");
    // }

    // let object = {laundryservices:LaundryServices,CyberCafe:CyberCafe,
    //   conferencehall:ServicesConferenceHall,RestaurtantServices:RestaurtantServices,
    //   SwimmingPoolServices:SwimmingPoolServices,GymServices:GymServices,TourAssistance:TourAssistance,
    // PetFriendly:PetFriendly,Parking:Parking};
    // Amenities.push(object)
  };
  const onChangecleaniness = useCallback((checkedValues) => {
    console.log("checked = ", checkedValues);
    setcleaninessAmenities(checkedValues);
  },[CleaninessAmenities]);
  const onChangeotherInformation = (checkedValues) => {
    console.log("checked = ", checkedValues);
    setotherinformation(checkedValues);
  }

console.log(facilities)
  const showModal =() => {

//do not delete
 //let result1 = safety.map(item => safetyAmenities.includes(item))
 //do not delete
 //Safety checked box
  let safety_result = safety.map((item,i) => {
    // var obj = {};
    
    if(safetyAmenities?.includes(item) ){
      // obj[item] = 1;
      return 1
    }
    else {
    return 0
    }
      // obj[item] = 0;}
  //  return obj;
      })
      //Cleanliness checked box
      let Cleanliness_result = Cleanliness.map((item,i) => {
        // var obj = {};
        
        if(CleaninessAmenities?.includes(item)){
          // obj[item] = 1;
          return 1
        }
        else { 
          return 0
          // obj[item] = 0;
        }
      //  return obj;
          })
          //Other infomation checked box
      let Other_info_result = Other_info.map((item,i) => {
        // var obj = {};
        
        if(otherinformation?.includes(item)){
          // obj[item] = 1;
          return 1
        }
        else { 
          // obj[item] = 0;
          return 0
        }
      //  return obj;
     
          })
 
// console.log(safety_result);
// console.log(Cleanliness_result);
// console.log(Other_info_result);





    addToFacilities([{safetyAmenities: safetyAmenities || facilities[0]?.safetyAmenities, CleaninessAmenities:CleaninessAmenities || facilities[0]?.CleaninessAmenities,otherinformation:otherinformation || facilities[0]?.otherinformation,safety_check_result:safety_result,Cleanliness_check_result:Cleanliness_result,Other_info_check_result:Other_info_result}])
    
    // localStorage.setItem("Safetyarr", JSON.stringify(safetyAmenities));
    // localStorage.setItem("Cleaninessarr", JSON.stringify(CleaninessAmenities));
    // localStorage.setItem("OtherInformationarr", JSON.stringify(otherinformation));
    return navigate("/roomdet");
    // console.log(Amenities)
  };

  


  return (
    <div>
      <nav className="row">
        <a className="navbar-brand col-md-2 text-center mobile-show" href="#">
          <img
            className=""
            src={pic}
            alt=""
            width={"120px"}
            height={"85px"}
          />
        </a>
        {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon">fsfds</span>
      </button> */}
        <span className="text-center col-md-12 d-flex justify-content-center align-items-center">
          <h3>
            <b>Property RFP</b>
          </h3>
        </span>
      </nav>
      <div className="container-fluid">
        <div className="row">
        <div className="col-md-2 no-border-l h100" style={{background:'#fff',borderLeft:"none"}}>
          <Card className=" col-md-12 h-100 side-menu no-border-l">
            <Steps
              size="small"
              className="mt-md-5 cursor-not-allowed mobile-hide"
              direction="vertical"
              current={2}
            >
              <Steps.Step title={<NavLink to="/">Basics</NavLink>} />
              <Steps.Step title={<NavLink to="/location">Location</NavLink>} />
              <Steps.Step
                title={<NavLink to="/facilities">Facilities</NavLink>}
              />
              <Steps.Step
                title={<NavLink to="/roomdet">Room details</NavLink>}
              />
              <Steps.Step title={<NavLink to="/photos">Photos</NavLink>} />
              <Steps.Step title={<NavLink to="/Profile">Contact</NavLink>} />
              <Steps.Step title={<NavLink to="/Publish">Publish</NavLink>} />
            </Steps>
            <a className="navbar-brand col-md-2 text-center logo-display" href="#">
            <img
              className=""
              src={pic}
              alt=""
             width="100%"
            />
          </a>
          </Card>

          </div>
          <div
            className="col-md-10 bgcolor facilities layout-ml"
            style={{ background: "rgb(227 245 255)" }}
          >
            <div className="row">
              <div className="col-sm-6 p-3">
                <h3>What’s unique and wonderful about your property?</h3>
                <p>
                  Every room and home is unique. Tell us why yours stands out.
                </p>
              </div>
              <div className="col-sm-6">
                <img src={facilitypic} alt="" height={"120px"} />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-9">
                <h5>Safety</h5>
                <p>
                  Show guests that you take their safety and health seriously
                  with these amenities..
                </p>
                <Card
                  style={{
                    background: "#f4f4f4",
                    boxShadow: "rgb(207 202 202) 0px 0px 10px",
                  }}
                >
                  <Checkbox.Group
                    style={{
                      width: "100%",
                    }}
                    onChange={onChange}
                    // value={safetyAmenities || facilities?.safetyAmenities}

                    value ={safetyAmenities}
                  >
                    <Row>
                    {safety.map((item,index) => <Col  key={index} xs={24} sm={12} lg={8}>

                        <Checkbox defaultChecked={'checked'}  value={item}>
                        {item}
                        </Checkbox>
                      </Col>)}

               

                    </Row>
                  </Checkbox.Group>
                </Card>

                <h5>Cleanliness</h5>
                <p>
                  Show guests that you take their safety and health seriously
                  with these amenities..
                </p>
                <Card
                  style={{
                    background: "#f4f4f4",
                    boxShadow: "rgb(207 202 202) 0px 0px 10px",
                  }}
                >
                  <Checkbox.Group
                    style={{
                      width: "100%",
                    }}
                    onChange={onChangecleaniness}
                    //value={CleaninessAmenities}
                    value ={CleaninessAmenities}
                  >
                    <Row>
                    {Cleanliness.map ((item,index) => <Col key={index} xs={24} sm={12} lg={8}>
                        <Checkbox value={item}>
                        {item}
                        </Checkbox>
                      </Col>)}
  
                    </Row>
                  </Checkbox.Group>
                </Card>
                <h5>Other Information</h5>
                <p>
                  These offerings are found in most of our successful properties
                </p>
                <Card
                  style={{
                    background: "#f4f4f4",
                    boxShadow: "rgb(207 202 202) 0px 0px 10px",
                  }}
                  
                >
                  <Checkbox.Group
                    style={{
                      width: "100%",
                    }}
                    onChange={onChangeotherInformation}
                    value ={otherinformation}
                  >
                    <Row>
                      {Other_info.map ((item,index) => <Col key={index} xs={24} sm={12} lg={8}>

                        <Checkbox value={item}>
                        {item}
                        </Checkbox>
                      </Col>)}
                      </Row>
                  </Checkbox.Group>
                </Card>

                <div className="float-end mt-3 mt-sm-3 mb-4">
                <Button type="primary" style={{ minWidth: "100px",marginRight:"5px" }}>
                  <NavLink to="/location">Previous</NavLink>
                </Button>
                <Button
                  type="primary"
                  className="ms-sm-3"
                  style={{ minWidth: "100px" }}
                  onClick={showModal}
                >
                  Save & Continue
                  {/* <NavLink to="/roomdet"> </NavLink> */}
                </Button>
                </div>
 
                {/* "jquery": "^3.6.0", */}
              </div>
            </div>

           
          </div>
        </div>
      </div>
    </div>
  );
}
