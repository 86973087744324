import { useState,useContext } from "react";
import roomheadpic from "../assets/ec-publish@2x.png";
import { Card } from "antd";
import "../styles/propinfo.css";
import "antd/dist/antd.css";
import { Steps } from "antd";
import pic from "../assets/unnamed.png";
import { Input, List } from "antd";
import { Button, Modal } from "antd";
import { NavLink } from "react-router-dom";
import { AutoComplete } from "antd";
import { Checkbox, Descriptions } from "antd";
import axios from "axios";
import { useEffect, useRef } from "react";
import { useLocation,useNavigate } from "react-router-dom";
import { Space, Table, Tag } from "antd";
import ColumnGroup from "antd/lib/table/ColumnGroup";
import ReactDOMServer from "react-dom/server";
import html2pdf from "html2pdf.js";
import JsPDF from "jspdf";
import { PropertyContext } from "../../HB Components/propertyContext/PropertyContext";

export default function Publish({ data1, propname, gstnum }) {
  const {basic, addToBasic, locationValue, addToLocations, facilities, addToFacilities, roomDetails, addToRoomDetails, propImages, addToPropImages,propImagespath, addToPropImagespath, profileDetails, addToProfile, profileImagePath,gstvarified_details,basic_Verifiedbut,profile_acVerifiedbut,profileupiverified} = useContext(PropertyContext);
  console.log(profileImagePath);
  const location = useLocation();
  const ref1 = useRef(null);
  let data1cont = [];
  let frontofficedata = [];
  let Gmdata = [];
  let Reservation = [];
  let finance_manager = [];
  const navigate = useNavigate();
console.log(data1)
  console.log("basic:",basic);
  console.log("locationValue:",locationValue);
  console.log("facilities:",facilities);
  console.log("roomDetails:",roomDetails);
  console.log("propImages:",propImages)
  console.log("propImagespath:",propImagespath)
  console.log("profileDetails:",profileDetails);

  const [salesdata, setsalesdata] = useState([]);
  const [frontoffdata, setfrontoffdata] = useState([]);
  const [Gmdataarr, setGmdataarr] = useState([]);
  const [financemanager, setFinancemanager] = useState([]);
  const [Reservationdata, setreservationdata] = useState([]);
  const [prname, setprname] = useState("");
  const [gstnm, setgstnm] = useState("");
  const [chkintime, setchkintime] = useState([]);
  const [Propertydescription, setPropertydescritpion] = useState("");
  const [addrsline1, setAddrsline1] = useState("");
  const [addrsline2, setAddrsline2] = useState("");
  const [CityId, setCityId] = useState("");
  const [CityName, setCityName] = useState("");
  const [Directions, setDirections] = useState("");
  const [Pincode, setPincode] = useState("");
  const [StateId, setStateId] = useState("");
  const [StateName, setStatename] = useState("");
  const [latlng, setlatlng] = useState({});
  const [localityId, setLocalityId] = useState("");
  const [localityname, setlocalityname] = useState("");
  const [AccountType, setAccounttype] = useState("");
  const [Accountnumber, setAccountnumber] = useState("");
  const [Bankname, setBankname] = useState("");
  const [IFSCcode, setIFSCcode] = useState("");
  const [Payeename, setPayeename] = useState("");
  const [UpiId, setUpiId] = useState("");
  const [Upimobilenumber, setUpimobilenumber] = useState("");
  const [Upiname, setUpiname] = useState("");
  const [branchAddress, setbranchaddress] = useState("");
  const [checkleafpath, setchkleafpath] = useState("");
  const [contactdet, setcontacdet] = useState([]);
  const [roomdtdatasorce, setroomdetailsdatasorce] = useState([]);
  const [openmodal, setopenmodal] = useState(false);
  const [openerror,setopenerror]=useState(false)
  const [termsconditions, setTermsconditions] = useState(false);
  const [sucess,setsucess]=useState(false)

  const [openmodalsuccess,setopenmodalsuccess]=useState(false)



  const [roomdet, setroomdet] = useState([]);

  let items = [];
  let rdet = [];
  let locarr = [];
  let ownerdet = [];
  let cdet = [];
  useEffect(() => {
    // 👇️ use document.getElementById()
    const el = document.getElementById("report");
    console.log(el);
    console.log(data1);
    console.log(profileDetails?.contactarr);
    setprname(propname);
    setgstnm(gstnum);
     data1cont = profileDetails[0]?.contactarr.filter((dt) => {
       return dt.contactType == "Sales";
     });
    // localStorage.clear();
    frontofficedata = profileDetails[0]?.contactarr.filter((fd) => {
      return fd.contactType == "Front Office";
    });
    Gmdata = profileDetails[0]?.contactarr.filter((gmdt) => {
      return gmdt.contactType == "GM";
    });
    Reservation = profileDetails[0]?.contactarr.filter((rsrvation) => {
      return rsrvation.contactType == "Reservation";
    });
    finance_manager = profileDetails[0]?.contactarr.filter((f_manager) => {
      return f_manager.contactType == "Finance Manager";
    });
    setGmdataarr(Gmdata);
    console.log(frontofficedata);
    setsalesdata(data1cont);
    setfrontoffdata(frontofficedata);
    setreservationdata(Reservation);
    setFinancemanager(finance_manager)

    items = JSON.parse(localStorage.getItem("Propbasics"));
    locarr = JSON.parse(localStorage.getItem("locationarr"));
    ownerdet = JSON.parse(localStorage.getItem("PropOwnerdet"));
    // setprname(items[0]?.propertyname);
    // setgstnm(items[0]?.gstnumber);
    // setchkintime(items[0]?.checkintype);
    // setPropertydescritpion(items[0]?.propdescription);
    // setAddrsline1(locarr[0]?.Addrsline1);
    // setAddrsline2(locarr[0]?.Addrsline2);
    // setCityId(locarr[0]?.CityId);
    // setCityName(locarr[0]?.Cityname);
    // setDirections(locarr[0]?.Directions);
    // setPincode(locarr[0]?.Pincode);
    // setStateId(locarr[0]?.StateId);
    // setStatename(locarr[0]?.Statename);
    // setlatlng(locarr[0]?.latlng.lat, locarr[0]?.latlng.lng);
    // setLocalityId(locarr[0]?.localityId);
    // setlocalityname(locarr[0]?.localityname);
    // setAccounttype(ownerdet[0]?.AccountType);
    // setAccountnumber(ownerdet[0]?.Accountnumber);
    // setIFSCcode(ownerdet[0]?.IFSCcode);
    // setPayeename(ownerdet[0]?.Payeename);
    // setBankname(ownerdet[0]?.Bankname);
    // setUpiId(ownerdet[0]?.UpiId);
    // setUpimobilenumber(ownerdet[0]?.Upimobilenumber);
    // setUpiname(ownerdet[0]?.Upiname);
    // setbranchaddress(ownerdet[0]?.branchAddress);
    // setchkleafpath(ownerdet[0]?.checkleafpath);
    rdet = JSON.parse(localStorage.getItem("RoomDetails"));
    cdet = JSON.parse(localStorage.getItem("ContactDetails"));
    setcontacdet(cdet);
    console.log(rdet);
    console.log(cdet);
    setroomdet(rdet);
    //  const  newroomDetail=[]
    //  console.log(rdet.map(rd=>{ rd.DoubleRackRate}))

    // console.log(data1cont)
  }, []);
  const newroomDetail = roomdet?.map((element, i) => {
    return {
      key: element.key,
      Roomtype: element.RoomType,
      SingleRackRate: element.SingleRackRate,
      DoubleRackRate: element.DoubleRackRate,
    };
  });

  const handleCancel = () => {
    

    
    
    setopenmodal(false);

    
  };
  const handleCancelerror = () => {
    setopenerror(false);
  };
  const handleCancelsuccess = () => {
    navigate("/")  
    setopenmodalsuccess(false);
    // if(sucess === true)  {
      // console.log("sfsdfd")
      // alert("sdfsdfdf")
      addToBasic([]);
      addToLocations([]);
      addToFacilities([]);
      addToRoomDetails([]);
      addToRoomDetails([]);
      addToPropImagespath([])
      addToPropImages([]);
      addToProfile([]);
      basic_Verifiedbut(false)
      profile_acVerifiedbut(false)
      profileupiverified(false)
     
      window.location.href("http://localhost:8000/")      
      // window.location.replace("https://www.w3schools.com")
      
    // }
  };
  
  const { Column } = Table;
  const { TextArea } = Input;
  const description = "This is a description.";
  const options = [
    {
      value: "Burns Bay Road",
    },
    {
      value: "Downing Street",
    },
    {
      value: "Wall Street",
    },
  ];

  const handleChange = (value) => {
    console.log(`Selected: ${value}`);
  };
  // const functionshow = () => {
  //   if(termsconditions === true){
  //   setopenmodal(true);
  //   }
  //   else {
      
  //     // setopenmodal(true);
  //     const data = {
  //       PropertyName: basic[0]?.propertyname,
  //       CheckinTime: basic[0]?.checkintypeampm[0],
  //       CheckinType: basic[0]?.checkintypeampm[1],
  //       CheckOutTime: basic[0]?.checkouttypeampm[0],
  //       CheckOutType: basic[0]?.checkouttypeampm[1],
  //       Gstnumber: basic[0]?.gstnumber,
  //       legalname:basic[0]?.legalname,
  //       Propertydescription:basic[0]?.propdescription,
  //       propertytype:basic[0]?.propertytype,
  //       Channelmanager:basic[0]?.Channelmanager,
  //       cancellationpolicy:basic[0]?.cancellationpolicy,
  //       gststatus:basic[0]?.gststatus,
  //       Addrsline1:locationValue[0]?.Addrsline1,
  //       Addrsline2:locationValue[0]?.Addrsline2,
  //       State:locationValue[0]?.Statename,
  //       City:locationValue[0]?.Cityname,
  //       Locality:locationValue[0]?.localityname,
  //       StateId:locationValue[0]?.StateId,
  //       CityId: locationValue[0]?.CityId,
  //       localityId: locationValue[0]?.localityId,
  //       Pincode:locationValue[0]?.Pincode,
  //       Directions:locationValue[0]?.Directions,
  //       latlng:locationValue[0]?.latlng.lat+","+locationValue[0]?.latlng.lng,
  //       localityId: locationValue[0]?.localityId,
  //       localityname: locationValue[0]?.localityname,
  //       AccountType:profileDetails[0].Propertyowners[0]?.AccountType,
  //       Accountnumber:profileDetails[0].Propertyowners[0]?.Accountnumber,
  //       Bankname: profileDetails[0].Propertyowners[0]?.Bankname,
  //       IFSCcode: profileDetails[0].Propertyowners[0]?.IFSCcode,
  //       Payeename: profileDetails[0].Propertyowners[0]?.Payeename,
  //       UpiId: profileDetails[0].Propertyowners[0]?.UpiId,
  //       Upimobilenumber:profileDetails[0].Propertyowners[0]?.Upimobilenumber,
  //       Upiname: profileDetails[0].Propertyowners[0]?.Upiname,
  //       branchAddress: profileDetails[0].Propertyowners[0]?.branchAddress,
  //       checkleafpath: profileDetails[0].Propertyowners[0]?.checkleafpath,
  //       roomdet: roomDetails?.roomdetarr,
  //       contactdet: profileDetails?.contactarr,
  //       btccreditperiod:roomDetails?.btccreditperiod,
  //       Hygienictowelsandblanket:facilities?.Cleanliness_check_result[0],
  //       Freefacemasks:facilities?.Cleanliness_check_result[1],
  //       Freeofpests:facilities?.Cleanliness_check_result[2],
  //       CleanedbyprofessionalcleaningTeam:facilities?.Cleanliness_check_result[3],
  //       Handsanitizer:facilities?.Cleanliness_check_result[4],
  //       WaterandElectricity:facilities?.Cleanliness_check_result[5],
  //       LaundryServices:facilities?.Other_info_check_result[0],
  //       CyberCafeWifi:facilities?.Other_info_check_result[1],
  //       ConferenceHall:facilities?.Other_info_check_result[2],
  //       RestaurtantServices:facilities?.Other_info_check_result[3],
  //       SwimmingPoolServices:facilities?.Other_info_check_result[4],
  //       GymServices:facilities?.Other_info_check_result[5],
  //       TourAssistance:facilities?.Other_info_check_result[6],
  //       PetFriendly:facilities?.Other_info_check_result[7],
  //       Parking:facilities?.Other_info_check_result[8],
  //       WomenSafety:facilities?.safety_check_result[0],
  //       CCTVsurveillance:facilities?.safety_check_result[1],
  //       Contactlesscheckin:facilities?.safety_check_result[2],
  //       Securityguard:facilities?.safety_check_result[3],
  //       Fireextinguisher:facilities?.safety_check_result[4],
  //       Emergencyexit:facilities?.safety_check_result[5],
  //       imagepath1:propImagespath[0],
  //       imagepath2:propImagespath[1],
  //       imagepath3:propImagespath[2],
  //       imagename1:propImages[0]?.name,
  //       imagename2:propImages[1]?.name,
  //       imagename3:propImages[2]?.name
  //       // ChannelManager:basic[0]?.
  //     };
  //     console.log(data);
  //     const propresponse = axios
  //       .post("http://localhost:3000/api/propins", data)
  //       .then((res) => {
  //         console.log(res.data);
  //         if(res.data[0][0].errmsg==="ALREADY EXISTS")
  //         {
            
  //           setopenerror(true)
  //         }
  //         else{
          
  //           const mailresponse = axios
  //             .post(
  //               "http://localhost:3000/api/propmail"
                
  //             )
  //             .then((res) => {
  //               console.log(res);
              
  //             })
  //             .catch((err) => {
  //               console.log(err);
  //             });
            
  //         }
  //     // addToBasic([]);
  //     // addToLocations([]);
  //     // addToFacilities([]);
  //     // roomDetails([]);
  //     // addToRoomDetails([]);
  //     // propImages([]);
  //     // addToPropImages([]);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         console.log(data)
  //       });
  //   }
  // };

  const functionshow = () => {
    if(termsconditions === true)
    {
      setopenmodal(false);
      console.log(locationValue[0]?.latlng.lat+","+locationValue[0]?.latlng.lng)

      // if(basic[0]?.oneday==="24 hrs")
      // {
      //  let ckintime = basic[0]?.CheckinTime="24"
      //  console.log(ckintime)
      // }
   
      // setopenmodal(true);
      // setopenmodalsuccess(true)
      const data = {
        PropertyName: basic[0]?.propertyname,
        CheckinTime: basic[0]?.checkintime ===" "?"24":basic[0]?.checkintime,
        CheckinType: basic[0]?.checkintypeampm ===" "?"Hrs":basic[0]?.checkintypeampm,
        CheckOutTime: basic[0]?.checkouttime ===" "?"24":basic[0]?.checkouttime,
        CheckOutType: basic[0]?.checkouttypeampm ===" "?"Hrs":basic[0]?.checkouttypeampm,
        Gstnumber: basic[0]?.gstnumber,
        legalname:basic[0]?.legalname,
        Propertydescription:basic[0]?.propdescription,
        propertytype:basic[0]?.propertytype,
        Channelmanager:basic[0]?.Channelmanager,
        cancellationpolicy:basic[0]?.cancellationpolicy,
        gststatus:basic[0]?.gststatus,
        Addrsline1:locationValue[0]?.Addrsline1,
        Addrsline2:locationValue[0]?.Addrsline2,
        State:locationValue[0]?.Statename,
        City:locationValue[0]?.Cityname,
        Locality:locationValue[0]?.localityname,
        StateId:locationValue[0]?.StateId,
        CityId: locationValue[0]?.CityId,
        localityId: locationValue[0]?.localityId,
        Pincode:locationValue[0]?.Pincode,
        Directions:locationValue[0]?.Directions,
        latlng:locationValue[0]?.latlng.lat+","+locationValue[0]?.latlng.lng,
        localityId: locationValue[0]?.localityId,
        localityname: locationValue[0]?.localityname,
        AccountType:profileDetails[0].Propertyowners[0]?.AccountType,
        Accountnumber:profileDetails[0].Propertyowners[0]?.Accountnumber,
        Bankname: profileDetails[0].Propertyowners[0]?.Bankname,
        IFSCcode: profileDetails[0].Propertyowners[0]?.IFSCcode,
        Payeename: profileDetails[0].Propertyowners[0]?.Payeename,
        UpiId: profileDetails[0].Propertyowners[0]?.UpiId,
        Upimobilenumber:profileDetails[0].Propertyowners[0]?.Upimobilenumber,
        Upiname: profileDetails[0].Propertyowners[0]?.Upiname,
        branchAddress: profileDetails[0].Propertyowners[0]?.branchAddress,
        checkleafpath: profileImagePath[0],
        roomdet: roomDetails?.roomdetarr,
        contactdet: profileDetails[0]?.contactarr,
        btccreditperiod:roomDetails?.btccreditperiod,
        Hygienictowelsandblanket:facilities[0]?.Cleanliness_check_result[0],
        Freefacemasks:facilities[0]?.Cleanliness_check_result[1],
        Freeofpests:facilities[0]?.Cleanliness_check_result[2],
        CleanedbyprofessionalcleaningTeam:facilities[0]?.Cleanliness_check_result[3],
        Handsanitizer:facilities[0]?.Cleanliness_check_result[4],
        WaterandElectricity:facilities[0]?.Cleanliness_check_result[5],
        LaundryServices:facilities[0]?.Other_info_check_result[0],
        CyberCafeWifi:facilities[0]?.Other_info_check_result[1],
        ConferenceHall:facilities[0]?.Other_info_check_result[2],
        RestaurtantServices:facilities[0]?.Other_info_check_result[3],
        SwimmingPoolServices:facilities[0]?.Other_info_check_result[4],
        GymServices:facilities[0]?.Other_info_check_result[5],
        TourAssistance:facilities[0]?.Other_info_check_result[6],
        PetFriendly:facilities[0]?.Other_info_check_result[7],
        Parking:facilities[0]?.Other_info_check_result[8],
        WomenSafety:facilities[0]?.safety_check_result[0],
        CCTVsurveillance:facilities[0]?.safety_check_result[1],
        Contactlesscheckin:facilities[0]?.safety_check_result[2],
        Securityguard:facilities[0]?.safety_check_result[3],
        Fireextinguisher:facilities[0]?.safety_check_result[4],
        Emergencyexit:facilities[0]?.safety_check_result[5],
        imagepath1:propImagespath[0],
        imagepath2:propImagespath[1],
        imagepath3:propImagespath[2],
        imagename1:propImages[0]?.name,
        imagename2:propImages[1]?.name,
        imagename3:propImages[2]?.name,
        cancalpolicy1:basic[0]?.cancelpolicy1,
        cancalpolicy2:basic[0]?.cancalpolicy2,
        chnmanagerid:basic[0]?.chnlmanagerid,
        safetyamenities:facilities[0]?.safetyAmenities,
        QualityAmenities:facilities[0]?.CleaninessAmenities
        // ChannelManager:basic[0]?.
      };
      console.log(data);
      
      const propresponse = axios
        .post("https://hotelonboardingapi.staysimplyfied.com/API/propins", data)
        .then((res) => {
          console.log(res.data);
          
          if(res?.data?.sucessmsg==="Success")
          {
            console.log("success sdsfs")
            setopenmodal(false)
            setopenerror(false)
            setopenmodalsuccess(true)
            
              
            
          }
         
            if(res?.data[0][0]?.errmsg==="ALREADY EXISTS")
            {
              setopenmodal(false)
              setopenerror(true)
              
            }

          // const mailresponse = axios
          //             .post(
          //               "https://hotelonboardtestapi.staysimplyfied.com/API/propmail"
                        
          //             )
          //             .then((res) => {
          //               console.log(res);
          //             })
          //             .catch((err) => {
          //               console.log(err);
          //             });
                    
          setsucess(true);   
      
        })
        .catch((err) => {
          console.log(err);
          setopenmodal(false)

          // setopenerror(true)
        });
    }
    else{
      setopenmodal(true)
    }
  };

  const propertysave = () => {
    
   
  };

  const data = [
    {
      key: "1",
      Sno: "1",
      name: "Client:",
      description:
        "M/s HummingBirdDigitalPvt. Ltd., 122 Amarjyothi Layout, Domlur, Bangalore – 560071, Karnataka",
    },
    {
      key: "2",
      Sno: "2",
      name: "Service Provider Name & Address:",
      description: "",
    },
    {
      key: "3",
      Sno: "3",
      name: "Service Provided:",
      description: "",
    },
    {
      key: "4",
      Sno: "4",
      name: "Service Provided:",
      description: "",
    },
  ];
console.log(gstvarified_details)
  const datasource = [

    {
      key: "1",
      Particulars: "GST Number",
      numbername: "",
      singletariff:gstvarified_details && gstvarified_details?.gstin,
    },
    {
      key: "2",
      Particulars: "Taxpayer Legal Name",
      numbername: "",
      singletariff:gstvarified_details && gstvarified_details?.lgnm,
    },
    {
      key: "3",
      Particulars: "Taxpayer Trade Name",
      numbername: "",
      singletariff:gstvarified_details && gstvarified_details?.tradeNam,
    },
   
  ];
  const checkinout = [];

  const generatePDF = () => {
    // const report = new JsPDF({
    //   orientation: 'p',
    //   unit: 'pt',
    //   format: 'a4',
    //   putOnlyUsedFonts:true,
    //   compress:true,
    //  })

    //  report.setDisplayMode('100%','single')
    // const header = 'Hummming bird';
    // report.html()
    // .then(() => {
    //   // report.text('Hummming bird', 200, 50)
    //   // report.setPage(2)

    //   report.save('report.pdf');
    // }).catch(err=>{
    //   console.log(err)
    // })

    // var width = report.internal.pageSize.width(250);
    // var height=report.internal.pageSize.getHeight();
    // console.log(width)

    var opt = {
      margin: 0.5,
      filename: "myfile.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 5 },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };

    let element = document.getElementById("report");
    var worker = html2pdf().from(element).set(opt).save();

    //   report.html(document.querySelector('#report'), {
    //     callback: function (report) {
    //       report.save();
    //     },
    //     x: 10,
    //     y: 10,
    //     autoPaging:'slice',
    //     windowWidth:750
    //  });
  };

  const onChangechecktime = (e) => {
if(e.target.checked === true) {
setTermsconditions(true);  
}else {
  setTermsconditions(false); 
}

  }
console.log(facilities)
  return (
    <div>
      <nav className="row">
        <a className="navbar-brand col-md-2 text-center mobile-show" href="#">
          <img
            className=""
            src={pic}
            alt=""
            width={"120px"}
            height={"85px"}
          />
        </a>
        {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon">fsfds</span>
      </button> */}
        <span className="text-center col-md-12 d-flex justify-content-center align-items-center">
          <h3>
            <b>Property RFP</b>
          </h3>
        </span>
      </nav>
      <div className="container-fluid">
        <div className="row">
        <div className="col-md-2 no-border-l h100" style={{background:'#fff',borderLeft:"none"}}>
          <Card className=" col-md-12 h-100 side-menu">
            <Steps
              size="small"
              className="mt-md-5 cursor-not-allowed mobile-hide"
              direction="vertical"
              current={6}
            >
              <Steps.Step title={<NavLink to="/">Basics</NavLink>} />
              <Steps.Step title={<NavLink to="/location">Location</NavLink>} />
              <Steps.Step
                title={<NavLink to="/facilities">Facilities</NavLink>}
              />
              <Steps.Step
                title={<NavLink to="/roomdet">Room details</NavLink>}
              />
              <Steps.Step title={<NavLink to="/photos">Photos</NavLink>} />
              <Steps.Step title={<NavLink to="/Profile">Contact</NavLink>} />
              <Steps.Step title={<NavLink to="/Publish">Publish</NavLink>} />
            </Steps>
            <a className="navbar-brand col-md-2 text-center logo-display" href="#">
            <img
              className=""
              src={pic}
              alt=""
             width="100%"
            />
          </a>
          </Card>
          {/* <div className='d-flex justify-content-center'> */}
          </div>
          <div
            className="col-md-10 layout-ml"
            style={{ background: "rgb(227 245 255)" }}
          >
            <div className="row ">
              <div className="col-sm-6 p-3">
                <h3>Sign your contract and let’s get booking.</h3>
                <p>Just a few more details.</p>
              </div>
              <div className="col-sm-6">
                <img src={roomheadpic} alt="" height={"120px"} />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-9" id="report" ref={ref1}>
                <h5>
                  MOU / Service Agreement for Business STAY Management Services
                </h5>

                <Card
                  style={{
                    background: "#f4f4f4",
                    boxShadow: "rgb(207 202 202) 0px 0px 10px",
                  }}
                >
                  {/* <label>Room Type</label> */}

                  <div className="row">
                    <div className="col-sm-12 publish p-0">
                      {/* <Table  dataSource={data} >
      <Column
      title="Single Tariff"
      key="singletariff"
    
      render={(_, record) => (
        <Space size="middle">
          <ul>
            li
          </ul>
          
        </Space>
      )}
    />
      </Table> */}

                      <div>
                        <Descriptions bordered layout={"vertical"}>
                          <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Client:</span>
}>
                            {/* {basic[0]?.propertyname} */}
                            HummingBird Digital Pvt Ltd, {locationValue[0]?.stategstnum  || '29AABCH5874R1ZN'}
                                                                                             
                          </Descriptions.Item>
                          <Descriptions.Item 
                          label={
                            <span style={{fontWeight: 'bold'}}>Service Provider Name & Address:</span>
                      }>
                            <li>{basic[0]?.propertyname}</li>
                            <li>
                              {locationValue[0]?.Addrsline1 +
                                "," +
                                locationValue[0]?.Addrsline2 +
                                "," +
                                locationValue[0]?.Cityname +
                                "," +
                                locationValue[0]?.Statename +
                                "," +
                                locationValue[0]?.Pincode}
                            </li>
                          </Descriptions.Item>
                          <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Service Provided:</span>
}>
                            <ol type="a">
                              <li>
                                Provide rooms for transit stay for Corporate
                                clients & Retail
                              </li>
                              <li>Provide rooms for long stay</li>
                              <li>Provide meals & Wifi to the traveler</li>
                              <li>
                                Provide conference room facility if available
                              </li>
                              <li>Provide car park if available</li>
                            </ol>
                          </Descriptions.Item>
                          {facilities && facilities[0]?.length > 0 && <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Quality Requirements:</span>
}>
                            <ol type="1">
                              {facilities && facilities[0]?.CleaninessAmenities?.map((item,index) => <li key={index}>{item}</li> )}
                              
                              {/* <li>Clean rooms & clean toilets</li>
                              <li>Fresh food & efficient service</li>
                              <li>
                                Fresh & hygienic towels, bed sheets, blankets &
                                soft furnishing
                              </li>

                              <li>Free of pests</li>
                              <li>Electricity & water to be provided 24/7</li> */}
                            </ol>
                          </Descriptions.Item> }
                          {facilities[0]?.safetyAmenities.length > 0 && <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Safety Requirements:</span>
}>
                            <ol>
                              {/* <li>- Security guards on 24/7</li>
                              <li>
                                - CCTV cameras in the lobby and all the floors
                              </li>
                              <li>- Emergency exits with signs</li>
                              <li>
                                - Fire extinguishers in every floor, that is
                                functional.
                              </li> */}
                              {facilities[0]?.safetyAmenities.map((item,index) => <li key={index}>{item}</li> )}
                            </ol>
                          </Descriptions.Item>}
                          <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>HB Business Fee:</span>
}>
                            As per Annexure A
                          </Descriptions.Item>
                          <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Payment Terms:</span>
}>
                            - If the traveler pay at the hotel. The business fee
                            agreed on will be transferred to HB
                            <br />- If HB collect the payment, HB will deduct
                            the TAC and process the payment to hotel in 30 days
                            credit period.
                          </Descriptions.Item>
                          <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Bank Details:</span>
}>                          A/c No : {profileDetails[0]?.Propertyowners[0]?.Accountnumber}
                            <br />
                            IFSC Code : {profileDetails[0]?.Propertyowners[0]?.IFSCcode}
                            <br />
                            Beneficiary Name : {profileDetails[0]?.Propertyowners[0]?.Payeename}
                            <br />
                            Bank Name : {profileDetails[0]?.Propertyowners[0]?.Bankname}
                            <br />
                            Account Type : {profileDetails[0]?.Propertyowners[0]?.AccountType}
                            <br />
                            Address : {profileDetails[0]?.Propertyowners[0]?.branchAddress}
                            
                            
                           
                          </Descriptions.Item>
                          <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Relationship Manager / Sales Head:</span>
}>
                            {/* {
        setTimeout(()=>{console.log(data1cont[0].contactName)},1000)
        }  */}
                            Name  : {" "}
                            {salesdata?.length > 0 ? salesdata.map((sd) => {
                              return sd.contactName;
                            }) : " N/A"}
                            <br />
                            Contact No :{" "}
                            {salesdata?.length > 0 ? salesdata.map((sd) => {
                              return sd.mobileno;
                            }) : " N/A"}
                            <br />Email Id : {" "}
                            {salesdata?.length > 0 ? salesdata.map((sd) => {
                              return sd.Email;
                            }) : " N/A"}

                            {/* <p>Name : {profileDetails[0]?.contactarr[0]?.contactName}</p>
                            <p>Contact No : {profileDetails[0]?.contactarr[0]?.mobileno}</p>
<p>Email Id : {profileDetails[0]?.contactarr[0]?.Email}</p> */}
                            
                            
                          </Descriptions.Item>
                          <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Accounts or Finance Manager:</span>
}>  
                            Name : {financemanager?.length > 0  ? financemanager.map((sd) => {
                              return sd.contactName;
                            }) : " N/A"} 
                            <br />
                           
                            Contact No : {financemanager?.length > 0  ? financemanager.map((sd) => {
                              return sd.mobileno;
                            }) : " N/A"} 
                            <br />
                            Email Id : {financemanager?.length > 0  ? financemanager.map((sd) => {
                              return sd.Email;
                            }) : " N/A"} 
                            <br />
                          </Descriptions.Item>
                          <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Hotel Owner / Director / GM / Key Mgmt:</span>
}>  
                         
                          Name :  
                            {Gmdataarr?.length > 0  ? Gmdataarr.map((sd) => {
                              return sd.contactName;
                            }) : " N/A"} 
                            <br />
                            Contact No : 
                            {Gmdataarr?.length > 0  ? Gmdataarr.map((sd) => {
                              return sd.mobileno;
                            }) : " N/A"}
                            <br />Email Id : 
                            {Gmdataarr?.length > 0  ? Gmdataarr.map((sd) => {
                              return sd.Email;
                            }) : " N/A"}
                          </Descriptions.Item>
                          <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Front Office Manager:</span>
}>  
                          
                            Name :{" "}
                            {frontoffdata?.length > 0  ? frontoffdata.map((sd) => {
                              return sd.contactName;
                            }) : salesdata?.length > 0 ? salesdata.map((sd) => {
                              return sd.contactName;
                            }) : financemanager?.length > 0  ? financemanager.map((sd) => {
                              return sd.contactName;
                            }) : Gmdataarr?.length > 0  ? Gmdataarr.map((sd) => {
                              return sd.contactName;
                            }) :Reservationdata?.length > 0  ? Reservationdata.map((sd) => {
                              return sd.contactName;
                            }) : " N/A"}
                            <br />
                            Contact No : {" "}
                            {frontoffdata?.length > 0  ? frontoffdata.map((sd) => {
                              return sd.mobileno;
                            }) :salesdata?.length > 0 ? salesdata.map((sd) => {
                              return sd.mobileno;
                            }) :financemanager?.length > 0  ? financemanager.map((sd) => {
                              return sd.mobileno;
                            }) :Gmdataarr?.length > 0  ? Gmdataarr.map((sd) => {
                              return sd.mobileno;
                            }) :Reservationdata?.length > 0  ? Reservationdata.map((sd) => {
                              return sd.mobileno;
                            }) : " N/A"}
                            <br />Email Id :{" "}
                            {frontoffdata?.length > 0  ? frontoffdata.map((sd) => {
                              return sd.Email;
                            }) :salesdata?.length > 0 ? salesdata.map((sd) => {
                              return sd.Email;
                            }) :financemanager?.length > 0  ? financemanager.map((sd) => {
                              return sd.Email;
                            }) :Gmdataarr?.length > 0  ? Gmdataarr.map((sd) => {
                              return sd.Email;
                            }) :Reservationdata?.length > 0  ? Reservationdata.map((sd) => {
                              return sd.Email;
                            })  : " N/A"}
                          </Descriptions.Item>
                          <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Reservation Manager:</span>
}>  
                          
                            <div className="row">
                              <div className="col-sm-3">
                                Name :{" "}
                                {Reservationdata?.length > 0  ? Reservationdata.map((sd) => {
                                  return sd.contactName;
                                }) : " N/A"}{" "}
                              </div>

                              <div className="col-sm-3">
                                Contact No :{" "}
                                {Reservationdata?.length > 0  ? Reservationdata.map((sd) => {
                                  return sd.mobileno;
                                }) : " N/A"}
                              </div>

                              <div className="col-sm-3">
                                Email Id :{" "}
                                {Reservationdata?.length > 0  ? Reservationdata.map((sd) => {
                                  return sd.Email;
                                })  : " N/A"}
                              </div>
                            </div>
                          </Descriptions.Item>
                        </Descriptions>
                      </div>
                      <p className="ms-sm-2 mt-sm-2">
                        This is in principle acceptance of terms and conditions.
                        <br />
                        We may enter into a formal service agreement on mutual
                        agreed terms & conditions.
                      </p>
                      <p className="ms-sm-2"> </p>

                      <div className="row">
                        <div className="col-sm-8">
                          <p className="ms-sm-2">
                            For <b>HummingBird Digital Pvt Ltd</b>
                          </p>
                        </div>
                        <div className="col-sm-1">
                          <p className=" float-end d-inline-block">for,</p>
                        </div>
                        <div className="col-sm-2"></div>
                      </div>
                      <h3 className="text-center">ANNEXURE A</h3>

                      <Descriptions bordered layout={"vertical"}>
                      <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Property Name:</span>
}>  
                          {/* {prname} */}
                          {basic[0]?.propertyname}
                        </Descriptions.Item>
                        <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Property Address:</span>}>

{locationValue[0]?.Addrsline1+","+locationValue[0]?.Addrsline2+","+locationValue[0]?.localityname+","+locationValue[0]?.Cityname+","+locationValue[0]?.Statename+"-"+locationValue[0]?.Pincode}
      </Descriptions.Item>
                        <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Property Contact details : (Reservation / Front office)</span>}>

                          <div className="row">
                            <div className="col-sm-12">Phone No : {(frontoffdata || Reservationdata) ? frontoffdata.map((sd) => {
                              return sd.mobileno;
                            }) || Reservationdata.map((sd) => {
                              return sd.mobileno;
                            }) : " N/A"}</div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12">Email Id : {(frontoffdata || Reservationdata) ? frontoffdata.map((sd) => {
                              return sd.Email;
                            }) || Reservationdata.map((sd) => {
                              return sd.Email;
                            })  : " N/A" }</div>
                          </div>
                        </Descriptions.Item>
                      </Descriptions>
                      <Descriptions className="publish_full-width">
                        <Descriptions.Item>
                          <div className="row">
                            <div
                              className="col-12 col-sm-12 col-lg-6 col-md-6 publish_column"
                              style={{ float: "left" }}
                            >
                              <Descriptions bordered layout={"vertical"}>
                              <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>GST
      {gstvarified_details?.sts ===  "Active" && ' & TAN'   } 
      </span>
    }
      >
                                  <Table dataSource={datasource} className="gsttan publish_tab" pagination={false} >
                                    <Column
                                      title="Particulars"
                                      key="Particulars"
                                      render={(_, record) => (
                                        <Space size="middle">
                                          <p>{record.Particulars}</p>
                                          {/* <p>{gstvarified_details[0]?.MESSAGE.result.gstin}</p> */}
                                        </Space>
                                      )}
                                    />
                                    <Column
                                      title="Number / Name"
                                      key="numbername"
                                      render={(_, record) => (
                                        <Space size="middle">
                                          <p>{record.singletariff}</p>
                                        </Space>
                                      )}
                                    />
                                  </Table>
                                </Descriptions.Item>
                              </Descriptions>
                            </div>

                            <div
                              className="col-12 col-sm-12 col-md-6 col-lg-6 publish_column"
                              style={{ float: "left" }}
                            >
                              <Descriptions bordered layout={"vertical"}>
                              <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Check-In & Check-Out Policy:</span>}>
        <div>{basic?.oneday === "24 hrs" ? "24 hrs" : <>
        <p><b>Check-in : </b>{basic[0]?.checkintime +" "+ basic[0]?.checkintypeampm}</p> 
        <p><b>Check-out : </b>{basic[0]?.checkouttime +" "+ basic[0]?.checkouttypeampm}</p>
        <hr />
        <p><b>BTC Credit Period :</b> {roomDetails?.btccreditperiodoption==="Yes"? roomDetails?.btccreditperiod+" days from invoice submission":""}</p>
        </>}</div>
      </Descriptions.Item>
      
                              </Descriptions>
                            </div>
                          </div>
                        </Descriptions.Item>
                      </Descriptions>

                      <Descriptions className="Tariff">
                        {console.log(roomDetails[0]?.roomdetarr
)}
                        <div className="Tariff_block">
                        <Descriptions.Item>
                          <Table dataSource={roomDetails?.roomdetarr} pagination={false} className="publish_tab">
                            <Column
                              title="Room Type"
                              key="roomtype"
                              style={{ minWidth: "100px" }}
                              render={(_, record) => (
                                <Space size="middle">
                                  <p>{record.RoomType}</p>
                                </Space>
                              )}
                            />
                            <ColumnGroup title="Rack Tariff - Excl GST">
                              <Column
                                title="Single"
                                key="numbername"
                                render={(_, record) => (
                                  <Space size="middle">
                                    <p>{record.SingleRackRate}</p>
                                  </Space>
                                )}
                                style={{minWidth:'100px'}}
                              />

                              <Column
                                title="Double"
                                key="numbername"
                                render={(_, record) => (
                                  <Space size="middle">
                                    <p>{record.DoubleRackRate}</p>
                                  </Space>
                                )}
                              />
                            </ColumnGroup>

                            <ColumnGroup title="Agreed Tariff - Excl GST">
                              <Column
                                title="Single(Incl. TAC)"
                                key="numbername"
                                render={(_, record) => (
                                  <Space size="middle">
                                    <p>{record.SingleDiscountRate}</p>
                                  </Space>
                                )}
                              />

                              <Column title="Double (Incl. TAC)" key=""
                              render={(_, record) => (
                                <Space size="middle">
                                  <p>{record.DoubleDiscountRate}</p>
                                </Space>
                              )} />
                            </ColumnGroup>
                            <Column
                              title="Inclusions"
                              key="numbername"
                              render={(_, record) => (
                                <Space size="middle">
                                  {record.Inclusions.map(item => <p>{item}</p>)}
                                </Space>
                              )}
                              className="inclusions_block"
                            />
                            <Column
                              title="TAC % on Agreed Tariff"
                              key="numbername"
                              render={(_, record) => (
                                <Space size="middle">
                                  <p>{record.Tacper}</p>
                                </Space>
                              )}
                            />
                          </Table>
                        </Descriptions.Item>
                        </div>
                      </Descriptions>
                      <Descriptions className="kindly_note">
                        <Descriptions.Item label="Kindly Note">
                          <ul>
                            <li>
                              GST will be applicable on agreed tariff and TAC.
                            </li>
                            <li>
                              Service charges will not be paid by HB & HB
                              Clients.
                            </li>
                            <li>
                              No Shows / cancellation retention not to be charged.
                              If the No-Shows / cancellations are high, it should
                              mutually discussed to handle future bookings.
                            </li>
                            <li>
                              If HB has marked up the tariff and charged the
                              client, which will be collected at the property,
                              the mark-up amount (commission) collected plus
                              applicable Government tax invoice will be raised
                              by HB.
                            </li>
                            <li>
                              TAC dues to be paid by the property in 10 days
                              from the date of invoice.
                            </li>
                            <li>
                              Incase of short stay / early check outs, hotels have
                              to refund or payment will be adjusted with the
                              future bookings when the payment is paid in
                              advance.
                            </li>
                            <li>
                              Capture GST Number as per the individual booking
                              request mail. Hotel has to rectify the GST incase
                              of any error found.
                            </li>
                            <li>
                              Any loss of ITC due to delay filing of GST by the
                              hotel, needs to be reimbursed.
                            </li>
                          </ul>
                        </Descriptions.Item>
                      </Descriptions>
                    </div>
                  </div>
                </Card>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 mt-2 col-lg-9">
                <h5>Accept the terms and conditions</h5>

                <Card
                  style={{
                    background: "#f4f4f4",
                    boxShadow: "rgb(207 202 202) 0px 0px 10px",
                  }}
                >
                  <Checkbox value="Hand sanitizer" onChange={onChangechecktime}>
                    The General terms and conditions apply to all of your
                    properties listed through HB extranet
                  </Checkbox>
                </Card>
                <div className="float-end mt-3 mt-sm-3 mb-4">
                  <Button
                    type="primary"
                    style={{ minWidth: "100px", marginRight: "5px" }}
                  >
                    <NavLink to="/Profile">Previous</NavLink>
                  </Button>

                  <Button
                    type="primary"
                    className="ms-sm-3"
                    onClick={functionshow}
                    style={{ minWidth: "100px" }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal title="Message" open={openmodal} onCancel={handleCancel}
        footer={[
          <Button key="back" type="primary" onClick={handleCancel}>
            Ok
          </Button>,
        ]}>
         <p> Please accept the Terms & Conditions </p>

        </Modal>
        <Modal title="Message" open={openmodalsuccess} onCancel={handleCancelsuccess}
        footer={[
          <Button key="back" type="primary" onClick={handleCancelsuccess}>
            Ok
          </Button>,
        ]}>
         <p> Property has been created successfully </p>

        </Modal>
        <Modal title="Message" open={openerror} onCancel={handleCancelerror}
        footer={[
          <Button key="back" type="primary" onClick={handleCancelerror}>
            Ok
          </Button> 
        ]}>
         <p> Property has been created already </p>
        </Modal>
      </div>
    </div>
  );
}
